import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_CLIENT_DOWNLOAD_DUMP_ADIB, GET_CLIENT_DOWNLOAD_DUMP_ADIB_SUCCESS, GET_CLIENT_DOWNLOAD_DUMP_ADIB_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* clientDownloadDumpAdib({ payload }) {
  const platformHeader = Constant.PROJECT_CODE === 'SAB' ? { 'x-platform': 'gcp' } : {};

  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/adib/admin/downloads-info`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...platformHeader,
      },
      params: {
        ...payload?.urlParam,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: GET_CLIENT_DOWNLOAD_DUMP_ADIB_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({
        type: GET_CLIENT_DOWNLOAD_DUMP_ADIB_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CLIENT_DOWNLOAD_DUMP_ADIB_ERROR, payload: error });
  }
}

export function* watchClientDownloadDumpAdib() {
  yield takeEvery(GET_CLIENT_DOWNLOAD_DUMP_ADIB, clientDownloadDumpAdib);
}

function* clientDownloadDumpAdibSaga() {
  yield all([fork(watchClientDownloadDumpAdib)]);
}

export default clientDownloadDumpAdibSaga;

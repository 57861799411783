import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PermissionConstants } from '../Containers/Helpers/Constants/permissions';
import { hasPermission } from '../Containers/Helpers/Helpers';

import { Constant } from './../Containers/Helpers/constant';

const Navdata = () => {
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  // Block Dates
  const [isBlockDatesManagement, setIsBlockDatesManagement] = useState(false);

  // Supply Store
  const [isSupplyStore, setIsSupplyStore] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [isService, setIsService] = useState(false);

  // Communication Management
  const [isCommunication, setIsCommunication] = useState(false);
  const [isTemplateEngine, setIsTemplateEngine] = useState(false);

  // Reward Settings
  const [isRewardSettings, setIsRewardSettings] = useState(false);

  // Coupon Management
  const [isCoupon, setIsCoupon] = useState(false);

  // Bookings Management
  const [isBookings, setIsBookings] = useState(false);

  // Review Management
  const [isReview, setIsReview] = useState(false);

  // Inventory Settings
  const [isInventorySettings, setIsInventorySettings] = useState(false);

  // Surge Sphere Management
  const [isSurgeSphere, setIsSurgeSphere] = useState(false);

  // Customer Management
  const [isCustomerManagement, setIsCustomerManagement] = useState(false);

  // Custom Download Management
  const [isCustomDownloadManagement, setIsCustomDownloadManagement] = useState(false);

  // Customer Data Management
  const [isCustomerDataManagement, setIsCustomerDataManagement] = useState(false);

  // Duplicate Cards Management
  const [isDuplicateCardsManagement, setIsDuplicateCardsManagement] = useState(false);

  // Benefit Pack
  const [isBenefitManagement, setIsBenefitManagement] = useState(false);

  // Download Dump
  const [isDownloadDump, setIsDownloadDump] = useState(false);

  // Data Pool
  const [isDataPool, setIsDataPool] = useState(false);

  // Blocked Date
  const [isBlockedDate, setIsBlockedDate] = useState(false);

  // Rules Management
  const [isRulesManagement, setIsRulesManagement] = useState(false);

  // Golf Admin - Real Time
  const [isGolfAdmin, setIsGolfAdmin] = useState(false);
  const [isRealTimeSlotMng, setIsRealTimeSlotMng] = useState(false);

  // User Management
  const [isUserManagement, setIsUserManagement] = useState(false);

  // Project Management
  const [isProjectManagement, setIsProjectManagement] = useState(false);

  // Reports Management
  const [isReportsManagement, setIsReportsManagement] = useState(false);

  // Client Panel
  const [isClientAirportTransfer, setIsClientAirportTransfer] = useState(false);
  const [isDelayedBookingV2, setIsDelayedBookingV2] = useState(false);
  const [isUniqueBookingV2, setIsUniqueBookingV2] = useState(false);
  const [isStaticBookingV2, setIsStaticBookingV2] = useState(false);
  const [isClientAirportMeetAndGreet, setIsClientAirportMeetAndGreet] = useState(false);
  const [isClientHistory, setIsClientHistory] = useState(false);
  const [isClientContact, setIsClientContact] = useState(false);
  const [isClientDownloadDump, setIsClientDownloadDump] = useState(false);

  const [isGenerateVoucher, setIsGenerateVoucher] = useState(false);
  const [isVoucherReports, setIsVoucherReports] = useState(false);

  // Vendor Panel
  const [isVendorAirportTransfer, setIsVendorAirportTransfer] = useState(false);
  const [isVendorDownloadDump, setIsVendorDownloadDump] = useState(false);
  const [isVendorValetBooking, setIsVendorValetBooking] = useState(false);
  const [isVendorDiningBooking, setIsVendorDiningBooking] = useState(false);

  // GM REPORT
  const [isPriceViewer, setIsPriceViewer] = useState(false);
  const [isClientPricing, setIsClientPricing] = useState(false);
  const [isBookingRecon, setIsBookingRecon] = useState(false);
  const [isGmReport, setIsGmReport] = useState(false);

  // ADIB
  const [isBusinessRegistration, setIsBusinessRegistration] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isOffer, setIsOffer] = useState(false);
  const [isAnnouncement, setIsAnnouncement] = useState(false);
  const [clientDelayedBookings, setClientDelayedBookings] = useState(false);
  const [clientUniqueCouponBookings, setClientUniqueCouponBookings] = useState(false);
  const [clientCustomers, setClientCustomers] = useState(false);
  const [isClientDownloadBookingsDump, setIsClientDownloadBookingsDump] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach(item => {
        item.classList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id)) document.getElementById(id).classList.remove('show');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    console.log('iscurrentState', iscurrentState);
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
    if (iscurrentState !== 'coupon') {
      setIsCoupon(false);
    }
    if (iscurrentState !== 'customer-management') {
      setIsCustomerManagement(false);
    }
    if (iscurrentState !== 'customer-data-management') {
      setIsCustomerDataManagement(false);
    }
    if (iscurrentState !== '') {
      setIsDuplicateCardsManagement(false);
    }
    if (iscurrentState !== 'supply-store') {
      setIsSupplyStore(false);
    }
    if (iscurrentState !== 'communication-management') {
      setIsCommunication(false);
    }
    if (iscurrentState !== 'reward-settings') {
      setIsRewardSettings(false);
    }
    if (iscurrentState !== 'benefit-management') {
      setIsBenefitManagement(false);
    }
    if (iscurrentState !== 'booking-management') {
      setIsBookings(false);
    }
    if (iscurrentState !== 'inventory-settings') {
      setIsInventorySettings(false);
    }
    if (iscurrentState !== 'surge-sphere-management') {
      setIsSurgeSphere(false);
    }
    if (iscurrentState !== 'data-pool') {
      setIsDataPool(false);
    }
    if (iscurrentState !== 'download-dump') {
      setIsDownloadDump(false);
    }
    if (iscurrentState !== 'blocked-date') {
      setIsBlockedDate(false);
    }
    if (iscurrentState !== 'rules-management') {
      setIsRulesManagement(false);
    }
    if (iscurrentState !== 'golf-admin') {
      setIsGolfAdmin(false);
    }
    if (iscurrentState !== 'real-time-slot-management') {
      setIsRealTimeSlotMng(false);
    }
    if (iscurrentState !== `user-management`) {
      setIsUserManagement(false);
    }
    if (iscurrentState !== `project-management`) {
      setIsProjectManagement(false);
    }
    if (iscurrentState !== `reports-management`) {
      setIsReportsManagement(false);
    }
    if (iscurrentState !== `block-date-management`) {
      setIsBlockDatesManagement(false);
    }
    if (iscurrentState !== `voucher-reports`) {
      setIsVoucherReports(false);
    }
    if (iscurrentState !== `voucher-generate`) {
      setIsGenerateVoucher(false);
    }
    if (iscurrentState !== `price-viewer`) {
      setIsPriceViewer(false);
    }
    if (iscurrentState !== `client-pricing`) {
      setIsClientPricing(false);
    }
    if (iscurrentState !== `booking-recon`) {
      setIsBookingRecon(false);
    }
    if (iscurrentState !== `gm-report`) {
      setIsGmReport(true);
    }
    if (iscurrentState !== `client-unique-coupon-bookings`) {
      setClientUniqueCouponBookings(false);
    }
    if (iscurrentState !== `client-delayed-bookings`) {
      setClientDelayedBookings(false);
    }
    if (iscurrentState !== `client-customers`) {
      setClientCustomers(false);
    }
    if (iscurrentState !== `client-download-bookings-dump`) {
      setIsClientDownloadBookingsDump(false);
    }
    if (iscurrentState !== `review-management`) {
      setIsReview(false);
    }
  }, [history, iscurrentState]);

  /* ---------------------------- MENU ---------------------------- */
  let menuItems = [
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsDashboard(!isDashboard);
        setIscurrentState('Dashboard');
      },
      subItems: [
        {
          id: 'analytics',
          label: 'Golf Booking',
          link: '/golf-booking',
          parentId: 'dashboard',
        },
      ],
    },

    // // COUPON MANAGEMENT
    // {
    //   id: 'coupon',
    //   label: 'Coupon Management',
    //   icon: 'ri-dashboard-2-line',
    //   link: '/coupon',
    //   name: `${PermissionConstants?.ALL_PERMISSIONS?.COUPON_MANAGEMENT}`,
    //   stateVariables: isCoupon,
    //   click: function (e) {
    //     e.preventDefault()
    //     updateIconSidebar(e)
    //     setIsCoupon(!isCoupon)
    //     setIscurrentState('coupon')
    //   },
    // },

    // COUPON MANAGEMENT
    {
      id: 'coupons',
      label: 'Coupon Management',
      icon: 'ri-dashboard-2-line',
      link: '/coupon',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.COUPON_MANAGEMENT}`,
      stateVariables: isCoupon,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsCoupon(!isCoupon);
        setIscurrentState('coupons');
      },
      subItems: [],
    },

    // DATA POOL
    {
      id: 'data-pool',
      label: 'Data Pool',
      icon: 'ri-dashboard-2-line',
      link: '/data-pool',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.DATA_POOL}`,
      stateVariables: isDataPool,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsDataPool(!isDataPool);
        setIscurrentState('data-pool');
      },
    },

    // CUSTOM DOWNLOAD MANAGEMENT
    {
      id: 'custom-downloads-management',
      label: 'Custom Downloads Management',
      icon: 'ri-dashboard-2-line',
      link: '/custom-reports',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CUSTOM_REPORT_MANAGEMENT}`,
      stateVariables: isCustomDownloadManagement,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsCustomDownloadManagement(!isCustomDownloadManagement);
        setIscurrentState('custom-downloads-management');
      },
      // subItems: [],
    },

    // CUSTOMER DATA MANAGEMENT
    {
      id: 'customer-data-management',
      label: 'Customer Data Management',
      icon: 'ri-dashboard-2-line',
      link: '/customer-data',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CUSTOMER_DATA_MANAGEMENT}`,
      stateVariables: isCustomerDataManagement,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsCustomerDataManagement(!isCustomerDataManagement);
        setIscurrentState('customer-data-management');
      },
    },

    // CLIENT AIRPORT TRANSFER
    {
      id: 'airport-data-management',
      label: 'Airport Transfer',
      icon: 'ri-dashboard-2-line',
      link: '/client/airport-transfer',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_AIRPORT_TRANSFER}`,
      stateVariables: isClientAirportTransfer,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsClientAirportTransfer(!isClientAirportTransfer);
        setIscurrentState('airport-data-management');
      },
    },

    // CLIENT AIRPORT MEET & GREET
    {
      id: 'client-airport-meet-and-greet',
      label: 'Airport Meet & Greet',
      icon: 'ri-dashboard-2-line',
      link: '/client/airport-meet-greet',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_AIRPORT_MEET_AND_GREET}`,
      stateVariables: isClientAirportTransfer,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsClientAirportMeetAndGreet(!isClientAirportMeetAndGreet);
        setIscurrentState('client-airport-meet-and-greet');
      },
    },

    // CLIENT HISTORY
    {
      id: 'client-history',
      label: 'History',
      icon: 'ri-dashboard-2-line',
      link: '/client/history',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_HISTORY}`,
      stateVariables: isClientAirportTransfer,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsClientHistory(!isClientHistory);
        setIscurrentState('client-history');
      },
    },

    // LANDMARK VOUCHER GENERATE
    {
      id: 'voucher-generate',
      label: 'Generate Voucher',
      icon: 'ri-dashboard-2-line',
      link: '/client/voucher-generate',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.GENERATE_VOUCHER}`,
      stateVariables: isGenerateVoucher,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsGenerateVoucher(!isGenerateVoucher);
        setIscurrentState('voucher-generate');
      },
    },

    // LANDMARK VOUCHER REPORTS
    {
      id: 'voucher-reports',
      label: 'Voucher Reports',
      icon: 'ri-dashboard-2-line',
      link: '/client/voucher-bookings',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.VOUCHERS_REPORT}`,
      stateVariables: isVoucherReports,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsVoucherReports(!isVoucherReports);
        setIscurrentState('voucher-reports');
      },
    },

    // DUPLICATE CARDS MANAGEMENT
    {
      id: 'duplicate-cards-management',
      label: 'Duplicate Cards',
      icon: 'ri-dashboard-2-line',
      link: '/duplicate-cards',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.DUPLICATE_CARDS_MANAGEMENT}`,
      stateVariables: isDuplicateCardsManagement,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsDuplicateCardsManagement(!isDuplicateCardsManagement);
        setIscurrentState('duplicate-cards-management');
      },
    },

    // CLIENT CONTACT
    {
      id: 'client-contact',
      label: 'Contact',
      icon: 'ri-dashboard-2-line',
      link: '/client/contact',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_CONTACT}`,
      stateVariables: isClientAirportTransfer,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsClientContact(!isClientContact);
        setIscurrentState('client-contact');
      },
    },

    // CLIENT DASHBOARD
    {
      id: 'client-dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/client/dashboard',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_DASHBOARD}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
      },
    },

    // CLIENT POST
    {
      id: 'post',
      label: 'Posts',
      icon: 'ri-dashboard-2-line',
      link: '/client/post',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.POST}`,
      stateVariables: isPost,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsPost(!isPost);
        setIscurrentState('post');
      },
    },

    // CLIENT OFFER
    {
      id: 'offer',
      label: 'Offers',
      icon: 'ri-dashboard-2-line',
      link: '/client/offer',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.OFFER}`,
      stateVariables: isOffer,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsOffer(!isOffer);
        setIscurrentState('offer');
      },
    },

    // CLIENT ANNOUNCEMENT
    {
      id: 'announcements',
      label: 'Announcements',
      icon: 'ri-dashboard-2-line',
      link: '/client/announcements',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.ANNOUNCEMENT}`,
      stateVariables: isAnnouncement,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsAnnouncement(!isAnnouncement);
        setIscurrentState('announcements');
      },
    },

    // CLIENT BUSINESS REGISTRATION
    {
      id: 'business-registration',
      label: 'Business Registrations',
      icon: 'ri-dashboard-2-line',
      link: '/client/business-registration',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.BUSINESS_REGISTRATION}`,
      stateVariables: isBusinessRegistration,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsBusinessRegistration(!isBusinessRegistration);
        setIscurrentState('business-registration');
      },
    },

    // CLIENT CUSTOMERS
    {
      id: 'client-customers',
      label: 'Customers Report',
      icon: 'ri-dashboard-2-line',
      link: '/client/customers',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_CUSTOMERS}`,
      stateVariables: clientCustomers,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setClientCustomers(!clientCustomers);
        setIscurrentState('client-customers');
      },
    },

    // CLIENT DELAYED BOOKINGS
    {
      id: 'client-delayed-bookings',
      label: 'Bookings Report',
      icon: 'ri-dashboard-2-line',
      link: '/client/delayed-bookings',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_DELAYED_BOOKINGS}`,
      stateVariables: clientDelayedBookings,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setClientDelayedBookings(!clientDelayedBookings);
        setIscurrentState('client-delayed-bookings');
      },
    },

    // CLIENT BOOKINGS DOWNLOADS
    {
      id: 'client-bookings-download-dump',
      label: 'Downloads',
      icon: 'ri-dashboard-2-line',
      link: '/client/bookings/downloads',
      stateVariables: isClientDownloadBookingsDump,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_BOOKINGS_DOWNLOAD_DUMP}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsClientDownloadBookingsDump(!isClientDownloadBookingsDump);
        setIscurrentState('client-download-bookings-dump');
      },
    },

    // // CLIENT UNIQUE COUPON BOOKINGS
    // {
    //   id: 'client-unique-bookings',
    //   label: 'Unique Coupon Booking',
    //   icon: 'ri-dashboard-2-line',
    //   link: '/client/unique-coupon-bookings',
    //   name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_UNIQUE_COUPON_BOOKINGS}`,
    //   stateVariables: clientUniqueCouponBookings,
    //   click: function (e) {
    //     e.preventDefault();
    //     updateIconSidebar(e);
    //     setClientUniqueCouponBookings(!clientUniqueCouponBookings);
    //     setIscurrentState('client-unique-coupon-bookings');
    //   },
    // },

    // VENDOR AIRPORT TRANSFER
    {
      id: 'vendor-airport-data-management',
      label: 'Airport transfer bookings',
      icon: 'ri-dashboard-2-line',
      link: '/vendor/booking/airport-transfer',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.VENDOR_AIRPORT_TRANSFER}`,
      stateVariables: isVendorDiningBooking,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsVendorDiningBooking(!isVendorDiningBooking);
        setIscurrentState('vendor-airport-data-management');
      },
    },

    // VENDOR THRIWE DINING
    {
      id: 'vendor-airport-data-management',
      label: 'Dining Bookings',
      icon: 'ri-dashboard-2-line',
      link: '/vendor/booking/dining',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.DINING_BOOKING_MANAGEMENT}`,
      stateVariables: isVendorAirportTransfer,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsVendorAirportTransfer(!isVendorAirportTransfer);
        setIscurrentState('vendor-airport-data-management');
      },
    },

    // VENDOR VALET BOOKING
    {
      id: 'vendor-valet-parking',
      label: 'Bookings',
      icon: 'ri-dashboard-2-line',
      link: '/vendor/booking/valet-parking',
      stateVariables: isVendorValetBooking,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.VENDOR_VALET_PARKING}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsVendorValetBooking(!isVendorValetBooking);
        setIscurrentState('vendor-valet-parking');
      },
    },

    // VENDOR REPORT
    {
      id: 'vendor-report',
      label: 'Reports',
      icon: 'ri-dashboard-2-line',
      link: '/vendor/report',
      stateVariables: isVendorDownloadDump,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.VENDOR_REPORT}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsVendorDownloadDump(!isVendorDownloadDump);
        setIscurrentState('vendor-download-dump');
      },
    },

    // VENDOR DOWNLOAD
    {
      id: 'vendor-download-dump',
      label: 'Downloads',
      icon: 'ri-dashboard-2-line',
      link: '/vendor/download',
      stateVariables: isVendorDownloadDump,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.VENDOR_DOWNLOAD_DUMP}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsVendorDownloadDump(!isVendorDownloadDump);
        setIscurrentState('vendor-download-dump');
      },
    },

    // SUPPLY STORE MANAGEMENT
    {
      id: 'supply-store',
      label: 'Supply Store',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.SUPPLY_STORE_MANAGEMENT}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsSupplyStore(!isSupplyStore);
        setIscurrentState('supply-store');
      },
      stateVariables: isSupplyStore,
      subItems: [],
    },

    // BLOCK DATE MANAGEMENT
    {
      id: 'block-date',
      label: 'Block Dates',
      icon: 'ri-dashboard-2-line',
      link: '/block-dates',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.BLOCK_DATE_MANAGEMENT}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsBlockDatesManagement(!isBlockDatesManagement);
        setIscurrentState('block-date-management');
      },
      stateVariables: isBlockDatesManagement,
      // subItems: [],
    },

    // COMMUNICATION MANAGEMENT
    {
      id: 'communication-management',
      label: 'Communication',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.COMMUNICATION}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsCommunication(!isCommunication);
        setIscurrentState('communication-management');
      },
      stateVariables: isCommunication,
      subItems: [],
    },

    // BENEFIT MANAGEMENT
    {
      id: 'benefit-management',
      label: 'Benefit Management',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_MANAGEMENT}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsBenefitManagement(!isBenefitManagement);
        setIscurrentState('benefit-management');
      },
      stateVariables: isBenefitManagement,
      subItems: [],
    },

    // REWARD SETTINGS
    {
      id: 'reward-settings',
      label: 'Reward Settings',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.REWARD_SETTINGS}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsRewardSettings(!isRewardSettings);
        setIscurrentState('reward-settings');
      },
      stateVariables: isRewardSettings,
      subItems: [],
    },

    // CUSTOMER MANAGEMENT
    {
      id: 'customer-management',
      label: 'Customer Management',
      icon: 'ri-dashboard-2-line',
      link: '/customer-management',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CUSTOMER_MANAGEMENT}`,
      stateVariables: isCustomerManagement,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsCustomerManagement(!isCustomerManagement);
        setIscurrentState('customer-management');
      },
    },

    // BOOKINGS MANAGEMENT
    {
      id: 'booking-management',
      label: 'Bookings Management',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.BOOKING_MANAGEMENT}`,
      stateVariables: isBookings,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsBookings(!isBookings);
        setIscurrentState('booking-management');
      },
      subItems: [],
    },

    // REVIEW MANAGEMENT
    {
      id: 'review-management',
      label: 'Review Management',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.REVIEW_MANAGEMENT}`,
      stateVariables: isReview,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsReview(!isReview);
        setIscurrentState('review-management');
      },
      subItems: [],
    },

    // INVENTORY SETTINGS
    {
      id: 'inventory-settings',
      label: 'Inventory Settings',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.INVENTORY_SETTINGS}`,
      stateVariables: isInventorySettings,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsInventorySettings(!isInventorySettings);
        setIscurrentState('inventory-settings');
      },
      subItems: [],
    },

    // SURGE SPHERE MANAGEMENT
    {
      id: 'surge-sphere-management',
      label: 'Surge Sphere',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.SURGE_SPHERE_MANAGEMENT}`,
      stateVariables: isSurgeSphere,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsSurgeSphere(!isSurgeSphere);
        setIscurrentState('surge-sphere-management');
      },
      subItems: [],
    },

    // RULES MANAGEMENT
    {
      id: 'rules-management',
      label: 'Rules Management',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.RULES_MANAGEMENT}`,
      stateVariables: isRulesManagement,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsRulesManagement(!isRulesManagement);
        setIscurrentState('rules-management');
      },
      subItems: [],
    },

    // GOLF ADMIN
    {
      id: 'golf-admin',
      label: 'Golf Admin',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.GOLF_ADMIN_MANAGEMENT}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsGolfAdmin(!isGolfAdmin);
        setIscurrentState('golf-admin');
      },
      stateVariables: isGolfAdmin,
      subItems: [],
    },

    // USER MANAGEMENT
    {
      id: 'user-management',
      label: 'User Management',
      icon: 'ri-dashboard-2-line',
      link: '/user-management',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.ADD_USER_PERMISSION}`,
      stateVariables: isUserManagement,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsUserManagement(!isUserManagement);
        setIscurrentState('user-management');
      },
    },

    // DOWNLOAD DUMP
    {
      id: 'download-dump',
      label: 'Downloads',
      icon: 'ri-dashboard-2-line',
      link: '/downloads',
      stateVariables: isDownloadDump,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsDownloadDump(!isDownloadDump);
        setIscurrentState('download-dump');
      },
    },

    // PROJECT MANAGEMENT
    {
      id: 'project-management',
      label: 'Project Management',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isProjectManagement,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.PROJECT_MANAGEMENT}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsProjectManagement(!isProjectManagement);
        setIscurrentState('project-management');
      },
      subItems: [],
    },

    // REPORTS MANAGEMENT
    {
      id: 'reports-management',
      label: 'Reports Management',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isReportsManagement,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.REPORTS_MANAGEMENT}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsReportsManagement(!isReportsManagement);
        setIscurrentState('reports-management');
      },
      subItems: [],
    },

    // CLIENT DOWNLOAD
    {
      id: 'client-download-dump',
      label: 'Downloads',
      icon: 'ri-dashboard-2-line',
      link: '/client/downloads',
      stateVariables: isClientDownloadDump,
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_DOWNLOAD_DUMP}`,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsClientDownloadDump(!isClientDownloadDump);
        setIscurrentState('client-download-dump');
      },
    },

    // CLIENT PRICING
    {
      id: 'client-pricing',
      label: 'Client Pricing',
      icon: 'ri-dashboard-2-line',
      link: '/client-pricing',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.CLIENT_DATA_GM_REPORT}`,
      stateVariables: isClientPricing,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsClientPricing(!isClientPricing);
        setIscurrentState('client-pricing');
      },
    },

    // PRICE VIEWER
    {
      id: 'price-viewer',
      label: 'Price Viewer',
      icon: 'ri-dashboard-2-line',
      link: '/price-viewer',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.PRICE_VIEWER_GM_REPORT}`,
      stateVariables: isPriceViewer,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsPriceViewer(!isPriceViewer);
        setIscurrentState('price-viewer');
      },
    },

    // BOOKING RECON
    {
      id: 'booking-recon',
      label: 'Booking Recon',
      icon: 'ri-dashboard-2-line',
      link: '/booking-recon',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.BOOKING_RECON_GM_REPORT}`,
      stateVariables: isBookingRecon,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsBookingRecon(!isBookingRecon);
        setIscurrentState('booking-recon');
      },
    },

    // GM REPORT
    {
      id: 'gm-report',
      label: 'GM Report',
      icon: 'ri-dashboard-2-line',
      link: '/gm-report',
      name: `${PermissionConstants?.ALL_PERMISSIONS?.MASTER_GM_REPORT}`,
      stateVariables: isGmReport,
      click: function (e) {
        e.preventDefault();
        updateIconSidebar(e);
        setIsGmReport(!isGmReport);
        setIscurrentState('gm-report');
      },
    },
  ];

  /* ---------------------------- SUB-MENU ITEM'S ---------------------------- */
  /* -------------- REPORTS MANAGEMENT -------------- */
  const itemCommsReport = {
    id: 'comms-report',
    label: 'Comms Report',
    link: '/comms-report',
    parentId: 'reports-management',
  };

  /* -------------- PROJECT PAYMENT REPORT MANAGEMENT -------------- */
  const itemProjectPaymentLogs = {
    id: 'payment-report',
    label: 'Payments Report',
    link: '/payments/logs',
    parentId: 'reports-management',
  };

  /* -------------- BOOKING MANAGEMENT -------------- */
  // GOLF BOOKING
  const itemGolfBooking = {
    label: 'Golf Booking',
    link: '/bookings/golf',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.golfBookings?.objectId),
  };

  // AIRPORT TRANSFER BOOKING
  const itemAirportTransferBooking = {
    label: 'Airport Transfer Booking',
    link: '/bookings/pick-drop',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId),
  };

  // DYNAMIC QR BOOKING
  const itemDynamicQRBooking = {
    label: 'Dynamic Qr Booking',
    link: '/bookings/dynamic-qr-booking',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.dynamicQrBookings?.objectId),
  };

  // MEET & GREET BOOKING
  const itemMeetAndGreetBooking = {
    label: 'Meet & Greet Booking',
    link: '/bookings/meet-greet',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.meetAndGreetBookings?.objectId),
  };

  // LOCAL COURIER BOOKING
  const itemLocalCourierBooking = {
    label: 'Local Courier Booking',
    link: '/bookings/local-courier',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.localCourierBookings?.objectId),
  };

  // QR BOOKING
  const itemQRBooking = {
    label: 'QR Booking',
    link: '/bookings/qr',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.qrBookings?.objectId),
  };

  // CARTERX BOOKING
  const itemCarterXBooking = {
    label: 'CarterX Booking',
    link: '/bookings/carterx',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.carterXBookings.objectId),
  };

  // STATIC COUPON BOOKING
  const itemStaticCouponBooking = {
    label: 'Static Coupon Booking',
    link: '/bookings/static-coupon',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.staticCouponBookings?.objectId),
  };

  // UNIQUE COUPON BOOKING
  const itemUniqueCouponBooking = {
    label: 'Unique Coupon Booking',
    link: '/bookings/unique-coupon',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.uniqueCouponBookings?.objectId),
  };

  // DELAYED COUPON BOOKING
  const itemDelayedCouponBooking = {
    label: 'Delayed Coupon Booking',
    link: '/bookings/delayed-coupon',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.delayedCouponBookings?.objectId),
  };

  // INSURANCE BOOKING
  const itmInsuranceBooking = {
    label: 'Insurance Booking',
    link: '/bookings/insurance',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.insuranceBookings?.objectId),
  };

  // CAR SERVICING BOOKING
  const itemCarServicingBooking = {
    label: 'Car Servicing Booking',
    link: '/bookings/car-servicing',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.carServicingBookings?.objectId),
  };

  // CAR REGISTRATION BOOKING
  const itemCarRegistrationBooking = {
    label: 'Car Registration Booking',
    link: '/bookings/car-registration',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.carRegistrationBookings?.objectId),
  };

  // HIRE A DRIVER BOOKING
  const itemHireADriverBooking = {
    label: 'Hire a Driver Booking',
    link: '/bookings/hire-a-driver',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.hireADriverBookings?.objectId),
  };

  // THRIWE PRIME BOOKING
  const itemThriwePrimeBooking = {
    label: 'Thriwe Prime Booking',
    link: '/bookings/thriwe-prime',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.thriwePrimeBookings?.objectId),
  };

  // CLUB BOOKING
  const itemClubBooking = {
    label: 'Club Booking',
    link: '/bookings/club',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.clubBookings?.objectId),
  };

  // VALET PARKING BOOKING
  const itemValetParkingBooking = {
    label: 'Valet Parking Booking',
    link: '/bookings/valet-parking',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.valetParking?.objectId),
  };

  // DINING BOOKING
  const itemDiningBooking = {
    label: 'Dining Booking',
    link: '/bookings/dining',
    parentId: 'bookings',
    // typeId: parseInt(Constant?.BOOKING_TYPES?.valetParking?.objectId),
  };

  // DYNAMIC COUPON BOOKING
  const itemDynamicCouponBooking = {
    label: 'Dynamic Coupon Booking',
    link: '/bookings/dynamic-coupon',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.dynamicCouponBookings?.objectId),
  };

  // DYNAMIC FIELD BOOKING
  const itemDynamicFieldBooking = {
    label: 'Dynamic Booking',
    link: '/bookings/dynamic-booking',
    parentId: 'bookings',
    typeId: parseInt(Constant?.BOOKING_TYPES?.dynamicBookings?.objectId),
  };

  // DELAYED BOOKING V2
  const itemDelayedBookingV2 = {
    label: 'Delayed Booking V2',
    link: '/bookings/delayed-booking-v2',
    parentId: 'bookings',
  };

  // UNIQUE BOOKING V2
  const itemUniqueBookingV2 = {
    label: 'Unique Booking V2',
    link: '/bookings/unique-booking-v2',
    parentId: 'bookings',
  };

  // STATIC BOOKING V2
  const itemStaticBookingV2 = {
    label: 'Static Booking V2',
    link: '/bookings/static-booking-v2',
    parentId: 'bookings',
  };

  // AIRPORT TRANSFER BOOKING V2
  const itemAirportTransferBookingV2 = {
    label: 'Airport Transfer Booking V2',
    link: '/bookings/pick-drop-v2',
    parentId: 'bookings',
  };

  // GOLF BOOKING V2
  const itemGolfBookingV2 = {
    label: 'Golf Booking V2',
    link: '/bookings/golf-v2',
    parentId: 'bookings',
  };

  // GOLF BOOKING V2
  const itemQRBookingV2 = {
    label: 'QR Booking V2',
    link: '/bookings/qr-v2',
    parentId: 'bookings',
  };

  const itemCustomDelayedBookingV2 = {
    label: 'Beach Club Booking',
    link: '/bookings/custom-delayed-booking-v2',
    parentId: 'bookings',
  };

  /* -------------- INVENTORY SETTINGS -------------- */
  // AIRPORT TRANSFER
  const itemAirportTransfer = {
    label: 'Airport Transfer',
    link: '/settings/airport-transfer',
    parentId: 'inventory-settings',
  };

  /* -------------- SURGE SPHERE MANAGEMENT -------------- */
  // WALLET
  const itemWallet = {
    id: 'wallet',
    label: 'Wallet',
    link: '/surge-sphere/wallet',
    parentId: 'surge-sphere-management',
  };

  // LEDGER
  const itemLedger = {
    id: 'ledger',
    label: 'Ledger',
    link: '/surge-sphere/ledger',
    parentId: 'surge-sphere-management',
  };

  // RULES
  const itemRules = {
    id: 'rules',
    label: 'Rules',
    link: '/surge-sphere/rules',
    parentId: 'surge-sphere-management',
  };

  // CAMPAIGN
  const itemCampaigns = {
    id: 'campaigns',
    label: 'Campaigns',
    link: '/surge-sphere/campaigns',
    parentId: 'surge-sphere-management',
  };

  // EVENTS
  const itemEvents = {
    id: 'events',
    label: 'Events',
    link: '/surge-sphere/events',
    parentId: 'surge-sphere-management',
  };

  // COMMUNICATION MANAGEMENT
  const itemCommunicationManagement = {
    id: 'communication-management',
    label: 'Communication Management',
    link: '/surge-sphere/communication',
    parentId: 'surge-sphere-management',
  };

  /* -------------- COUPON MANAGEMENT -------------- */
  // UNIQUE COUPON
  const itemUniqueCoupon = {
    label: 'Unique Coupon',
    link: '/coupons/unique-coupons',
    parentId: 'coupons',
  };

  // STATIC COUPON
  const itemStaticCoupon = {
    label: 'Static Coupon',
    link: '/coupons/static-coupons',
    parentId: 'coupons',
  };

  // UPLOAD STATUS - SUPPLY STORE
  const itemSupplyStoreUploadStatus = {
    label: 'Upload Status',
    link: '/supply-store/upload-status',
    parentId: 'supply-store',
  };

  // MASTER UPLOAD - SUPPLY STORE
  const itemSupplyStoreMasterUpload = {
    label: 'Master Upload',
    link: '/supply-store/master-upload',
    parentId: 'supply-store',
  };

  /* -------------- COMMUNICATION MANAGEMENT -------------- */
  // TEMPLATE ENGINE
  const itemTemplateEngine = {
    id: 'template-engine',
    name: `Template Engine`,
    label: 'Template Engine',
    link: '/#',
    isChildItem: true,
    click: function (e) {
      e.preventDefault();
      setIsTemplateEngine(!isTemplateEngine);
    },
    parentId: 'communication-management',
    stateVariables: isTemplateEngine,
    childItems: [],
  };

  /* -------------- SUPPLY-STORE MANAGEMENT -------------- */
  // CATEGORY
  const itemCategory = {
    id: 'category-management',
    name: `${Constant.SIDEBAR_TYPES.SUPPLY_STORE}`,
    label: 'Category',
    link: '/#',
    isChildItem: true,
    click: function (e) {
      e.preventDefault();
      setIsCategory(!isCategory);
    },
    parentId: 'supply-store',
    stateVariables: isCategory,
    childItems: [],
  };

  // PARTNER
  const itemPartner = {
    id: 'partner-management',
    name: `${Constant.SIDEBAR_TYPES.SUPPLY_STORE}`,
    label: 'Brand',
    link: '/#',
    isChildItem: true,
    click: function (e) {
      e.preventDefault();
      setIsPartner(!isPartner);
    },
    parentId: 'supply-store',
    stateVariables: isPartner,
    childItems: [],
  };

  // FACILITY
  const itemFacility = {
    id: 'location-management',
    name: `${Constant.SIDEBAR_TYPES.SUPPLY_STORE}`,
    label: 'Outlet',
    link: '/#',
    isChildItem: true,
    click: function (e) {
      e.preventDefault();
      setIsLocation(!isLocation);
    },
    parentId: 'supply-store',
    stateVariables: isLocation,
    childItems: [],
  };

  // SERVICE
  const itemService = {
    id: 'service-management',
    name: `${Constant.SIDEBAR_TYPES.SUPPLY_STORE}`,
    label: 'Benefit',
    link: '/#',
    isChildItem: true,
    click: function (e) {
      e.preventDefault();
      setIsService(!isService);
    },
    parentId: 'supply-store',
    stateVariables: isService,
    childItems: [],
  };

  /* -------------- RULES MANAGEMENT -------------- */
  const itemExpenditure = {
    id: 'expenditure',
    label: 'Expenditure',
    link: '/rules/expenditure',
    parentId: 'rules-management',
  };

  const itemEvent = {
    id: 'event',
    label: 'Event',
    link: '/rules/event',
    parentId: 'rules-management',
  };

  const itemServiceWorth = {
    id: 'Service',
    label: 'Service',
    link: '/rules/service',
    parentId: 'rules-management',
  };

  /* -------------- BENEFIT MANAGEMENT -------------- */
  const itemBenefitPack = {
    id: 'benefit-pack',
    label: 'Benefit Packs',
    link: '/benefit-pack',
    parentId: 'benefit-management',
  };

  const itemBenefitGroup = {
    id: 'benefit-groups',
    label: 'Benefit Groups',
    link: '/benefit-groups',
    parentId: 'benefit-management',
  };

  /* -------------- BENEFIT CONFIG -------------- */
  const itemBenefitConfig = {
    id: 'benefit-config',
    label: 'Benefit Config',
    link: '/config-store/benefit-config',
    parentId: 'reward-settings',
  };
  /* -------------- PLATFORM MANAGEMENT -------------- */
  const itemPlatformConfig = {
    id: 'project-config',
    label: 'All Platform',
    link: '/platform/config',
    parentId: 'project-management',
  };

  /* -------------- PROGRAM MANAGEMENT -------------- */
  const itemProgramConfig = {
    id: 'project-config',
    label: 'All Program',
    link: '/program/config',
    parentId: 'project-management',
  };

  /* -------------- COMMUNICATION MANAGEMENT -------------- */
  const itemProjectCommunicationSettings = {
    id: 'communication-setting',
    label: 'Comm Settings',
    link: '/project/comm-settings',
    parentId: 'project-management',
  };

  const itemProjectOnBoardingSettings = {
    id: 'project-onboarding',
    label: 'Project Onboarding',
    link: '/project/project-onboarding',
    parentId: 'project-management',
  };

  /* -------------- CUSTOMER DATA MANAGEMENT -------------- */
  const itemCustomerData = {
    id: 'customer-data',
    label: 'Customer Data Mangement',
    link: '/customer-data',
    parentId: 'project-management',
  };

  /* -------------- PAYMENT MANAGEMENT -------------- */
  const itemPaymentSettings = {
    id: 'payment-settings',
    label: 'Project Payments',
    link: '/payment/settings',
    parentId: 'project-management',
  };

  /* -------------- PROJECT DATA INTEGRATION MANAGEMENT -------------- */
  const itemProjectDataIntegration = {
    id: 'data-integration',
    label: 'Data Integration',
    link: '/project/data-integration',
    parentId: 'project-management',
  };

  /* -------------- CLIENTS MANAGEMENT -------------- */
  const itemClients = {
    id: 'clients',
    label: 'Clients',
    link: '/clients',
    parentId: 'project-management',
  };

  /* -------------- GOLF ADMIN MANAGEMENT -------------- */
  const itemGolfAdmin = {
    id: 'real-time-slot-management',
    label: 'Real Time Slot Management',
    link: '/#',
    isChildItem: true,
    click: function (e) {
      e.preventDefault();
      setIsRealTimeSlotMng(!isRealTimeSlotMng);
    },
    parentId: 'real-time-slot-management',
    stateVariables: isRealTimeSlotMng,
    childItems: [],
  };

  /* ---------------------------- CHILD ITEM'S ---------------------------- */
  const addCategory = {
    id: 1,
    label: 'Add Category',
    link: '/category/add-category',
  };

  const manageCategory = {
    id: 2,
    label: 'Manage Category',
    link: '/category/manage-category',
  };

  const addPartner = {
    id: 1,
    label: 'Add Brand',
    link: '/partner/add-partner',
  };

  const managePartner = {
    id: 2,
    label: 'Manage Brand',
    link: '/partner/manage-partner',
  };

  const addFacility = {
    id: 1,
    label: 'Add Outlet',
    link: '/facility/add-location',
  };

  const manageFacility = {
    id: 2,
    label: 'Manage Outlet',
    link: '/facility/manage-location',
  };

  const facilityBlockedDates = {
    id: 3,
    label: 'Blocked Dates',
    link: '/facility/blocked-date',
  };

  const addService = {
    id: 1,
    label: 'Add Benefit',
    link: '/service/add-service',
  };

  const manageService = {
    id: 2,
    label: 'Manage Benefit',
    link: '/service/manage-service',
  };

  const serviceBlockedDates = {
    id: 3,
    label: 'Blocked Dates',
    link: '/service/blocked-date',
  };

  const courseWiseFormats = {
    id: 1,
    label: 'Course Wise Formats',
    link: '/golf/course-wise-formats',
  };

  const editCourseFormats = {
    id: 2,
    label: 'Edit Course Formats',
    link: '/golf/edit-course-formats',
  };

  const realTimeSlotsReport = {
    id: 3,
    label: 'Real Time Slots Report',
    link: '/golf/real-time-slots-report',
  };

  const slotsUnavailability = {
    id: 4,
    label: 'Slots Unavailability',
    link: '/golf/slots-unavailability',
  };

  const addTemplateEngine = {
    id: 1,
    label: 'Add Template',
    link: '/template-engine/add-template-engine',
  };

  const manageTemplateEngine = {
    id: 2,
    label: 'Manage Template',
    link: '/template-engine/manage-template-engine',
  };

  /* -------------- REVIEW -------------- */

  // FEEDBACK
  const itemFeedback = {
    label: 'Feedback',
    link: '/review/feedback',
    parentId: 'review',
  };

  // FEEDBACK
  const itemQuery = {
    label: 'Query',
    link: '/review/query',
    parentId: 'review',
  };

  /* ---------------------------- HANDLE SUB-MENU'S ---------------------------- */
  menuItems = menuItems?.map(item => {
    if (item?.id === `supply-store`) {
      let tempSubItems = [...item.subItems];

      /* -------------- SUPPLY-STORE MANAGEMENT -------------- */
      // CATEGORY
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CATEGORY_MANAGEMENT)) {
        const tempItemCategory = { ...itemCategory };
        const tempChildItems = [...itemCategory.childItems];
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CATEGORY_CREATE)) {
          tempChildItems.push(addCategory);
        }
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CATEGORY_READ)) {
          tempChildItems.push(manageCategory);
        }
        tempSubItems.push({ ...tempItemCategory, childItems: tempChildItems });
      }

      // PARTNER
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PARTNER_MANAGEMENT)) {
        const tempItemPartner = { ...itemPartner };
        const tempChildItems = [...itemPartner.childItems];
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PARTNER_CREATE)) {
          tempChildItems.push(addPartner);
        }
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PARTNER_READ)) {
          tempChildItems.push(managePartner);
        }
        tempSubItems.push({ ...tempItemPartner, childItems: tempChildItems });
      }

      // FACILITY
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.FACILITY_MANAGEMENT)) {
        const tempItemFacility = { ...itemFacility };
        const tempChildItems = [...itemFacility.childItems];
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.FACILITY_CREATE)) {
          tempChildItems.push(addFacility);
        }
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.FACILITY_READ)) {
          tempChildItems.push(manageFacility);
        }

        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.FACILITY_BLOCKED_DATES)) {
          tempChildItems.push(facilityBlockedDates);
        }
        tempSubItems.push({ ...tempItemFacility, childItems: tempChildItems });
      }

      // SERVICE
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.SERVICE_MANAGEMENT)) {
        const tempItemService = { ...itemService };
        const tempChildItems = [...itemService.childItems];
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.SERVICE_CREATE)) {
          tempChildItems.push(addService);
        }
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.SERVICE_READ)) {
          tempChildItems.push(manageService);
        }

        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.SERVICE_BLOCKED_DATES)) {
          tempChildItems.push(serviceBlockedDates);
        }

        tempSubItems.push({ ...tempItemService, childItems: tempChildItems });
      }

      // UPLOAD FILE
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE)) {
        const tempSupplyStoreUploadStatus = { ...itemSupplyStoreUploadStatus };
        tempSubItems.push({ ...tempSupplyStoreUploadStatus });
      }

      // MASTER UPLOAD
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.SUPPLY_DATA_GM_REPORT)) {
        const tempSupplyStoreMasterUpload = { ...itemSupplyStoreMasterUpload };
        tempSubItems.push({ ...tempSupplyStoreMasterUpload });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === `communication-management`) {
      let tempSubItems = [...item.subItems];

      /* -------------- COMMUNCATION MANAGEMENT -------------- */
      // TEMPLATE ENGINE
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.TEMPLATE_ENGINE_MANAGEMENT)) {
        const tempItemTemplateEngine = { ...itemTemplateEngine };
        const tempChildItems = [...itemTemplateEngine.childItems];
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.TEMPLATE_ENGINE_CREATE)) {
          tempChildItems.push(addTemplateEngine);
        }
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.TEMPLATE_ENGINE_READ)) {
          tempChildItems.push(manageTemplateEngine);
        }
        tempSubItems.push({
          ...tempItemTemplateEngine,
          childItems: tempChildItems,
        });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === `review-management`) {
      let tempSubItems = [...item.subItems];

      /* -------------- REVIEW -------------- */
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.FEEDBACK)) {
        const tempItemFeedback = { ...itemFeedback };
        tempSubItems.push({ ...tempItemFeedback });
      }

      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.QUERY)) {
        const tempItemQuery = { ...itemQuery };
        tempSubItems.push({ ...tempItemQuery });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === `booking-management`) {
      let tempSubItems = [...item.subItems];

      /* -------------- BOOKING MANAGEMENT -------------- */
      // GOLF BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_READ)) {
        const tempItemGolfBooking = { ...itemGolfBooking };
        tempSubItems.push({ ...tempItemGolfBooking });
      }

      // AIRPORT TRANSFER BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_READ)) {
        const tempItemAirportTransferBooking = {
          ...itemAirportTransferBooking,
        };
        tempSubItems.push({ ...tempItemAirportTransferBooking });
      }

      // DYNAMIC QR BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DYNAMIC_QR_BOOKING_READ)) {
        const tempItemAirportTransferBooking = {
          ...itemDynamicQRBooking,
        };
        tempSubItems.push({ ...tempItemAirportTransferBooking });
      }

      // MEET & GREET BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.MEET_AND_GREET_BOOKING_READ)) {
        const tempItemMeetAndGreetBooking = { ...itemMeetAndGreetBooking };
        tempSubItems.push({ ...tempItemMeetAndGreetBooking });
      }

      // LOCAL COURIER BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.LOCAL_COURIER_BOOKING_READ)) {
        const tempItemLocalCourierBooking = { ...itemLocalCourierBooking };
        tempSubItems.push({ ...tempItemLocalCourierBooking });
      }

      // QR BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.QR_BOOKING_READ)) {
        const tempItemQRBooking = { ...itemQRBooking };
        tempSubItems.push({ ...tempItemQRBooking });
      }

      // CARTERX BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CARTERX_BOOKING_READ)) {
        const tempItemCarterXBooking = { ...itemCarterXBooking };
        tempSubItems.push({ ...tempItemCarterXBooking });
      }

      // STATIC COUPON BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.STATIC_COUPON_BOOKING_READ)) {
        const tempItemStaticCouponBooking = { ...itemStaticCouponBooking };
        tempSubItems.push({ ...tempItemStaticCouponBooking });
      }

      // UNIQUE COUPON BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UNIQUE_COUPON_BOOKING_READ)) {
        const tempItemUniqueCouponBooking = { ...itemUniqueCouponBooking };
        tempSubItems.push({ ...tempItemUniqueCouponBooking });
      }

      // DELAYED COUPON BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DELAYED_COUPON_BOOKING_READ)) {
        const tempItemDelayedCouponBooking = { ...itemDelayedCouponBooking };
        tempSubItems.push({ ...tempItemDelayedCouponBooking });
      }

      // INSURANCE BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.INSURANCE_BOOKING_READ)) {
        const tempItemInsuranceBooking = { ...itmInsuranceBooking };
        tempSubItems.push({ ...tempItemInsuranceBooking });
      }

      // CAR SERVICING BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CAR_SERVICING_BOOKING_READ)) {
        const tempItemCarServicingBooking = { ...itemCarServicingBooking };
        tempSubItems.push({ ...tempItemCarServicingBooking });
      }

      // CAR REGISTRATION BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CAR_REGISTRATION_BOOKING_READ)) {
        const tempItemCarRegistrationBooking = {
          ...itemCarRegistrationBooking,
        };
        tempSubItems.push({ ...tempItemCarRegistrationBooking });
      }

      // HIRE A DRIVER BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.HIRE_A_DRIVER_BOOKING_READ)) {
        const tempItemHireADriverBooking = {
          ...itemHireADriverBooking,
        };
        tempSubItems.push({ ...tempItemHireADriverBooking });
      }

      // THRIWE PRIME BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.THRIWE_PRIME_BOOKING_READ)) {
        const tempItemThriwePrimeBooking = { ...itemThriwePrimeBooking };
        tempSubItems.push({ ...tempItemThriwePrimeBooking });
      }

      // CLUB BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLUB_BOOKING_READ)) {
        const tempItemClubBooking = { ...itemClubBooking };
        tempSubItems.push({ ...tempItemClubBooking });
      }

      // VALET PARKING BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VALET_PARKING_BOOKING_READ)) {
        const tempItemValetParkingBooking = { ...itemValetParkingBooking };
        tempSubItems.push({ ...tempItemValetParkingBooking });
      }

      // DINING BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.OUTLET_BOOKING_READ)) {
        const tempItemDiningBooking = { ...itemDiningBooking };
        tempSubItems.push({ ...tempItemDiningBooking });
      }
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DYNAMIC_COUPON_BOOKING_READ)) {
        const tempDynamicCouponBooking = { ...itemDynamicCouponBooking };
        tempSubItems.push({ ...tempDynamicCouponBooking });
      }

      // DYNAMIC FIELD BOOKING
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DYNAMIC_BOOKING_READ)) {
        const tempItemDynamicFieldBooking = { ...itemDynamicFieldBooking };
        tempSubItems.push({ ...tempItemDynamicFieldBooking });
      }

      // STATIC BOOKING V2
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.STATIC_BOOKING_V2_READ)) {
        const tempItemStaticBookingV2 = { ...itemStaticBookingV2 };
        tempSubItems.push({ ...tempItemStaticBookingV2 });
      }

      // DELAYED BOOKING V2
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DELAYED_BOOKING_V2_READ)) {
        const tempItemDelayedBookingV2 = { ...itemDelayedBookingV2 };
        tempSubItems.push({ ...tempItemDelayedBookingV2 });
      }

      // UNIQUE BOOKING V2
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UNIQUE_BOOKING_V2_READ)) {
        const tempItemUniqueBookingV2 = { ...itemUniqueBookingV2 };
        tempSubItems.push({ ...tempItemUniqueBookingV2 });
      }
      // AIRPORT TRANSFER BOOKING V2
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PICKUP_AND_DROP_BOOKING_V2_READ)) {
        const tempItemAirportTransferBookingV2 = { ...itemAirportTransferBookingV2 };
        tempSubItems.push({ ...tempItemAirportTransferBookingV2 });
      }

      // GOLF BOOKING V2
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_V2_READ)) {
        const tempItemGolfBookingV2 = { ...itemGolfBookingV2 };
        tempSubItems.push({ ...tempItemGolfBookingV2 });
      }

      // QR BOOKING V2
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.QR_BOOKING_V2_READ)) {
        const tempItemQRBookingV2 = { ...itemQRBookingV2 };
        tempSubItems.push({ ...tempItemQRBookingV2 });
      }

      // CUSTOM DELAYED BOOKING V2
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CUSTOM_DELAYED_V2_READ)) {
        const tempItemCustomDelayedBookingV2 = { ...itemCustomDelayedBookingV2 };
        tempSubItems.push({ ...tempItemCustomDelayedBookingV2 });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === `inventory-settings`) {
      let tempSubItems = [...item.subItems];

      /* -------------- INVENTORY SETTINGS -------------- */
      // AIRPORT TRANSFER
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.AIRPORT_TRANSFER)) {
        const tempItemAirportTransfer = { ...itemAirportTransfer };
        tempSubItems.push({ ...tempItemAirportTransfer });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === `surge-sphere-management`) {
      let tempSubItems = [...item.subItems];

      /* -------------- WALLET -------------- */
      // WALLET
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.WALLET)) {
        const tempWallet = { ...itemWallet };
        tempSubItems.push({ ...tempWallet });
      }

      // LEDGER
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.LEDGER)) {
        const tempLedger = { ...itemLedger };
        tempSubItems.push({ ...tempLedger });
      }

      // LEDGER
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.RULES)) {
        const tempRules = { ...itemRules };
        tempSubItems.push({ ...tempRules });
      }

      // CAMPAIGNS
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CAMPAIGNS)) {
        const tempCampaigns = { ...itemCampaigns };
        tempSubItems.push({ ...tempCampaigns });
      }

      // EVENTS
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.EVENTS)) {
        const tempEvents = { ...itemEvents };
        tempSubItems.push({ ...tempEvents });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === 'rules-management') {
      let tempSubItems = [...item.subItems];

      /* -------------- RULES MANAGEMENT -------------- */
      // EXPENDITURE
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.EXPENDITURE_READ)) {
        const tempExpenditure = { ...itemExpenditure };
        tempSubItems.push({ ...tempExpenditure });
      }

      // EVENT
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.EVENT_READ)) {
        const tempEvent = { ...itemEvent };
        tempSubItems.push({ ...tempEvent });
      }

      // SERVICE WORTH
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ASSIGN_COINS)) {
        const tempServiceWorth = { ...itemServiceWorth };
        tempSubItems.push({ ...tempServiceWorth });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === 'project-management') {
      let tempSubItems = [...item.subItems];

      // CLIENTS
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLIENTS)) {
        const tempClients = { ...itemClients };
        tempSubItems.push({ ...tempClients });
      }

      // PLATFORMS
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ALL_PLATFORMS)) {
        const tempPlatformConfig = { ...itemPlatformConfig };
        tempSubItems.push({ ...tempPlatformConfig });
      }

      // PROGRAMS
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ALL_PROGRAMS)) {
        const tempProgramConfig = { ...itemProgramConfig };
        tempSubItems.push({ ...tempProgramConfig });
      }

      // COMMUNICATION MANAGEMENT
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COMMS_SETTINGS)) {
        const tempProjectCommunicationSettings = {
          ...itemProjectCommunicationSettings,
        };
        tempSubItems.push({ ...tempProjectCommunicationSettings });
      }

      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PROGRAM_ONBOARDING)) {
        const tempProjectOnboardingSettings = {
          ...itemProjectOnBoardingSettings,
        };
        tempSubItems.push({ ...tempProjectOnboardingSettings });
      }

      // PROJECTS PAYMENT
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PROJECT_PAYMENTS)) {
        const tempPaymentSettings = { ...itemPaymentSettings };
        tempSubItems.push({ ...tempPaymentSettings });
      }

      // ROJECT DATA INTEGRATION MANAGEMENT
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DATA_INTEGRATION)) {
        const tempProjectDataIntegration = { ...itemProjectDataIntegration };
        tempSubItems.push({ ...tempProjectDataIntegration });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === `golf-admin`) {
      let tempSubItems = [...item.subItems];

      // REAL TIME SLOTS MANAGEMENT
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_ADMIN_MANAGEMENT)) {
        const tempGolfAdmin = { ...itemGolfAdmin };

        const tempChildItems = [];
        // COURSE WISE FORMATS
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_ADMIN_MANAGEMENT)) {
          tempChildItems?.push(courseWiseFormats);
        }

        // EDIT COURSE FORMATS
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_ADMIN_MANAGEMENT)) {
          tempChildItems?.push(editCourseFormats);
        }

        // REAL TIME SLOTS REPORT
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_ADMIN_MANAGEMENT)) {
          tempChildItems?.push(realTimeSlotsReport);
        }

        // SLOT UNAVAILABILITY
        if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_ADMIN_MANAGEMENT)) {
          tempChildItems?.push(slotsUnavailability);
        }

        tempSubItems.push({
          ...tempGolfAdmin,
          childItems: [...tempChildItems],
        });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === 'benefit-management') {
      let tempSubItems = [...item.subItems];

      // BENEFIT PACK
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_PACK)) {
        const tempBenefitPack = { ...itemBenefitPack };
        tempSubItems.push({ ...tempBenefitPack });
      }

      // BENEFIT GROUP
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_GROUP)) {
        const tempBenefitGroup = { ...itemBenefitGroup };
        tempSubItems.push({ ...tempBenefitGroup });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === 'reward-settings') {
      let tempSubItems = [...item.subItems];

      // BENEFIT CONFIG
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_CONFIG)) {
        const tempBenefitConfig = { ...itemBenefitConfig };
        tempSubItems.push({ ...tempBenefitConfig });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === 'coupons') {
      let tempSubItems = [...item.subItems];

      // STATIC COUPON
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.STATIC_COUPON)) {
        const tempStaticCoupon = { ...itemStaticCoupon };
        tempSubItems.push({ ...tempStaticCoupon });
      }

      // UNIQUE COUPON
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UNQIUE_COUPON)) {
        const tempUniqueCoupon = { ...itemUniqueCoupon };
        tempSubItems.push({ ...tempUniqueCoupon });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else if (item?.id === 'reports-management') {
      let tempSubItems = [...item.subItems];

      // PAYMENTS REPORT
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PAYMENT_LOGS)) {
        const tempProjectPaymentsLogs = { ...itemProjectPaymentLogs };
        tempSubItems.push({ ...tempProjectPaymentsLogs });
      }

      // COMMS REPORT
      if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COMMS_REPORTS)) {
        const tempCommsReport = { ...itemCommsReport };
        tempSubItems.push({ ...tempCommsReport });
      }

      return {
        ...item,
        subItems: tempSubItems,
      };
    } else {
      return item;
    }
  });

  const menuHandler = () => {
    let tempMenuItems = menuItems;

    const allAssignedPermissions = Constant?.CURRENTLY_ASSIGNED_PERMISSIONS;
    const allBookingTypesBasedOnSelectedProgram = JSON.parse(localStorage?.getItem('data'))?.program?.bookingTypes;

    const nullifyValues = [null, undefined, '', []];
    if (nullifyValues?.includes(allBookingTypesBasedOnSelectedProgram)) {
      return [];
    } else {
      tempMenuItems = tempMenuItems?.map(menuItem => {
        return allAssignedPermissions?.includes(menuItem?.name) && menuItem;
      });

      // tempMenuItems = tempMenuItems?.filter(menuItem => {
      //   if (menuItem?.name !== `ADD_USER_PERMISSION`) {
      //     return true;
      //   } else if (['Rahul karn']?.includes(Constant?.CURRENTLY_LOGGED_IN_USER?.name)) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });

      return tempMenuItems.filter(Boolean);
    }
  };

  menuHandler();
  return <React.Fragment>{menuHandler()}</React.Fragment>;
};
export default Navdata;

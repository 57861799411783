import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import Table from './components/Tables/Table';
import Filter from './components/Filters/Filter';
import UpdatePost from './components/Modals/UpdatePost';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader, PreviewCardHeader } from '../../../../../Common';
import {
  CLICK_DOWNLOAD_DUMP_EMPTY,
  DOWNLOAD_ADIB_BOOKING,
  DOWNLOAD_ADIB_BOOKING_EMPTY,
  GET_POST,
  GET_POST_EMPTY,
  GET_POST_SETTING,
} from '../../../../../../store/actions';
import UpdatePostSetting from './components/Modals/PostSetting';

// Define the shape of the filter object
interface FilterState {
  createdAtRange: {
    from: string;
    to: string;
  };
  sortOn: string;
}

// Define the shape of your Redux state
interface RootStateForGetPost {
  GetPost: {
    data: any;
    loading: boolean;
    error: any;
  };
}

interface RootStateForGetPostSetting {
  GetPostSetting: {
    data: any;
    loading: boolean;
    error: any;
  };
}

interface RootStateForUpdatePost {
  UpdatePost: {
    data: any;
    loading: boolean;
    error: any;
  };
}

interface RootStateForUpdatePostSetting {
  UpdatePostSetting: {
    data: any;
    loading: boolean;
    error: any;
  };
}

const Posts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState<boolean>(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [currentSelectedPostDetails, setCurrentSelectedPostDetails] = useState([]);
  const [isUpdateModalShow, setIsUpdateModalShow] = useState(false);
  const [updateType, setUpdateType] = useState('');
  const [isPostSettingModal, setIsPostSettingModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Posts
  const { getPost, getPostLoading } = useSelector((state: RootStateForGetPost) => ({
    getPost: state?.GetPost?.data?.data,
    getPostLoading: state?.GetPost?.loading,
  }));

  // Get Post Setting
  const { getPostSetting, getPostSettingLoading } = useSelector((state: RootStateForGetPostSetting) => ({
    getPostSetting: state?.GetPostSetting?.data?.data,
    getPostSettingLoading: state?.GetPostSetting?.loading,
  }));

  // Update Post
  const { updatePost, updatePostLoading } = useSelector((state: RootStateForUpdatePost) => ({
    updatePost: state?.UpdatePost?.data,
    updatePostLoading: state?.UpdatePost?.loading,
  }));

  // Update Post Setting
  const { updatePostSetting, updatePostSettingLoading } = useSelector((state: RootStateForUpdatePostSetting) => ({
    updatePostSetting: state?.UpdatePostSetting?.data,
    updatePostSettingLoading: state?.UpdatePostSetting?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector((state: any) => ({
    downloadDump: state?.DownloadAdibBookingDump?.data,
    downloadDumpLoading: state?.DownloadAdibBookingDump?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    return () => {
      dispatch({
        type: GET_POST_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (updatePost !== null) {
      dispatch({
        type: GET_POST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
          data: null,
        },
      });
    }
  }, [updatePost]);

  useEffect(() => {
    if (updatePostSetting !== null) {
      dispatch({
        type: GET_POST_SETTING,
      });
    }
  }, [updatePostSetting]);

  useEffect(() => {
    dispatch({
      type: GET_POST,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
        data: null,
      },
    });
    dispatch({
      type: GET_POST_SETTING,
    });
  }, [dispatch]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: DOWNLOAD_ADIB_BOOKING_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedPostDetails(params?.postDetails);
    if (params?.actionType === `ACCEPT`) {
      setIsUpdateModalShow(true);
      setUpdateType('Accept');
    } else if (params?.actionType === `REJECT`) {
      setIsUpdateModalShow(true);
      setUpdateType('Reject');
    } else if (params?.actionType === `BLOCK`) {
      setIsUpdateModalShow(true);
      setUpdateType('Block');
    } else if (params?.actionType === `UNBLOCK`) {
      setIsUpdateModalShow(true);
      setUpdateType('Unblock');
    } else if (params?.actionType === `DELETE`) {
      setIsUpdateModalShow(true);
      setUpdateType('Delete');
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    const user = JSON.parse(localStorage.getItem('authUser'));
    dispatch({
      type: DOWNLOAD_ADIB_BOOKING,
      payload: {
        data: {
          adminId: user?.data?.userId,
          projectCode: 'ADIB_V2',
          downloadType: 12,
          pageIndex: 0,
          pageSize: Number(getPost?.count),
          ...currentlyAppliedFilters,
        },
      },
    });
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = (filters: FilterState) => {
    if (filters) {
      setCurrentPageNumber(0);
      setCurrentlyAppliedFilters(filters);
      dispatch({
        type: GET_POST,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
          data: {
            ...filters,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_POST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (getPostLoading || updatePostLoading || downloadDumpLoading || getPostSettingLoading || updatePostSettingLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getPostLoading, updatePostLoading, downloadDumpLoading, getPostSettingLoading, updatePostSettingLoading]);

  return (
    <>
      {loading && <Loader zIndex={true} />}

      {isUpdateModalShow && (
        <UpdatePost data={currentSelectedPostDetails} onCloseHandler={() => setIsUpdateModalShow(false)} updateType={updateType} />
      )}

      {isPostSettingModal && <UpdatePostSetting onCloseHandler={() => setIsPostSettingModal(false)} postSettingDetails={getPostSetting} />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          <>
            <BreadCrumb title="Post Approval" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Post Approval`]} />
            <Row>
              <Col xxl={12}>
                <Card>
                  <PreviewCardHeader title="Post Section" />
                  <CardBody>
                    <div className="live-preview"></div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className={`btn w-md ${getPostSetting?.postCreationFlag === 1 ? 'btn-primary' : ''}`}
                        style={{
                          background: getPostSetting?.postCreationFlag === 0 ? '#F3F6F9' : '#33416e',
                          borderRadius: 0,
                          border: getPostSetting?.postCreationFlag === 0 ? '1px solid black' : undefined,
                          cursor: getPostSetting?.postCreationFlag === 0 ? 'pointer' : 'default',
                        }}
                        onClick={() => {
                          if (getPostSetting?.postCreationFlag === 0) setIsPostSettingModal(true);
                        }}
                      >
                        Active
                      </button>
                      <button
                        type="button"
                        className={`btn w-md ${getPostSetting?.postCreationFlag === 0 ? 'btn-primary' : ''}`}
                        style={{
                          borderRadius: 0,
                          background: getPostSetting?.postCreationFlag === 1 ? '#F3F6F9' : '#33416e',
                          border: getPostSetting?.postCreationFlag === 1 ? '1px solid black' : undefined,
                          cursor: getPostSetting?.postCreationFlag === 1 ? 'pointer' : 'default',
                        }}
                        onClick={() => {
                          if (getPostSetting?.postCreationFlag === 1) setIsPostSettingModal(true);
                        }}
                      >
                        InActive
                      </button>
                    </div>
                    <p className="text-center mt-4">This feature will enable or disable post feature from the platform</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />
            <Table
              data={getPost?.data}
              totalData={getPost?.count}
              pageNumber={currentPageNumber}
              pageHandler={paginationHandler}
              actionHandler={tableActionHandler}
              dumpDownload={downloadDumpHandler}
              appliedFilters={currentlyAppliedFilters}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default Posts;

import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  baseUrl,
  customHeaders,
  commsHeader,
  indianProjectsBaseURL,
  uaeProjectsBaseURL,
  ksaProjectsBaseURL,
} from '../../../../Containers/Config/index.js';

import { VENDOR_BOOKINGS_DATA, VENDOR_BOOKINGS_DATA_SUCCESS, VENDOR_BOOKINGS_DATA_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* getVendorBookings({ payload }) {
  const headers = payload?.headers?.code
    ? { Authorization: Constant?.CLIENT_TOKEN, 'Content-Type': 'application/json', 'Project-Code': payload?.headers?.code }
    : { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders, ...payload.headers };

  let projectBaseURL;

  if (Constant.PROJECT_CODE === 'SAB') {
    projectBaseURL = ksaProjectsBaseURL;
  } else if (Constant.PROJECT_CODE === 'THRIWE_DINING_V1') {
    projectBaseURL = indianProjectsBaseURL;
  } else {
    projectBaseURL = uaeProjectsBaseURL;
  }

  try {
    const response = yield call(apiCall, {
      url: `${projectBaseURL}/vendor/v1/get-bookings`,
      method: 'POST',
      headers: headers,
      params: {
        bookingTypeId: payload?.urlParam?.bookingTypeId,
        pageIndex: payload?.urlParam?.pageIndex,
        pageSize: Constant?.TABLE_PAGE_SIZE,
        sortOn: payload?.urlParam?.sortOn,
        ...payload?.urlParam,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({ type: VENDOR_BOOKINGS_DATA_SUCCESS, payload: response?.data });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: VENDOR_BOOKINGS_DATA_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: VENDOR_BOOKINGS_DATA_ERROR, payload: error });
  }
}

export function* watchGetVendorBookings() {
  yield takeEvery(VENDOR_BOOKINGS_DATA, getVendorBookings);
}

function* vendorBookingsSaga() {
  yield all([fork(watchGetVendorBookings)]);
}

export default vendorBookingsSaga;

import React, { useEffect, useState } from 'react';
import { makerCheckerStatusEnum } from '../../enums/maker-checker-status-enum';
import { makerCheckerConfigs, makerCheckerEnum } from '../../enums/maker-checker-configs';
//redux
import { useDispatch, useSelector } from 'react-redux';

import { withRouter } from 'react-router-dom';
import ParticlesAuth from '../../../pages/AuthenticationInner/ParticlesAuth';
import FullLoader from '../../Common/Loader/CompleteLoader';

import { Button, Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  GET_PERMISSION_CHECKER,
  GET_PERMISSION_CHECKER_EMPTY,
  UDPATE_PERMISSION_CHECKER,
  UDPATE_PERMISSION_CHECKER_EMPTY,
} from '../../../store/actions';

const rejectValidationSchema = Yup.object().shape({
  rejectMessage: Yup.string().required('Reject message is required'),
});

const BenefitPackDownloadPermission = props => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const [isLoading, setIsLoading] = useState(false);

  const { getPermissionChecker, getPermissionCheckerLoading, getPermissionCheckerError } = useSelector(state => ({
    getPermissionChecker: state?.PermissionChecker?.data,
    getPermissionCheckerLoading: state?.PermissionChecker?.loading,
    getPermissionCheckerError: state?.PermissionChecker?.error,
  }));

  const { updatePermissionRequestData, updatePermissionRequestLoading, updatePermissionRequestError } = useSelector(state => ({
    updatePermissionRequestData: state?.UpdatePermissionRequest?.data,
    updatePermissionRequestLoading: state?.UpdatePermissionRequest?.loading,
    updatePermissionRequestError: state?.UpdatePermissionRequest?.error,
  }));


  useEffect(() => {
    if (getPermissionCheckerError) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [getPermissionCheckerError]);

  useEffect(() => {
    dispatch({
      type: GET_PERMISSION_CHECKER,
      payload: {
        token: token,
        data: null,
      },
    });

    return () => {
      dispatch({
        type: GET_PERMISSION_CHECKER_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (updatePermissionRequestData !== null || updatePermissionRequestError !== null) {
      dispatch({
        type: UDPATE_PERMISSION_CHECKER_EMPTY,
      });

      dispatch({
        type: GET_PERMISSION_CHECKER,
        payload: {
          token: token,
          data: null,
        },
      });
      setIsLoading(false);
    }
  }, [updatePermissionRequestData, updatePermissionRequestError]);

  useEffect(() => {
    console.log('makerCheckerConfigs', getPermissionChecker);
  }, [getPermissionChecker]);

  const setPermissionAction = (acceptType, rejectedRemarks = '') => {
    console.log('acceptType12', acceptType, rejectedRemarks);

    if (acceptType === `ACCEPTED`) {
      setIsLoading(true);
      dispatch({
        type: UDPATE_PERMISSION_CHECKER,
        payload: {
          token: token,
          data: {
            status: 2,
          },
        },
      });
    } else if (acceptType === `REJECTED` && rejectedRemarks?.trim() !== ``) {
      setIsLoading(true);
      dispatch({
        type: UDPATE_PERMISSION_CHECKER,
        payload: {
          token: token,
          data: {
            status: 3,
            reason_for_rejection: `${rejectedRemarks}`,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!token) {
      alert('This URL is not allowed, kindly provided valid token');
    }
  }, [token]);

  let statusObj = {};
  const status = () => {
    switch (getPermissionChecker?.status) {
      case makerCheckerStatusEnum.pending:
        statusObj = {
          key: makerCheckerStatusEnum.pending,
          className: 'primary',
          title: 'Pending',
        };
        break;

      case makerCheckerStatusEnum.accepted:
        statusObj = {
          key: makerCheckerStatusEnum.accepted,
          className: 'success',
          title: 'Accepted',
        };
        break;

      case makerCheckerStatusEnum.rejected:
        statusObj = {
          key: makerCheckerStatusEnum.rejected,
          className: 'danger',
          title: 'Rejected',
        };
        break;

      default:
      // do nothing
    }
    return <span className={`badge border border-primary text-${statusObj?.className}`}> {statusObj?.title}</span>;
  };

  console.log('getPermissionChecker', getPermissionChecker);

  return (
    <div>
      {token ? (
        <React.Fragment>
          <ParticlesAuth>
            {isLoading && <FullLoader />}
            <div className="container d-flex" style={{ height: '100vh' }}>
              <div className="box-2 d-flex flex-column">
                <div className="mb-2">
                  <Card className="shadow mb-0">
                    <CardBody>
                      <div>
                        <div className="d-flex py-3 my-2 px-2 rounded flex-column flex-sm-row" style={{ background: '#ecf6ff' }}>
                          <div className="col-sm-6">
                            <b>Requestor Name</b>: {getPermissionChecker?.name}
                          </div>

                          <div className="col-sm-6">
                            <b>Requested By</b>: {getPermissionChecker?.email_id}
                          </div>
                        </div>
                        <div className="border border-light rounded p-3">
                          <div className="ms-auto text-end">{status()}</div>
                          <h5 className="text-center">
                            <b>Pack Details</b>
                          </h5>
                          <div className="row">
                            <div className="col-sm-6" style={{ lineHeight: '25px' }}>
                              <div>
                                <b>Pack Count</b>: {getPermissionChecker?.metadata?.pack_count}
                              </div>

                              <div>
                                <b>Pack Name</b>: {getPermissionChecker?.metadata?.pack_name}
                              </div>

                              <div>
                                <b>Pack Total Cost</b>: {getPermissionChecker?.metadata?.pack_total_cost}
                              </div>

                              <div>
                                <b>Pack Unit Cost</b>: {getPermissionChecker?.metadata?.pack_unit_cost}
                              </div>

                              <div>
                                <b>Program Name</b>: {getPermissionChecker?.program_name}
                              </div>
                            </div>
                            <div className="col-sm-6" style={{ lineHeight: '25px' }}>
                              {getPermissionChecker?.status === makerCheckerStatusEnum.rejected && (
                                <div>
                                  <b>Rejected remarks</b>: {getPermissionChecker?.reason_for_rejection}
                                </div>
                              )}

                              <div>
                                <b>Applicable Date</b>: {moment(getPermissionChecker?.metadata?.applicable_date).format('DD-MM-YYYY hh:mmA')}
                              </div>

                              <div>
                                <b>Expiry Date</b>: {moment(getPermissionChecker?.metadata?.expiry_date).format('DD-MM-YYYY hh:mmA')}
                              </div>

                              <div>
                                <b>Creation Date</b>: {moment(getPermissionChecker?.metadata?.creation_date).format('DD-MM-YYYY hh:mmA')}
                              </div>

                              <div>
                                <b>Generated By</b>: {getPermissionChecker?.metadata?.generated_by}
                              </div>
                            </div>
                          </div>

                          {getPermissionChecker?.status !== makerCheckerStatusEnum.pending && (
                            <div className={`mt-3 text-center text-${statusObj.className}`}>
                              Already <span>{statusObj?.title}</span> by {getPermissionChecker?.checker_email_id}
                            </div>
                          )}
                        </div>
                      </div>

                      {getPermissionChecker?.status == makerCheckerStatusEnum.pending && (
                        <div className="d-flex gap-2 justify-content-center mt-3">
                          <div>
                            <button className="btn btn-primary" onClick={() => setPermissionAction(`ACCEPTED`)} disabled={isLoading}>
                              Accept
                            </button>
                          </div>
                          <div>
                            <RejectModal setIsLoading={setIsLoading} setPermissionAction={setPermissionAction} />
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </ParticlesAuth>
        </React.Fragment>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withRouter(BenefitPackDownloadPermission);

function RejectModal({ setIsLoading, setPermissionAction }) {
  const [rejectModal, setRejectModal] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    // setSubmitting(false); // Reset the submitting state
    // setIsLoading(true);

    if (values?.rejectMessage?.trim() !== ``) {
      setRejectModal(false);
      setPermissionAction(`REJECTED`, values?.rejectMessage);
    }
  };

  return (
    <>
      <Modal isOpen={rejectModal} toggle={() => setRejectModal(false)} id="exampleModal">
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Are you sure you want to go ahead?
          </h5>
          <Button type="button" onClick={() => setRejectModal(false)} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close" />
        </div>

        <ModalBody>
          <Formik initialValues={{ rejectMessage: '' }} validationSchema={rejectValidationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <Field as="textarea" className="form-control" name="rejectMessage" rows={5} />
                  <ErrorMessage name="rejectMessage" component="div" className="text-danger" />
                </div>

                <div className="mt-4 text-center">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      <button className="btn btn-danger" onClick={() => setRejectModal(true)}>
        Reject
      </button>
    </>
  );
}

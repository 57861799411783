import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';

const couponStatus = ['In-Process', 'Uploaded', 'Assigning In-process', 'Assigned'];

const CouponInfoTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '80px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          wrap: true,
        },

        {
          name: 'Uploaded by',
          width: '170px',
          selector: row => row?.uploadedBy,
          wrap: true,
        },

        {
          name: 'Coupon Count',
          width: '130px',
          selector: row => row?.couponCount,
          wrap: true,
        },

        {
          name: 'Lot No.',
          width: '100px',
          selector: row => row?.lotNumber,
          wrap: true,
        },

        {
          name: 'Services',
          width: '300px',
          selector: row => row?.service,
          wrap: true,
        },

        {
          name: 'Uploaded On',
          width: '180px',
          selector: row => row?.uploadedOn,
          wrap: true,
        },

        {
          name: 'Expiry On',
          width: '180px',
          selector: row => row?.expiryOn,
          wrap: true,
        },

        {
          name: 'Remark',
          width: '220px',
          selector: row => row?.remarks,
          wrap: true,
        },
        {
          name: 'Status',
          width: '130px',
          cell: row => (
            <span
              className={`${row?.status === 'Assigned' && 'text-success'} ${row?.status === 'Uploaded' && 'text-danger'} ${
                row?.status === 'In-Process' && 'text-warning'
              } ${row?.status === 'Assigning In-process' && 'text-secondary'}`}
            >
              {row?.status}
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '200px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column p-2">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_ASSIGN) && couponStatus[row?.data?.status - 1] === couponStatus[1] && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`ASSIGN`, row?.data);
                  }}
                  style={{ width: '150px' }}
                >
                  Assign
                </span>
              )}

              <span
                className="btn btn-sm btn-primary mb-1"
                onClick={() => {
                  actionBtnHandler(`DOWNLOAD`, row?.data);
                }}
                style={{ width: '150px' }}
              >
                Download
              </span>

              {/* {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_READ) && couponStatus[row?.data?.status - 1] === couponStatus[2] && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`VIEW`, row?.data);
                  }}
                  style={{ width: '150px' }}
                >
                  View
                </span>
              )} */}

              {/* {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_READ) && couponStatus[row?.data?.status - 1] === couponStatus[3] && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`VIEW`, row?.data);
                  }}
                  style={{ width: '150px' }}
                >
                  View
                </span>
              )} */}

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_ASSIGN) && couponStatus[row?.data?.status - 1] === couponStatus[3] && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`CHANGE_LOT_EXPIRY`, row?.data);
                  }}
                  style={{ width: '150px' }}
                >
                  Change Expiry
                </span>
              )}

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UNQIUE_COUPON_UPDATE) &&
                couponStatus[row?.data?.status - 1] === couponStatus[3] && (
                  <span
                    className="btn btn-sm btn-primary mb-1"
                    onClick={() => {
                      actionBtnHandler(`UPDATE`, row?.data);
                    }}
                    style={{ width: '150px' }}
                  >
                    Update
                  </span>
                )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);
      setData(
        props?.data?.map((data, index) => {
          let service = '';
          if (data?.services && data?.services?.length > 0) {
            service = data?.services?.map(service => service?.name).join(', ');
          }
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            uploadedBy: data?.uploadedBy?.name || `NA`,
            couponCount: data?.couponCount || `NA`,
            lotNumber: data?.lotNumber || `NA`,
            service: service ? service : 'Not Assigned',
            uploadedOn: data?.createdAt ? moment(`${data?.createdAt}`)?.format('DD-MM-YYYY, h:mm A') : `NA`,
            expiryOn: data?.expiryDate ? moment(`${data?.expiryDate}`)?.format('DD-MM-YYYY, h:mm A') : `NA`,
            remarks: data?.remarks,
            status: couponStatus[data?.status - 1],
            data,
          };
        }),
      );
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `ASSIGN`) {
      props.actionHandler({
        couponDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        couponDetails: data,
        actionType: flag,
      });
    } else if (flag === `CHANGE_LOT_EXPIRY`) {
      props.actionHandler({
        couponDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        couponDetails: data,
        actionType: flag,
      });
    } else if (flag === `DOWNLOAD`) {
      props.actionHandler({
        couponDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Coupon's Data</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_UPLOAD) && (
                <span className="btn btn-primary mb-1 mx-1" onClick={props?.clickHandler}>
                  <i className="ri-upload-cloud-2-line me-1 text-white fs-14 align-middle"></i>Upload Coupon
                </span>
              )}

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-primary mb-1 mx-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download
                </span>
              )}

              {props?.data?.length > 0 && (
                <span className="btn btn-success mb-1 mx-1" onClick={props.refreshHandler}>
                  <i className="ri-refresh-line me-1 text-white fs-14 align-middle"></i>Refresh
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CouponInfoTable;

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Button, Modal, ModalBody, Col, Row, Label, Input, ModalHeader, ModalFooter } from 'reactstrap';

import { Error } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import { isANumber, isValidAplha } from '../../../../../Helpers/Helpers';
import { POST_COUPONS } from '../../../../../../store/application/actionType';
import dummyExcel from './../../../../../../assets/static-files/couponSample.xlsx';

const invoicesStatus = [
  { label: 'Paid', value: 'Paid' },
  { label: 'Pending', value: 'Pending' },
];

const couponTypes = [
  { label: 'Code', value: '1' },
  { label: 'Pin', value: '2' },
  { label: 'Url', value: '3' },
];

const CouponUploadModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    remarks: '',
    file: '',
    expiryDate: '',
    lotNumber: '',
    couponType: null,

    lotPrice: '',
    perCouponPrice: '',
    number: '',
    date: '',
    status: '',
    vendorName: '',
  };
  const [state, setState] = useState(initialObj);

  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isBoolean, setIsBoolean] = useState(false);
  const [isEncryptionFlow, setIsEncryptionFlow] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    let lotPrice = '';
    let perCouponPrice = '';
    let lotNumber = '';

    switch (name) {
      case 'uploaded-coupon':
        updatedState = { ...state, file: value, fileData: e?.target?.files[0] };
        break;

      case 'coupon-remarks':
        updatedState = { ...state, remarks: value };
        break;

      case 'lot-number':
        if (value?.length === 0) {
          lotNumber = ``;
        } else {
          lotNumber = isANumber(value) ? value : state.lotNumber;
        }
        updatedState = {
          ...state,
          lotNumber: lotNumber,
        };
        break;

      case 'lotPrice':
        if (value?.length === 0) {
          lotPrice = ``;
        } else {
          lotPrice = isANumber(value) ? value : state.lotPrice;
        }
        updatedState = {
          ...state,
          lotPrice: lotPrice,
        };
        break;

      case 'perCouponPrice':
        if (value?.length === 0) {
          perCouponPrice = ``;
        } else {
          perCouponPrice = isANumber(value) ? value : state.perCouponPrice;
        }
        updatedState = {
          ...state,
          perCouponPrice: perCouponPrice,
        };
        break;

      case 'vendorName':
        updatedState = {
          ...state,
          vendorName: isValidAplha(value) ? value : state?.vendorName,
        };
        break;

      case 'number':
        updatedState = {
          ...state,
          number: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `invoice-status`) {
      updatedState = {
        ...state,
        status: data,
      };
    } else if (label === `couponType`) {
      updatedState = {
        ...state,
        couponType: data,
      };
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          expiryDate: new Date(date).toISOString(),
        };
        break;

      case 'date':
        updatedState = {
          ...state,
          date: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      onCloseHandler();

      const formData = new FormData();
      formData.append('file', state?.fileData);
      formData.append('type', 1);
      formData.append('couponType', state?.couponType?.value);
      formData.append('remarks', state?.remarks);
      formData.append('lotNumber', state?.lotNumber);

      formData.append('lotPrice', state?.lotPrice);
      formData.append('perCouponPrice', state?.perCouponPrice);
      formData.append('number', state?.number);
      formData.append('date', state?.date);
      formData.append('vendorName', state?.vendorName);
      formData.append('status', state?.status?.value ?? '');
      formData.append('expiryDate', new Date(state?.expiryDate).toISOString());
      formData.append('isEncryptionFlow', isEncryptionFlow);

      dispatch({
        type: POST_COUPONS,
        payload: {
          data: formData,
        },
      });
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.file !== `` &&
      data?.expiryDate !== `` &&
      data?.lotNumber !== `` &&
      data?.remarks !== `` &&
      data?.lotPrice !== `` &&
      data?.perCouponPrice !== `` &&
      data?.vendorName !== `` &&
      data?.number !== `` &&
      data?.status !== `` &&
      data?.date !== `` &&
      data?.couponType !== null &&
      moment() < moment(data?.expiryDate) &&
      moment() < moment(data?.date) &&
      [...(Constant?.EXCEL_FILE_FORMATS ?? [])]?.includes(state?.file.split('.')?.pop())
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- DOWNLOAD DUMMY EXCEL FORMAT HANDLER ---------------------------- */
  const downloadSampleHandler = () => {
    window.open(dummyExcel, '_self');
  };

  return (
    <Modal isOpen={true} scrollable={true}>
      <ModalHeader
        className="bg-soft-info p-3"
        toggle={() => {
          onCloseHandler(false);
        }}
      >
        Add Coupon
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <div>
                {/* <button
                    onClick={e => {
                      e.preventDefault();
                      console.log('test', state);
                    }}
                  >
                    get state
                  </button> */}

                <Label htmlFor="formatExcel" className="form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    Upload Coupon <span className="lbl_mandatory">*</span>
                  </span>
                  <span className="download-sample-excel" onClick={downloadSampleHandler}>
                    Download Sample Format
                  </span>
                </Label>
                <Input
                  type="file"
                  name="uploaded-coupon"
                  id="uploaded-coupon"
                  value={state?.file}
                  onChange={inputChangeHandler}
                  className="form-control"
                />
              </div>
              {isValidationShow && state?.file === `` && <Error text="Please upload file" />}
              {isValidationShow && state?.file !== `` && ![...(Constant?.EXCEL_FILE_FORMATS ?? [])]?.includes(state?.file.split('.')?.pop()) && (
                <Error text="Please upload proper file format" />
              )}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="coupon-remarks" className="form-label">
                  Expiry Date <span className="lbl_mandatory">*</span>
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  name="expiry-date"
                  id="expiry-date"
                  value={state?.expiryDate}
                  onChange={date => datePickHandler(`expiryDate`, date)}
                  options={{
                    // minDate: 'today',
                    defaultMinute: '59',
                    defaultHour: '23',
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                  }}
                />
              </div>
              {isValidationShow && state?.expiryDate === `` && <Error text="Please select expiry date" />}
              {isValidationShow && state?.expiryDate !== `` && moment() > moment(state?.expiryDate) && <Error text="Invalid date" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="lot-number" className="form-label">
                  Lot No. <span className="lbl_mandatory">*</span>
                </Label>
                <Input
                  type="text"
                  name="lot-number"
                  id="lot-number"
                  value={state?.lotNumber}
                  onChange={inputChangeHandler}
                  className="form-control form-control"
                />
              </div>
              {isValidationShow && state?.lotNumber?.trim() === `` && <Error text="Please enter Lot Number" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label className="form-label">
                Lot Price
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input id="lotPrice" name="lotPrice" type="text" className="form-control" onChange={inputChangeHandler} value={state?.lotPrice} />
              {isValidationShow && state?.lotPrice?.trim() === `` && <Error text="Please enter Lot Price" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label className="form-label">
                Invoice Number
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input id="number" name="number" type="text" className="form-control" onChange={inputChangeHandler} value={state?.number} />
              {isValidationShow && state?.number?.trim() === `` && <Error text="Please enter invoice number" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label className="form-label">
                Per Coupon Price
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                id="perCouponPrice"
                name="perCouponPrice"
                type="text"
                className="form-control"
                onChange={inputChangeHandler}
                value={state?.perCouponPrice}
              />
              {isValidationShow && state?.perCouponPrice?.trim() === `` && <Error text="Please enter coupon price" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="vendorName" className="form-label">
                  Vendor Name <span className="lbl_mandatory">*</span>
                </Label>
                <Input
                  type="text"
                  name="vendorName"
                  id="vendorName"
                  value={state?.vendorName}
                  onChange={inputChangeHandler}
                  className="form-control form-control"
                />
              </div>
              {isValidationShow && state?.vendorName?.trim() === `` && <Error text="Please enter vendor name" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="status" className="form-label">
                  Coupon Type <span className="lbl_mandatory">*</span>
                </Label>
                <Select
                  id="couponType"
                  name="couponType"
                  isMulti={false}
                  options={couponTypes}
                  value={state?.couponType}
                  onChange={data => inputSelectHandler(`couponType`, data)}
                  style={{
                    border: '4px solid #ced4da',
                    bordeRadius: '0.25rem',
                  }}
                />
              </div>
              {isValidationShow && state?.couponType === null && <Error text="Please select coupon type" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="status" className="form-label">
                  Invoice Status <span className="lbl_mandatory">*</span>
                </Label>
                <Select
                  id="invoice-status"
                  name="invoice-status"
                  isMulti={false}
                  options={invoicesStatus}
                  value={state?.status}
                  onChange={data => inputSelectHandler(`invoice-status`, data)}
                  style={{
                    border: '4px solid #ced4da',
                    bordeRadius: '0.25rem',
                  }}
                />
              </div>
              {isValidationShow && state?.status === `` && <Error text="Please select invoice status" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="date" className="form-label">
                  Invoice Date <span className="lbl_mandatory">* </span>
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <div className="area_expandable">
                  <Flatpickr
                    className="form-control"
                    name="date"
                    id="date"
                    value={state?.date}
                    onChange={date => datePickHandler(`date`, date)}
                    options={{
                      // minDate: 'today',
                      defaultMinute: '59',
                      defaultHour: '23',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }}
                  />
                </div>
              </div>
              {isValidationShow && state?.date === `` && <Error text="Please select invoice date" />}
              {isValidationShow && state?.date !== `` && moment() > moment(state?.date) && <Error text="Invalid date" />}
            </div>
          </Col>

          <Col md={12} className="mb-3">
            <div className="form-switch d-flex align-items-center">
              <Input
                className="form-check-input code-switcher form-switch-md cursor-pointer"
                type="checkbox"
                id="isEncryptionFlow"
                name="isEncryptionFlow"
                checked={isEncryptionFlow}
                onChange={() => setIsEncryptionFlow(!isEncryptionFlow)}
              />
              <span>&nbsp; Voucher Encryption Required </span>
            </div>
          </Col>

          {/* <Col md={12}>
            <div className="mb-3">
              <div className="form-switch d-flex align-items-center mb-3">
                <Input
                  className="form-check-input code-switcher form-switch-md cursor-pointer"
                  type="checkbox"
                  name="isValidationRegexRequired"
                  checked={isBoolean}
                  onChange={() => {
                    setIsBoolean(!isBoolean);
                  }}
                />
                <span>&nbsp; Validation Regex Required</span>
              </div>
            </div>
          </Col> */}

          <div>
            <Col md={12}>
              <div>
                <Label htmlFor="coupon-remarks" className="form-label">
                  Remarks <span className="lbl_mandatory">*</span>
                </Label>
                <textarea
                  rows="3"
                  id="coupon-remarks"
                  name="coupon-remarks"
                  className="form-control"
                  value={state?.remarks}
                  onChange={inputChangeHandler}
                ></textarea>
              </div>
              {isValidationShow && state?.remarks?.trim() === `` && <Error text="Please enter remarks" />}
            </Col>
          </div>
        </Row>
      </ModalBody>

      <ModalFooter className="border border-top-1 d-flex justify-content-center">
        <div className="hstack gap-2">
          <Button
            color="light"
            onClick={() => {
              onCloseHandler(false);
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CouponUploadModal;

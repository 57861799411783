import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { BreadCrumb, Loader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import {
  GET_CLIENT_DOWNLOAD_DUMP_ADIB,
  GET_CLIENT_DOWNLOAD_DUMP_ADIB_EMPTY,
  GET_VENDOR_DOWNLOADS_INFO,
} from '../../../../../../store/application/actionType';

const ClientBookingsDownloads = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.bookingTypes?.GOLF_BOOKINGS);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    client: {
      label: `${JSON.parse(localStorage?.getItem('data'))?.program?.name}`,
      value: `${JSON.parse(localStorage?.getItem('data'))?.program?.code}`,
    },
  };
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Downloads
  const { downloads, downloadsLoading } = useSelector((state: any) => ({
    downloads: state?.ClientDownloadDumpADIB?.data,
    downloadsLoading: state?.ClientDownloadDumpADIB?.loading,
  }));

  console.log('download', downloads);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (downloads === null) {
      dispatch({
        type: GET_CLIENT_DOWNLOAD_DUMP_ADIB,
        payload: {
          urlParam: {
            user_id: JSON.parse(localStorage.getItem('authUser'))?.data?.userId,
          },
          headers: {
            'Project-Code': 'ADIB_V2',
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_CLIENT_DOWNLOAD_DUMP_ADIB_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    console.log('params', params);
    if (params?.actionType === `DOWNLOAD`) {
      // setCurrentSelectedBookingDetails(params?.downloadDetails);
      window.open(params?.downloadDetails?.file, `_blank`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_VENDOR_DOWNLOADS_INFO,
      payload: {
        urlParam: {
          // bookingTypeId,
          // downloadType: 1,
          projectId: JSON.parse(window.localStorage.getItem('data'))?.program?.id,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
        headers: {
          'Project-Code': 'ADIB_V2',
        },
      },
    });
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    dispatch({
      type: GET_CLIENT_DOWNLOAD_DUMP_ADIB,
      payload: {
        urlParam: {
          user_id: JSON.parse(localStorage.getItem('authUser'))?.data?.userId,
        },
        headers: {
          'Project-Code': 'ADIB_V2',
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (downloadsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    } else if (title === `Downloads`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[t(`Dashboard`), t(`Downloads`)]} />
          <Table
            data={downloads?.data}
            totalData={downloads?.count}
            pageNumber={currentPageNumber}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default ClientBookingsDownloads;

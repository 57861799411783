/* ---------------------------- THRIWE-ONE-ADMIN ---------------------------- */

/* ---------------------------- COMMON API'S ---------------------------- */
// MAIN AREA LOADER
export const MAIN_AREA_LOADER_SHOW = `MAIN_AREA_LOADER_SHOW`;
export const MAIN_AREA_LOADER_HIDE = `MAIN_AREA_LOADER_HIDE`;

// FULL SCREEN LOADER
export const FULL_SCREEN_LOADER_SHOW = `FULL_SCREEN_LOADER_SHOW`;
export const FULL_SCREEN_LOADER_HIDE = `FULL_SCREEN_LOADER_HIDE`;

// LOGIN
export const LOGIN = `LOGIN`;
export const LOGIN_SUCCESS = `LOGIN_SUCCESS`;
export const LOGIN_ERROR = `LOGIN_ERROR`;
export const LOGIN_EMPTY = `LOGIN_EMPTY`;

// LOGOUT
export const LOGOUT = `LOGOUT`;
export const LOGOUT_SUCCESS = `LOGOUT_SUCCESS`;
export const LOGOUT_ERROR = `LOGOUT_ERROR`;

// DUMP
export const DOWNLOAD_DUMP = `DOWNLOAD_DUMP`;
export const DOWNLOAD_DUMP_SUCCESS = `DOWNLOAD_DUMP_SUCCESS`;
export const DOWNLOAD_DUMP_ERROR = `DOWNLOAD_DUMP_ERROR`;

// MAILS
export const SEND_MAIL = `SEND_MAIL`;
export const SEND_MAIL_SUCCESS = `SEND_MAIL_SUCCESS`;
export const SEND_MAIL_ERROR = `SEND_MAIL_ERROR`;

// GET GOLF COURSES
export const GET_GOLF_COURSES = `GET_GOLF_COURSES`;
export const GET_GOLF_COURSES_SUCCESS = `GET_GOLF_COURSES_SUCCESS`;
export const GET_GOLF_COURSES_ERROR = `GET_GOLF_COURSES_ERROR`;

// GET PROJECTS
export const GET_PROJECTS = `GET_PROJECTS`;
export const GET_PROJECTS_SUCCESS = `GET_PROJECTS_SUCCESS`;
export const GET_PROJECTS_ERROR = `GET_PROJECTS_ERROR`;

// GET COUNTRY
export const GET_COUNTRY = `GET_COUNTRY`;
export const GET_COUNTRY_SUCCESS = `GET_COUNTRY_SUCCESS`;
export const GET_COUNTRY_ERROR = `GET_COUNTRY_ERROR`;

// GET REGION
export const GET_REGION = `GET_REGION`;
export const GET_REGION_SUCCESS = `GET_REGION_SUCCESS`;
export const GET_REGION_ERROR = `GET_REGION_ERROR`;
export const GET_REGION_EMPTY = `GET_REGION_EMPTY`;

// GET SUBREGION
export const GET_SUB_REGION = `GET_SUB_REGION`;
export const GET_SUB_REGION_SUCCESS = `GET_SUB_REGION_SUCCESS`;
export const GET_SUB_REGION_ERROR = `GET_SUB_REGION_ERROR`;
export const GET_SUB_REGION_EMPTY = `GET_SUB_REGION_EMPTY`;

// GET BENEFIT GROUPS
export const GET_BENEFIT_GROUPS = `GET_BENEFIT_GROUPS`;
export const GET_BENEFIT_GROUPS_SUCCESS = `GET_BENEFIT_GROUPS_SUCCESS`;
export const GET_BENEFIT_GROUPS_ERROR = `GET_BENEFIT_GROUPS_ERROR`;

// GET CLIENTS
export const GET_CLIENTS = `GET_CLIENTS`;
export const GET_CLIENTS_SUCCESS = `GET_CLIENTS_SUCCESS`;
export const GET_CLIENTS_ERROR = `GET_CLIENTS_ERROR`;
export const GET_CLIENTS_EMPTY = `GET_CLIENTS_EMPTY`;

// GET AIRPORTS
export const GET_AIRPORTS_LIST = `GET_AIRPORTS_LIST`;
export const GET_AIRPORTS_LIST_SUCCESS = `GET_AIRPORTS_LIST_SUCCESS`;
export const GET_AIRPORTS_LIST_ERROR = `GET_AIRPORTS_LIST_ERROR`;
export const GET_AIRPORTS_LIST_EMPTY = `GET_AIRPORTS_LIST_EMPTY`;

// GET BOOKING TYPES
export const GET_BOOKING_TYPES_LIST = `GET_BOOKING_TYPES_LIST`;
export const GET_BOOKING_TYPES_LIST_SUCCESS = `GET_BOOKING_TYPES_LIST_SUCCESS`;
export const GET_BOOKING_TYPES_LIST_ERROR = `GET_BOOKING_TYPES_LIST_ERROR`;
export const GET_BOOKING_TYPES_LIST_EMPTY = `GET_BOOKING_TYPES_LIST_EMPTY`;

// GET PERMISSIONS BY PROJECT ID
export const GET_PERMISSIONS_BY_PROJECT_ID = `GET_PERMISSIONS_BY_PROJECT_ID`;
export const GET_PERMISSIONS_BY_PROJECT_ID_SUCCESS = `GET_PERMISSIONS_BY_PROJECT_ID_SUCCESS`;
export const GET_PERMISSIONS_BY_PROJECT_ID_ERROR = `GET_PERMISSIONS_BY_PROJECT_ID_ERROR`;
export const GET_PERMISSIONS_BY_PROJECT_ID_EMPTY = `GET_PERMISSIONS_BY_PROJECT_ID_EMPTY`;

//  DELETE FILE
export const DELETE_FILE = `DELETE_FILE`;
export const DELETE_FILE_SUCCESS = `DELETE_FILE_SUCCESS`;
export const DELETE_FILE_ERROR = `DELETE_FILE_ERROR`;
export const EMPTY_DELETE_FILE = `EMPTY_DELETE_FILE`;

/* ----------------------------------- DASHBOARD ----------------------------------- */
// GET SURGE REPORT
export const GET_SURGE_REPORT = `GET_SURGE_REPORT`;
export const GET_SURGE_REPORT_SUCCESS = `GET_SURGE_REPORT_SUCCESS`;
export const GET_SURGE_REPORT_ERROR = `GET_SURGE_REPORT_ERROR`;
export const GET_SURGE_REPORT_EMPTY = `GET_SURGE_REPORT_EMPTY`;

// GET USER INFO
export const GET_USER_LIST = `GET_USER_LIST`;
export const GET_USER_LIST_SUCCESS = `GET_USER_LIST_SUCCESS`;
export const GET_USER_LIST_ERROR = `GET_USER_LIST_ERROR`;
export const GET_USER_LIST_EMPTY = `GET_USER_LIST_EMPTY`;

// GET USER POINTS
export const GET_USER_POINTS = `GET_USER_POINTS`;
export const GET_USER_POINTS_SUCCESS = `GET_USER_POINTS_SUCCESS`;
export const GET_USER_POINTS_ERROR = `GET_USER_POINTS_ERROR`;
export const GET_USER_POINTS_EMPTY = `GET_USER_POINTS_EMPTY`;

// GET USER BOOKINGS
export const GET_USER_BOOKINGS = `GET_USER_BOOKINGS`;
export const GET_USER_BOOKINGS_SUCCESS = `GET_USER_BOOKINGS_SUCCESS`;
export const GET_USER_BOOKINGS_ERROR = `GET_USER_BOOKINGS_ERROR`;
export const GET_USER_BOOKINGS_EMPTY = `GET_USER_BOOKINGS_EMPTY`;

// GET MIS REPORT
export const GET_MIS_REPORT = `GET_MIS_REPORT`;
export const GET_MIS_REPORT_SUCCESS = `GET_MIS_REPORT_SUCCESS`;
export const GET_MIS_REPORT_ERROR = `GET_MIS_REPORT_ERROR`;
export const GET_MIS_REPORT_EMPTY = `GET_MIS_REPORT_EMPTY`;

/* ----------------------------------- SUPPLY STORE ----------------------------------- */
// GET REDEMPTION TYPE
export const GET_REDEMPTION_TYPE = `GET_REDEMPTION_TYPE`;
export const GET_REDEMPTION_TYPE_SUCCESS = `GET_REDEMPTION_TYPE_SUCCESS`;
export const GET_REDEMPTION_TYPE_ERROR = `GET_REDEMPTION_TYPE_ERROR`;

// GET PARENT CATEGORY
export const GET_PARENT_CATEGORY = `GET_PARENT_CATEGORY`;
export const GET_PARENT_CATEGORY_SUCCESS = `GET_PARENT_CATEGORY_SUCCESS`;
export const GET_PARENT_CATEGORY_ERROR = `GET_PARENT_CATEGORY_ERROR`;

// GET UPLOADS INFO
export const GET_UPLOADS_INFO = `GET_UPLOADS_INFO`;
export const GET_UPLOADS_INFO_SUCCESS = `GET_UPLOADS_INFO_SUCCESS`;
export const GET_UPLOADS_INFO_ERROR = `GET_UPLOADS_INFO_ERROR`;
export const GET_UPLOADS_INFO_EMPTY = `GET_UPLOADS_INFO_EMPTY`;

/* ----------------- SUPPLY STORE - CATEGORY ----------------- */
// CREATE
export const CREATE_CATEGORY = `CREATE_CATEGORY`;
export const CREATE_CATEGORY_SUCCESS = `CREATE_CATEGORY_SUCCESS`;
export const CREATE_CATEGORY_ERROR = `CREATE_CATEGORY_ERROR`;
export const EMPTY_CREATE_CATEGORY_RESPONSE = `EMPTY_CREATE_CATEGORY_RESPONSE`;

// UPDATE
export const UPDATE_CATEGORY = `UPDATE_CATEGORY`;
export const UPDATE_CATEGORY_SUCCESS = `UPDATE_CATEGORY_SUCCESS`;
export const UPDATE_CATEGORY_ERROR = `UPDATE_CATEGORY_ERROR`;
export const UPDATE_CATEGORY_EMPTY = `UPDATE_CATEGORY_EMPTY`;

// GET LIST
export const GET_CATEGORY = `GET_CATEGORY`;
export const GET_CATEGORY_SUCCESS = `GET_CATEGORY_SUCCESS`;
export const GET_CATEGORY_ERROR = `GET_CATEGORY_ERROR`;
export const GET_CATEGORY_EMPTY = `GET_CATEGORY_EMPTY`;

/* ----------------- SUPPLY STORE - LOCATION ----------------- */
// CREATE
export const CREATE_LOCATION = `CREATE_LOCATION`;
export const CREATE_LOCATION_SUCCESS = `CREATE_LOCATION_SUCCESS`;
export const CREATE_LOCATION_ERROR = `CREATE_LOCATION_ERROR`;
export const CREATE_LOCATION_EMPTY = `CREATE_LOCATION_EMPTY`;

// UPDATE
export const UPDATE_LOCATION = `UPDATE_LOCATION`;
export const UPDATE_LOCATION_SUCCESS = `UPDATE_LOCATION_SUCCESS`;
export const UPDATE_LOCATION_ERROR = `UPDATE_LOCATION_ERROR`;
export const UPDATE_LOCATION_EMPTY = `UPDATE_LOCATION_EMPTY`;

// GET LIST
export const GET_LOCATION = `GET_LOCATION`;
export const GET_LOCATION_SUCCESS = `GET_LOCATION_SUCCESS`;
export const GET_LOCATION_ERROR = `GET_LOCATION_ERROR`;
export const GET_LOCATION_EMPTY = `GET_LOCATION_EMPTY`;

/* ----------------- SUPPLY STORE - PARTNER ----------------- */
// CREATE
export const CREATE_PARTNER = `CREATE_PARTNER`;
export const CREATE_PARTNER_SUCCESS = `CREATE_PARTNER_SUCCESS`;
export const CREATE_PARTNER_ERROR = `CREATE_PARTNER_ERROR`;
export const CREATE_PARTNER_EMPTY = `CREATE_PARTNER_EMPTY`;

// UPDATE
export const UPDATE_PARTNER = `UPDATE_PARTNER`;
export const UPDATE_PARTNER_SUCCESS = `UPDATE_PARTNER_SUCCESS`;
export const UPDATE_PARTNER_ERROR = `UPDATE_PARTNER_ERROR`;
export const UPDATE_PARTNER_EMPTY = `UPDATE_PARTNER_EMPTY`;

// GET LIST
export const GET_PARTNER = `GET_PARTNER`;
export const GET_PARTNER_SUCCESS = `GET_PARTNER_SUCCESS`;
export const GET_PARTNER_ERROR = `GET_PARTNER_ERROR`;
export const GET_PARTNER_EMPTY = `GET_PARTNER_EMPTY`;

/* ----------------- SUPPLY STORE - SERVICE ----------------- */
// CREATE
export const CREATE_SERVICE = `CREATE_SERVICE`;
export const CREATE_SERVICE_SUCCESS = `CREATE_SERVICE_SUCCESS`;
export const CREATE_SERVICE_ERROR = `CREATE_SERVICE_ERROR`;
export const CREATE_SERVICE_EMPTY = `CREATE_SERVICE_EMPTY`;

// UPDATE
export const UPDATE_SERVICE = `UPDATE_SERVICE`;
export const UPDATE_SERVICE_SUCCESS = `UPDATE_SERVICE_SUCCESS`;
export const UPDATE_SERVICE_ERROR = `UPDATE_SERVICE_ERROR`;
export const UPDATE_SERVICE_EMPTY = `UPDATE_SERVICE_EMPTY`;

// GET LIST
export const GET_SERVICE = `GET_SERVICE`;
export const GET_SERVICE_SUCCESS = `GET_SERVICE_SUCCESS`;
export const GET_SERVICE_ERROR = `GET_SERVICE_ERROR`;
export const GET_SERVICE_EMPTY = `GET_SERVICE_EMPTY`;

/* ----------------- SUPPLY STORE - CATEGORY ----------------- */
// GET LIST
export const GET_COUPON_CATEGORY = `GET_COUPON_CATEGORY`;
export const GET_COUPON_CATEGORY_SUCCESS = `GET_COUPON_CATEGORY_SUCCESS`;
export const GET_COUPON_CATEGORY_ERROR = `GET_COUPON_CATEGORY_ERROR`;
export const GET_COUPON_CATEGORY_EMPTY = `GET_COUPON_CATEGORY_EMPTY`;

/* ----------------- SUPPLY STORE - FACILITY ----------------- */
// GET LIST
export const GET_COUPON_FACILITY = `GET_COUPON_FACILITY`;
export const GET_COUPON_FACILITY_SUCCESS = `GET_COUPON_FACILITY_SUCCESS`;
export const GET_COUPON_FACILITY_ERROR = `GET_COUPON_FACILITY_ERROR`;
export const GET_COUPON_FACILITY_EMPTY = `GET_COUPON_FACILITY_EMPTY`;

/* ----------------- SUPPLY STORE - GET PARTNER ----------------- */
// GET LIST
export const GET_COUPON_PARTNER = `GET_COUPON_PARTNER`;
export const GET_COUPON_PARTNER_SUCCESS = `GET_COUPON_PARTNER_SUCCESS`;
export const GET_COUPON_PARTNER_ERROR = `GET_COUPON_PARTNER_ERROR`;
export const GET_COUPON_PARTNER_EMPTY = `GET_COUPON_PARTNER_EMPTY`;

/* ----------------- GET PROJECTS ----------------- */
// GET LIST
export const GET_COUPON_PROJECTS = `GET_COUPON_PROJECTS`;
export const GET_COUPON_PROJECTS_SUCCESS = `GET_COUPON_PROJECTS_SUCCESS`;
export const GET_COUPON_PROJECTS_ERROR = `GET_COUPON_PROJECTS_ERROR`;
export const GET_COUPON_PROJECTS_EMPTY = `GET_COUPON_PROJECTS_EMPTY`;

/* ----------------- GET SERVICE ----------------- */
// GET LIST
export const GET_COUPON_SERVICE = `GET_COUPON_SERVICE`;
export const GET_COUPON_SERVICE_SUCCESS = `GET_COUPON_SERVICE_SUCCESS`;
export const GET_COUPON_SERVICE_ERROR = `GET_COUPON_SERVICE_ERROR`;
export const GET_COUPON_SERVICE_EMPTY = `GET_COUPON_SERVICE_EMPTY`;

/* ----------------------------------- COUPON MANAGEMENT ----------------------------------- */
// GET COUPON LIST
export const GET_COUPON = `GET_COUPON`;
export const GET_COUPON_SUCCESS = `GET_COUPON_SUCCESS`;
export const GET_COUPON_ERROR = `GET_COUPON_ERROR`;
export const GET_COUPON_EMPTY = `GET_COUPON_EMPTY`;

// GET COUPONS LIST
export const GET_COUPONS = `GET_COUPONS`;
export const GET_COUPONS_SUCCESS = `GET_COUPONS_SUCCESS`;
export const GET_COUPONS_ERROR = `GET_COUPONS_ERROR`;

// POST COUPONS
export const POST_COUPONS = `POST_COUPONS`;
export const POST_COUPONS_SUCCESS = `POST_COUPONS_SUCCESS`;
export const POST_COUPONS_ERROR = `POST_COUPONS_ERROR`;
export const GET_EMPTY_POST_COUPON = `GET_EMPTY_POST_COUPON`;

// UPDATE COUPON
export const UPDATE_COUPON = `UPDATE_COUPON`;
export const UPDATE_COUPON_SUCCESS = `UPDATE_COUPON_SUCCESS`;
export const UPDATE_COUPON_ERROR = `UPDATE_COUPON_ERROR`;
export const UPDATE_COUPON_EMPTY = `UPDATE_COUPON_EMPTY`;

// ASSIGN COUPON
export const ASSIGN_COUPON = `ASSIGN_COUPON`;
export const ASSIGN_COUPON_SUCCESS = `ASSIGN_COUPON_SUCCESS`;
export const ASSIGN_COUPON_ERROR = `ASSIGN_COUPON_ERROR`;
export const GET_EMPTY_ASSIGN_COUPON = `GET_EMPTY_ASSIGN_COUPON`;

// ASSIGNED STATUS LIST
export const GET_COUPON_ASSIGNED_STATUS = `GET_COUPON_ASSIGNED_STATUS`;
export const GET_COUPON_ASSIGNED_STATUS_SUCCESS = `GET_COUPON_ASSIGNED_STATUS_SUCCESS`;
export const GET_COUPON_ASSIGNED_STATUS_ERROR = `GET_COUPON_ASSIGNED_STATUS_ERROR`;

// COUPON UPLOADED BY LIST
export const GET_COUPON_UPLOADED_BY = `GET_COUPON_UPLOADED_BY`;
export const GET_COUPON_UPLOADED_BY_SUCCESS = `GET_COUPON_UPLOADED_BY_SUCCESS`;
export const GET_COUPON_UPLOADED_BY_ERROR = `GET_COUPON_UPLOADED_BY_ERROR`;

// UPLOAD STATIC COUPON
export const POST_STATIC_COUPON = `POST_STATIC_COUPON`;
export const POST_STATIC_COUPON_SUCCESS = `POST_STATIC_COUPON_SUCCESS`;
export const POST_STATIC_COUPON_ERROR = `POST_STATIC_COUPON_ERROR`;
export const POST_STATIC_COUPON_EMPTY = `POST_STATIC_COUPON_EMPTY`;

// UPDATE STATIC COUPON
export const UPDATE_STATIC_COUPON = `UPDATE_STATIC_COUPON`;
export const UPDATE_STATIC_COUPON_SUCCESS = `UPDATE_STATIC_COUPON_SUCCESS`;
export const UPDATE_STATIC_COUPON_ERROR = `UPDATE_STATIC_COUPON_ERROR`;
export const UPDATE_STATIC_COUPON_EMPTY = `UPDATE_STATIC_COUPON_EMPTY`;

// GET STATIC COUPON
export const GET_STATIC_COUPON = `GET_STATIC_COUPON`;
export const GET_STATIC_COUPON_SUCCESS = `GET_STATIC_COUPON_SUCCESS`;
export const GET_STATIC_COUPON_ERROR = `GET_STATIC_COUPON_ERROR`;
export const GET_STATIC_COUPON_EMPTY = `GET_STATIC_COUPON_EMPTY`;

/* ----------------------------------- BOOKING MANAGEMENT ----------------------------------- */
// GET BOOKING LIST
export const GET_BOOKING_LIST = `GET_BOOKING_LIST`;
export const GET_BOOKING_LIST_SUCCESS = `GET_BOOKING_LIST_SUCCESS`;
export const GET_BOOKING_LIST_ERROR = `GET_BOOKING_LIST_ERROR`;
export const GET_BOOKING_LIST_EMPTY = `GET_BOOKING_LIST_EMPTY`;

// UPDATE BOOKING
export const UPDATE_BOOKING = `UPDATE_BOOKING`;
export const UPDATE_BOOKING_SUCCESS = `UPDATE_BOOKING_SUCCESS`;
export const UPDATE_BOOKING_ERROR = `UPDATE_BOOKING_ERROR`;
export const EMPTY_UPDATE_BOOKING = `EMPTY_UPDATE_BOOKING`;

// RESEND MAIL
export const RESEND_MAIL = `RESEND_MAIL`;
export const RESEND_MAIL_SUCCESS = `RESEND_MAIL_SUCCESS`;
export const RESEND_MAIL_ERROR = `RESEND_MAIL_ERROR`;
export const RESEND_MAIL_EMPTY = `RESEND_MAIL_EMPTY`;

// GET VENDORS LIST
export const GET_VENDORS_LIST = `GET_VENDORS_LIST`;
export const GET_VENDORS_LIST_SUCCESS = `GET_VENDORS_LIST_SUCCESS`;
export const GET_VENDORS_LIST_ERROR = `GET_VENDORS_LIST_ERROR`;
export const GET_VENDORS_LIST_EMPTY = `GET_VENDORS_LIST_EMPTY`;

// GET VENDOR BY BOOKING
export const GET_VENDOR_BY_BOOKING = `GET_VENDOR_BY_BOOKING`;
export const GET_VENDOR_BY_BOOKING_SUCCESS = `GET_VENDOR_BY_BOOKING_SUCCESS`;
export const GET_VENDOR_BY_BOOKING_ERROR = `GET_VENDOR_BY_BOOKING_ERROR`;
export const GET_VENDOR_BY_BOOKING_EMPTY = `GET_VENDOR_BY_BOOKING_EMPTY`;
// VIEW FILE
export const VIEW_FILE = `VIEW_FILE`;
export const VIEW_FILE_SUCCESS = `VIEW_FILE_SUCCESS`;
export const VIEW_FILE_ERROR = `VIEW_FILE_ERROR`;
export const VIEW_FILE_EMPTY = `VIEW_FILE_EMPTY`;

/* ----------------------------------- USER MANAGEMENT ----------------------------------- */
// GET USER RIGHTS
export const GET_USER_RIGHTS = `GET_USER_RIGHTS`;
export const GET_USER_RIGHTS_SUCCESS = `GET_USER_RIGHTS_SUCCESS`;
export const GET_USER_RIGHTS_ERROR = `GET_USER_RIGHTS_ERROR`;

// CREATE NEW USER
export const CREATE_NEW_USER = `CREATE_NEW_USER`;
export const CREATE_NEW_USER_SUCCESS = `CREATE_NEW_USER_SUCCESS`;
export const CREATE_NEW_USER_ERROR = `CREATE_NEW_USER_ERROR`;

// PROGRAM USERS LIST
export const PROGRAM_USERS_LIST = `PROGRAM_USERS_LIST`;
export const PROGRAM_USERS_LIST_SUCCESS = `PROGRAM_USERS_LIST_SUCCESS`;
export const PROGRAM_USERS_LIST_ERROR = `PROGRAM_USERS_LIST_ERROR`;
export const PROGRAM_USER_EMPTY = `PROGRAM_USER_EMPTY`;

// GET BENEFITPACK DETAILS BASED ON MEMBERSHIP ID
export const GET_MEMBERSHIP_DETAILS = `GET_MEMBERSHIP_DETAILS`;
export const GET_MEMBERSHIP_DETAILS_SUCCESS = `GET_MEMBERSHIP_DETAILS_SUCCESS`;
export const GET_MEMBERSHIP_DETAILS_ERROR = `GET_MEMBERSHIP_DETAILS_ERROR`;
export const GET_MEMBERSHIP_DETAILS_EMPTY = `GET_MEMBERSHIP_DETAILS_EMPTY`;

// GET CARD LIST
export const GET_CARD_LIST = `GET_CARD_LIST`;
export const GET_CARD_LIST_SUCCESS = `GET_CARD_LIST_SUCCESS`;
export const GET_CARD_LIST_ERROR = `GET_CARD_LIST_ERROR`;
export const GET_CARD_LIST_EMPTY = `GET_CARD_LIST_EMPTY`;

// GET BULK USERS
export const GET_BULK_USERS = `GET_BULK_USERS`;
export const GET_BULK_USERS_SUCCESS = `GET_BULK_USERS_SUCCESS`;
export const GET_BULK_USERS_ERROR = `GET_BULK_USERS_ERROR`;
export const GET_BULK_USERS_EMPTY = `GET_BULK_USERS_EMPTY`;

// GET USER CARD
export const GET_USER_CARDS = `GET_USER_CARDS`;
export const GET_USER_CARDS_SUCCESS = `GET_USER_CARDS_SUCCESS`;
export const GET_USER_CARDS_ERROR = `GET_USER_CARDS_ERROR`;
export const GET_USER_CARDS_EMPTY = `GET_USER_CARDS_EMPTY`;

// GET USER DETAILS
export const GET_USER_DETAILS = `GET_USER_DETAILS`;
export const GET_USER_DETAILS_SUCCESS = `GET_USER_DETAILS_SUCCESS`;
export const GET_USER_DETAILS_ERROR = `GET_USER_DETAILS_ERROR`;
export const GET_USER_DETAILS_EMPTY = `GET_USER_DETAILS_EMPTY`;

// VALIDATE USER
export const VALIDATE_USER = `VALIDATE_USER`;
export const VALIDATE_USER_SUCCESS = `VALIDATE_USER_SUCCESS`;
export const VALIDATE_USER_ERROR = `VALIDATE_USER_ERROR`;
export const VALIDATE_USER_EMPTY = `VALIDATE_USER_EMPTY`;

// UPDATE USER CARD
export const UPDATE_USER_CARD = `UPDATE_USER_CARD`;
export const UPDATE_USER_CARD_SUCCESS = `UPDATE_USER_CARD_SUCCESS`;
export const UPDATE_USER_CARD_ERROR = `UPDATE_USER_CARD_ERROR`;
export const UPDATE_USER_CARD_EMPTY = `UPDATE_USER_CARD_EMPTY`;

// GET MEMBERSHIPS
export const GET_MEMBERSHIPS = `GET_MEMBERSHIPS`;
export const GET_MEMBERSHIPS_SUCCESS = `GET_MEMBERSHIPS_SUCCESS`;
export const GET_MEMBERSHIPS_ERROR = `GET_MEMBERSHIPS_ERROR`;
export const GET_MEMBERSHIPS_EMPTY = `GET_MEMBERSHIPS_EMPTY`;

// UPDATE CUSTOMER
export const UPDATE_CUSTOMER_DATA = `UPDATE_CUSTOMER_DATA`;
export const UPDATE_CUSTOMER_DATA_SUCCESS = `UPDATE_CUSTOMER_DATA_SUCCESS`;
export const UPDATE_CUSTOMER_DATA_ERROR = `UPDATE_CUSTOMER_DATA_ERROR`;
export const UPDATE_CUSTOMER_DATA_EMPTY = `UPDATE_CUSTOMER_DATA_EMPTY`;

/* ----------------------------------- DUPLICATE CARD MANAGEMENT ----------------------------------- */
// GET DUPLICATE CARD LIST
export const GET_DUPLICATE_CARD = `GET_DUPLICATE_CARD`;
export const GET_DUPLICATE_CARD_SUCCESS = `GET_DUPLICATE_CARD_SUCCESS`;
export const GET_DUPLICATE_CARD_ERROR = `GET_DUPLICATE_CARD_ERROR`;
export const GET_DUPLICATE_CARD_EMPTY = `GET_DUPLICATE_CARD_EMPTY`;

/* ----------------------------------- BENEFIT MANAGEMENT ----------------------------------- */
// GET BENEFIT PACK LIST
export const BENEFIT_PACK_LIST = `BENEFIT_PACK_LIST`;
export const BENEFIT_PACK_LIST_SUCCESS = `BENEFIT_PACK_LIST_SUCCESS`;
export const BENEFIT_PACK_LIST_ERROR = `BENEFIT_PACK_LIST_ERROR`;
export const BENEFIT_PACK_LIST_EMPTY = `BENEFIT_PACK_LIST_EMPTY`;

// CREATE BENEFIT PACK
export const CREATE_BENEFIT_PACK = `CREATE_BENEFIT_PACK`;
export const CREATE_BENEFIT_PACK_SUCCESS = `CREATE_BENEFIT_PACK_SUCCESS`;
export const CREATE_BENEFIT_PACK_ERROR = `CREATE_BENEFIT_PACK_ERROR`;
export const CREATE_BENEFIT_PACK_EMPTY = `CREATE_BENEFIT_PACK_EMPTY`;

// UPDATE BENEFIT PACK
export const UPDATE_BENEFIT_PACK = `UPDATE_BENEFIT_PACK`;
export const UPDATE_BENEFIT_PACK_SUCCESS = `UPDATE_BENEFIT_PACK_SUCCESS`;
export const UPDATE_BENEFIT_PACK_ERROR = `UPDATE_BENEFIT_PACK_ERROR`;
export const UPDATE_BENEFIT_PACK_EMPTY = `UPDATE_BENEFIT_PACK_EMPTY`;

// GET BENEFIT PACK INFO
export const BENEFIT_PACK_INFO = `BENEFIT_PACK_INFO`;
export const BENEFIT_PACK_INFO_SUCCESS = `BENEFIT_PACK_INFO_SUCCESS`;
export const BENEFIT_PACK_INFO_ERROR = `BENEFIT_PACK_INFO_ERROR`;
export const BENEFIT_PACK_INFO_EMPTY = `BENEFIT_PACK_INFO_EMPTY`;

// CREATE INVENTORY QUOTA
export const CREATE_INVENTORY_QUOTA = `CREATE_INVENTORY_QUOTA`;
export const CREATE_INVENTORY_QUOTA_SUCCESS = `CREATE_INVENTORY_QUOTA_SUCCESS`;
export const CREATE_INVENTORY_QUOTA_ERROR = `CREATE_INVENTORY_QUOTA_ERROR`;
export const CREATE_INVENTORY_QUOTA_EMPTY = `CREATE_INVENTORY_QUOTA_EMPTY`;

// GET INVENTORY QUOTA
export const GET_INVENTORY_QUOTA = `GET_INVENTORY_QUOTA`;
export const GET_INVENTORY_QUOTA_SUCCESS = `GET_INVENTORY_QUOTA_SUCCESS`;
export const GET_INVENTORY_QUOTA_ERROR = `GET_INVENTORY_QUOTA_ERROR`;
export const GET_INVENTORY_QUOTA_EMPTY = `GET_INVENTORY_QUOTA_EMPTY`;

// GET WORKFLOWS LIST
export const GET_WORKFLOWS_LIST = `GET_WORKFLOWS_LIST`;
export const GET_WORKFLOWS_LIST_SUCCESS = `GET_WORKFLOWS_LIST_SUCCESS`;
export const GET_WORKFLOWS_LIST_ERROR = `GET_WORKFLOWS_LIST_ERROR`;
export const GET_WORKFLOWS_LIST_EMPTY = `GET_WORKFLOWS_LIST_EMPTY`;

/* ----------------------------------- DOWNLOADS ----------------------------------- */
// CLICK DOWNLOAD DUMP
export const CLICK_DOWNLOAD_DUMP = `CLICK_DOWNLOAD_DUMP`;
export const CLICK_DOWNLOAD_DUMP_SUCCESS = `CLICK_DOWNLOAD_DUMP_SUCCESS`;
export const CLICK_DOWNLOAD_DUMP_ERROR = `CLICK_DOWNLOAD_DUMP_ERROR`;
export const CLICK_DOWNLOAD_DUMP_EMPTY = `CLICK_DOWNLOAD_DUMP_EMPTY`;

// DOWNLOADS
export const DOWNLOADS_LIST = `DOWNLOADS_LIST`;
export const DOWNLOADS_LIST_SUCCESS = `DOWNLOADS_LIST_SUCCESS`;
export const DOWNLOADS_LIST_ERROR = `DOWNLOADS_LIST_ERROR`;
export const DOWNLOADS_LIST_EMPTY = `DOWNLOADS_LIST_EMPTY`;

/* ----------------------------------- BLOCK DATE MANAGEMENT ----------------------------------- */
// CREATE BLOCK DATE
export const BLOCK_DATE = `BLOCK_DATE`;
export const BLOCK_DATE_SUCCESS = `BLOCK_DATE_SUCCESS`;
export const BLOCK_DATE_ERROR = `BLOCK_DATE_ERROR`;
export const BLOCK_DATE_EMPTY = `BLOCK_DATE_EMPTY`;

// BLOCK DATE LIST
export const BLOCK_DATE_LIST = `BLOCK_DATE_LIST`;
export const BLOCK_DATE_LIST_SUCCESS = `BLOCK_DATE_LIST_SUCCESS`;
export const BLOCK_DATE_LIST_ERROR = `BLOCK_DATE_LIST_ERROR`;
export const BLOCK_DATE_LIST_EMPTY = `BLOCK_DATE_LIST_EMPTY`;

// SERVICES BASED ON FACILITY
export const SERVICES_ON_FACILITY = `SERVICES_ON_FACILITY`;
export const SERVICES_ON_FACILITY_SUCCESS = `SERVICES_ON_FACILITY_SUCCESS`;
export const SERVICES_ON_FACILITY_ERROR = `SERVICES_ON_FACILITY_ERROR`;
export const SERVICES_ON_FACILITY_EMPTY = `SERVICES_ON_FACILITY_EMPTY`;

// BLOCK DATE DEACTIVATE
export const BLOCK_DATE_DEACTIVATE = `BLOCK_DATE_DEACTIVATE`;
export const BLOCK_DATE_DEACTIVATE_SUCCESS = `BLOCK_DATE_DEACTIVATE_SUCCESS`;
export const BLOCK_DATE_DEACTIVATE_ERROR = `BLOCK_DATE_DEACTIVATE_ERROR`;
export const BLOCK_DATE_DEACTIVATE_EMPTY = `BLOCK_DATE_DEACTIVATE_EMPTY`;

/* ----------------------------------- RULES MANAGEMENT ----------------------------------- */
/* ----------------- RULES MANAGEMENT - EXPENDITURE ----------------- */
// CREATE EXPENDITURE
export const CREATE_EXPENDITURE_RULE = `CREATE_EXPENDITURE_RULE`;
export const CREATE_EXPENDITURE_RULE_SUCCESS = `CREATE_EXPENDITURE_RULE_SUCCESS`;
export const CREATE_EXPENDITURE_RULE_ERROR = `CREATE_EXPENDITURE_RULE_ERROR`;
export const CREATE_EXPENDITURE_RULE_EMPTY = `CREATE_EXPENDITURE_RULE_EMPTY`;

// LIST EXPENDITURE
export const LIST_EXPENDITURE_RULE = `LIST_EXPENDITURE_RULE`;
export const LIST_EXPENDITURE_RULE_SUCCESS = `LIST_EXPENDITURE_RULE_SUCCESS`;
export const LIST_EXPENDITURE_RULE_ERROR = `LIST_EXPENDITURE_RULE_ERROR`;
export const LIST_EXPENDITURE_RULE_EMPTY = `LIST_EXPENDITURE_RULE_EMPTY`;

// UPDATE EXPENDITURE
export const UPDATE_EXPENDITURE_RULE = `UPDATE_EXPENDITURE_RULE`;
export const UPDATE_EXPENDITURE_RULE_SUCCESS = `UPDATE_EXPENDITURE_RULE_SUCCESS`;
export const UPDATE_EXPENDITURE_RULE_ERROR = `UPDATE_EXPENDITURE_RULE_ERROR`;
export const UPDATE_EXPENDITURE_RULE_EMPTY = `UPDATE_EXPENDITURE_RULE_EMPTY`;

// DELETE EXPENDITURE
export const DELETE_EXPENDITURE_RULE = `DELETE_EXPENDITURE_RULE`;
export const DELETE_EXPENDITURE_RULE_SUCCESS = `DELETE_EXPENDITURE_RULE_SUCCESS`;
export const DELETE_EXPENDITURE_RULE_ERROR = `DELETE_EXPENDITURE_RULE_ERROR`;
export const DELETE_EXPENDITURE_RULE_EMPTY = `DELETE_EXPENDITURE_RULE_EMPTY`;

/* ----------------- RULES MANAGEMENT - EVENT ----------------- */
// CREATE EVENT
export const CREATE_EVENT_RULE = `CREATE_EVENT_RULE`;
export const CREATE_EVENT_RULE_SUCCESS = `CREATE_EVENT_RULE_SUCCESS`;
export const CREATE_EVENT_RULE_ERROR = `CREATE_EVENT_RULE_ERROR`;
export const CREATE_EVENT_RULE_EMPTY = `CREATE_EVENT_RULE_EMPTY`;

// CREATE EVENT WITH USER IDS
export const CREATE_EVENT_RULE_WITH_USER_IDS = `CREATE_EVENT_RULE_WITH_USER_IDS`;
export const CREATE_EVENT_RULE_WITH_USER_IDS_SUCCESS = `CREATE_EVENT_RULE_WITH_USER_IDS_SUCCESS`;
export const CREATE_EVENT_RULE_WITH_USER_IDS_ERROR = `CREATE_EVENT_RULE_WITH_USER_IDS_ERROR`;
export const CREATE_EVENT_RULE_WITH_USER_IDS_EMPTY = `CREATE_EVENT_RULE_WITH_USER_IDS_EMPTY`;

// CREATE EVENT WITH BENEFIT GROUP ID
export const CREATE_EVENT_RULE_WITH_BENEFIT_GROUP = `CREATE_EVENT_RULE_WITH_BENEFIT_GROUP`;
export const CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_SUCCESS = `CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_SUCCESS`;
export const CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_ERROR = `CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_ERROR`;
export const CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_EMPTY = `CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_EMPTY`;

// LIST EVENT
export const LIST_EVENT_RULE = `LIST_EVENT_RULE`;
export const LIST_EVENT_RULE_SUCCESS = `LIST_EVENT_RULE_SUCCESS`;
export const LIST_EVENT_RULE_ERROR = `LIST_EVENT_RULE_ERROR`;
export const LIST_EVENT_RULE_EMPTY = `LIST_EVENT_RULE_EMPTY`;

// UPDATE EVENT
export const UPDATE_EVENT_RULE = `UPDATE_EVENT_RULE`;
export const UPDATE_EVENT_RULE_SUCCESS = `UPDATE_EVENT_RULE_SUCCESS`;
export const UPDATE_EVENT_RULE_ERROR = `UPDATE_EVENT_RULE_ERROR`;
export const UPDATE_EVENT_RULE_EMPTY = `UPDATE_EVENT_RULE_EMPTY`;

// DELETE EVENT
export const DELETE_EVENT_RULE = `DELETE_EVENT_RULE`;
export const DELETE_EVENT_RULE_SUCCESS = `DELETE_EVENT_RULE_SUCCESS`;
export const DELETE_EVENT_RULE_ERROR = `DELETE_EVENT_RULE_ERROR`;
export const DELETE_EVENT_RULE_EMPTY = `DELETE_EVENT_RULE_EMPTY`;

/* ----------------- RULES MANAGEMENT - ASSIGN COINS ----------------- */
// CREATE WORTH
export const CREATE_SERVICE_WORTH = `CREATE_SERVICE_WORTH`;
export const CREATE_SERVICE_WORTH_SUCCESS = `CREATE_SERVICE_WORTH_SUCCESS`;
export const CREATE_SERVICE_WORTH_ERROR = `CREATE_SERVICE_WORTH_ERROR`;
export const CREATE_SERVICE_WORTH_EMPTY = `CREATE_SERVICE_WORTH_EMPTY`;

// LIST WORTH
export const LIST_SERVICE_WORTH = `LIST_SERVICE_WORTH`;
export const LIST_SERVICE_WORTH_SUCCESS = `LIST_SERVICE_WORTH_SUCCESS`;
export const LIST_SERVICE_WORTH_ERROR = `LIST_SERVICE_WORTH_ERROR`;
export const LIST_SERVICE_WORTH_EMPTY = `LIST_SERVICE_WORTH_EMPTY`;

// UPDATE WORTH
export const UPDATE_SERVICE_WORTH = `UPDATE_SERVICE_WORTH`;
export const UPDATE_SERVICE_WORTH_SUCCESS = `UPDATE_SERVICE_WORTH_SUCCESS`;
export const UPDATE_SERVICE_WORTH_ERROR = `UPDATE_SERVICE_WORTH_ERROR`;
export const UPDATE_SERVICE_WORTH_EMPTY = `UPDATE_SERVICE_WORTH_EMPTY`;

// DELETE WORTH
export const DELETE_SERVICE_WORTH = `DELETE_SERVICE_WORTH`;
export const DELETE_SERVICE_WORTH_SUCCESS = `DELETE_SERVICE_WORTH_SUCCESS`;
export const DELETE_SERVICE_WORTH_ERROR = `DELETE_SERVICE_WORTH_ERROR`;
export const DELETE_SERVICE_WORTH_EMPTY = `DELETE_SERVICE_WORTH_EMPTY`;

/* ----------------------------------- GOLF ADMIN ----------------------------------- */
// GET GOLF COURSES
export const GET_GOLF_COURSE = `GET_GOLF_COURSE`;
export const GET_GOLF_COURSE_SUCCESS = `GET_GOLF_COURSE_SUCCESS`;
export const GET_GOLF_COURSE_ERROR = `GET_GOLF_COURSE_ERROR`;
export const GET_GOLF_COURSE_EMPTY = `GET_GOLF_COURSE_EMPTY`;

// GET GOLF COURSE SLOTS
export const GET_GOLF_COURSE_SLOTS = `GET_GOLF_COURSE_SLOTS`;
export const GET_GOLF_COURSE_SLOTS_SUCCESS = `GET_GOLF_COURSE_SLOTS_SUCCESS`;
export const GET_GOLF_COURSE_SLOTS_ERROR = `GET_GOLF_COURSE_SLOTS_ERROR`;
export const GET_GOLF_COURSE_SLOTS_EMPTY = `GET_GOLF_COURSE_SLOTS_EMPTY`;

// UPDATE GOLF COURSE SLOTS
export const UPDATE_GOLF_COURSE_SLOTS = `UPDATE_GOLF_COURSE_SLOTS`;
export const UPDATE_GOLF_COURSE_SLOTS_SUCCESS = `UPDATE_GOLF_COURSE_SLOTS_SUCCESS`;
export const UPDATE_GOLF_COURSE_SLOTS_ERROR = `UPDATE_GOLF_COURSE_SLOTS_ERROR`;
export const UPDATE_GOLF_COURSE_SLOTS_EMPTY = `UPDATE_GOLF_COURSE_SLOTS_EMPTY`;

// DOWNLOAD SLOTS EXCEL REPORT
export const DOWNLOAD_SLOTS_EXCEL = `DOWNLOAD_SLOTS_EXCEL`;
export const DOWNLOAD_SLOTS_EXCEL_SUCCESS = `DOWNLOAD_SLOTS_EXCEL_SUCCESS`;
export const DOWNLOAD_SLOTS_EXCEL_ERROR = `DOWNLOAD_SLOTS_EXCEL_ERROR`;
export const DOWNLOAD_SLOTS_EXCEL_EMPTY = `DOWNLOAD_SLOTS_EXCEL_EMPTY`;

// GET GOLF COURSE FORMAT
export const GET_GOLF_COURSE_FORMATS = `GET_GOLF_COURSE_FORMATS`;
export const GET_GOLF_COURSE_FORMATS_SUCCESS = `GET_GOLF_COURSE_FORMATS_SUCCESS`;
export const GET_GOLF_COURSE_FORMATS_ERROR = `GET_GOLF_COURSE_FORMATS_ERROR`;
export const GET_GOLF_COURSE_FORMATS_EMPTY = `GET_GOLF_COURSE_FORMATS_EMPTY`;

// GET GOLF COURSE BY ID
export const GET_GOLF_COURSE_FORMATS_BY_ID = `GET_GOLF_COURSE_FORMATS_BY_ID`;
export const GET_GOLF_COURSE_FORMATS_BY_ID_SUCCESS = `GET_GOLF_COURSE_FORMATS_BY_ID_SUCCESS`;
export const GET_GOLF_COURSE_FORMATS_BY_ID_ERROR = `GET_GOLF_COURSE_FORMATS_BY_ID_ERROR`;
export const GET_GOLF_COURSE_FORMATS_BY_ID_EMPTY = `GET_GOLF_COURSE_FORMATS_BY_ID_EMPTY`;

// DELETE GOLF COURSE BY ID
export const DELETE_GOLF_COURSE_FORMATS_BY_ID = `DELETE_GOLF_COURSE_FORMATS_BY_ID`;
export const DELETE_GOLF_COURSE_FORMATS_BY_ID_SUCCESS = `DELETE_GOLF_COURSE_FORMATS_BY_ID_SUCCESS`;
export const DELETE_GOLF_COURSE_FORMATS_BY_ID_ERROR = `DELETE_GOLF_COURSE_FORMATS_BY_ID_ERROR`;
export const DELETE_GOLF_COURSE_FORMATS_BY_ID_EMPTY = `DELETE_GOLF_COURSE_FORMATS_BY_ID_EMPTY`;

// CREATE GOLF COURSE UNAVAILABILITY
export const CREATE_GOLF_COURSE_UNAVAILABILITY = `CREATE_GOLF_COURSE_UNAVAILABILITY`;
export const CREATE_GOLF_COURSE_UNAVAILABILITY_SUCCESS = `CREATE_GOLF_COURSE_UNAVAILABILITY_SUCCESS`;
export const CREATE_GOLF_COURSE_UNAVAILABILITY_ERROR = `CREATE_GOLF_COURSE_UNAVAILABILITY_ERROR`;
export const CREATE_GOLF_COURSE_UNAVAILABILITY_EMPTY = `CREATE_GOLF_COURSE_UNAVAILABILITY_EMPTY`;

// GET GOLF COURSE UNAVAILABLE
export const GET_GOLF_COURSE_UNAVAILABLE = `GET_GOLF_COURSE_UNAVAILABLE`;
export const GET_GOLF_COURSE_UNAVAILABLE_SUCCESS = `GET_GOLF_COURSE_UNAVAILABLE_SUCCESS`;
export const GET_GOLF_COURSE_UNAVAILABLE_ERROR = `GET_GOLF_COURSE_UNAVAILABLE_ERROR`;
export const GET_GOLF_COURSE_UNAVAILABLE_EMPTY = `GET_GOLF_COURSE_UNAVAILABLE_EMPTY`;

// DISABLE GOLF COURSE UNAVAILABLE
export const DISABLE_GOLF_COURSE_UNAVAILABILITY = `DISABLE_GOLF_COURSE_UNAVAILABILITY`;
export const DISABLE_GOLF_COURSE_UNAVAILABILITY_SUCCESS = `DISABLE_GOLF_COURSE_UNAVAILABILITY_SUCCESS`;
export const DISABLE_GOLF_COURSE_UNAVAILABILITY_ERROR = `DISABLE_GOLF_COURSE_UNAVAILABILITY_ERROR`;
export const DISABLE_GOLF_COURSE_UNAVAILABILITY_EMPTY = `DISABLE_GOLF_COURSE_UNAVAILABILITY_EMPTY`;

// CREATE GOLF COURSE CREATE
export const CREATE_GOLF_COURSE_SLOT = `CREATE_GOLF_COURSE_SLOT`;
export const CREATE_GOLF_COURSE_SLOT_SUCCESS = `CREATE_GOLF_COURSE_SLOT_SUCCESS`;
export const CREATE_GOLF_COURSE_SLOT_ERROR = `CREATE_GOLF_COURSE_SLOT_ERROR`;
export const CREATE_GOLF_COURSE_SLOT_EMPTY = `CREATE_GOLF_COURSE_SLOT_EMPTY`;

/* ----------------------------------- USERS MANAGEMENT ----------------------------------- */
// GET USERS
export const GET_USERS = `GET_USERS`;
export const GET_USERS_SUCCESS = `GET_USERS_SUCCESS`;
export const GET_USERS_ERROR = `GET_USERS_ERROR`;
export const GET_USERS_EMPTY = `GET_USERS_EMPTY`;

// GET PERMISSIONS
export const GET_PERMISSIONS = `GET_PERMISSIONS`;
export const GET_PERMISSIONS_SUCCESS = `GET_PERMISSIONS_SUCCESS`;
export const GET_PERMISSIONS_ERROR = `GET_PERMISSIONS_ERROR`;
export const GET_PERMISSIONS_EMPTY = `GET_PERMISSIONS_EMPTY`;

// UPDATE PERMISSIONS
export const UPDATE_PERMISSIONS = `UPDATE_PERMISSIONS`;
export const UPDATE_PERMISSIONS_SUCCESS = `UPDATE_PERMISSIONS_SUCCESS`;
export const UPDATE_PERMISSIONS_ERROR = `UPDATE_PERMISSIONS_ERROR`;
export const UPDATE_PERMISSIONS_EMPTY = `UPDATE_PERMISSIONS_EMPTY`;

/* ----------------------------------- PROJECT MANAGEMENT ----------------------------------- */
// GET PROJECTS LIST
export const GET_PROJECTS_LIST = `GET_PROJECTS_LIST`;
export const GET_PROJECTS_LIST_SUCCESS = `GET_PROJECTS_LIST_SUCCESS`;
export const GET_PROJECTS_LIST_ERROR = `GET_PROJECTS_LIST_ERROR`;
export const GET_PROJECTS_LIST_EMPTY = `GET_PROJECTS_LIST_EMPTY`;

// CREATE PROJECTS
export const CREATE_PROJECT = `CREATE_PROJECT`;
export const CREATE_PROJECT_SUCCESS = `CREATE_PROJECT_SUCCESS`;
export const CREATE_PROJECT_ERROR = `CREATE_PROJECT_ERROR`;
export const CREATE_PROJECT_EMPTY = `CREATE_PROJECT_EMPTY`;

// UPDATE PROJECTS
export const UPDATE_PROJECT = `UPDATE_PROJECT`;
export const UPDATE_PROJECT_SUCCESS = `UPDATE_PROJECT_SUCCESS`;
export const UPDATE_PROJECT_ERROR = `UPDATE_PROJECT_ERROR`;
export const UPDATE_PROJECT_EMPTY = `UPDATE_PROJECT_EMPTY`;

// CREATE CLIENTS
export const CREATE_CLIENTS = `CREATE_CLIENTS`;
export const CREATE_CLIENTS_SUCCESS = `CREATE_CLIENTS_SUCCESS`;
export const CREATE_CLIENTS_ERROR = `CREATE_CLIENTS_ERROR`;
export const CREATE_CLIENTS_EMPTY = `CREATE_CLIENTS_EMPTY`;

// UPDATE CLIENTS
export const UPDATE_CLIENTS = `UPDATE_CLIENTS`;
export const UPDATE_CLIENTS_SUCCESS = `UPDATE_CLIENTS_SUCCESS`;
export const UPDATE_CLIENTS_ERROR = `UPDATE_CLIENTS_ERROR`;
export const UPDATE_CLIENTS_EMPTY = `UPDATE_CLIENTS_EMPTY`;

// GET PAYMENTS LIST
export const GET_PAYMENTS_LIST = `GET_PAYMENTS_LIST`;
export const GET_PAYMENTS_LIST_SUCCESS = `GET_PAYMENTS_LIST_SUCCESS`;
export const GET_PAYMENTS_LIST_ERROR = `GET_PAYMENTS_LIST_ERROR`;
export const GET_PAYMENTS_LIST_EMPTY = `GET_PAYMENTS_LIST_EMPTY`;

// UPDATE PAYMENTS SETTING
export const UPDATE_PAYMENT_SETTING = `UPDATE_PAYMENT_SETTING`;
export const UPDATE_PAYMENT_SETTING_SUCCESS = `UPDATE_PAYMENT_SETTING_SUCCESS`;
export const UPDATE_PAYMENT_SETTING_ERROR = `UPDATE_PAYMENT_SETTING_ERROR`;
export const UPDATE_PAYMENT_SETTING_EMPTY = `UPDATE_PAYMENT_SETTING_EMPTY`;

// CREATE PAYMENTS
export const CREATE_PAYMENT = `CREATE_PAYMENT`;
export const CREATE_PAYMENT_SUCCESS = `CREATE_PAYMENT_SUCCESS`;
export const CREATE_PAYMENT_ERROR = `CREATE_PAYMENT_ERROR`;
export const CREATE_PAYMENT_EMPTY = `CREATE_PAYMENT_EMPTY`;

// GET PAYMENT GATEWAY
export const GET_PAYMENT_GATEWAYS = `GET_PAYMENT_GATEWAYS`;
export const GET_PAYMENT_GATEWAYS_SUCCESS = `GET_PAYMENT_GATEWAYS_SUCCESS`;
export const GET_PAYMENT_GATEWAYS_ERROR = `GET_PAYMENT_GATEWAYS_ERROR`;
export const GET_PAYMENT_GATEWAYS_EMPTY = `GET_PAYMENT_GATEWAYS_EMPTY`;

// CREATE BINS
export const CREATE_BINS = `CREATE_BINS`;
export const CREATE_BINS_SUCCESS = `CREATE_BINS_SUCCESS`;
export const CREATE_BINS_ERROR = `CREATE_BINS_ERROR`;
export const CREATE_BINS_EMPTY = `CREATE_BINS_EMPTY`;

// UPDATE BINS
export const UPDATE_BINS = `UPDATE_BINS`;
export const UPDATE_BINS_SUCCESS = `UPDATE_BINS_SUCCESS`;
export const UPDATE_BINS_ERROR = `UPDATE_BINS_ERROR`;
export const UPDATE_BINS_EMPTY = `UPDATE_BINS_EMPTY`;

// BINS LIST
export const LIST_BINS = `LIST_BINS`;
export const LIST_BINS_SUCCESS = `LIST_BINS_SUCCESS`;
export const LIST_BINS_ERROR = `LIST_BINS_ERROR`;
export const LIST_BINS_EMPTY = `LIST_BINS_EMPTY`;

// PAYMENT LOGS
export const PAYMENT_LOGS = `PAYMENT_LOGS`;
export const PAYMENT_LOGS_SUCCESS = `PAYMENT_LOGS_SUCCESS`;
export const PAYMENT_LOGS_ERROR = `PAYMENT_LOGS_ERROR`;
export const PAYMENT_LOGS_EMPTY = `PAYMENT_LOGS_EMPTY`;

// CREATE PARENT PROJECT
export const CREATE_PARENT_PROJECT = `CREATE_PARENT_PROJECT`;
export const CREATE_PARENT_PROJECT_SUCCESS = `CREATE_PARENT_PROJECT_SUCCESS`;
export const CREATE_PARENT_PROJECT_ERROR = `CREATE_PARENT_PROJECT_ERROR`;
export const CREATE_PARENT_PROJECT_EMPTY = `CREATE_PARENT_PROJECT_EMPTY`;

// GET PARENT PROJECT
export const GET_PARENT_PROJECTS = `GET_PARENT_PROJECTS`;
export const GET_PARENT_PROJECTS_SUCCESS = `GET_PARENT_PROJECTS_SUCCESS`;
export const GET_PARENT_PROJECTS_ERROR = `GET_PARENT_PROJECTS_ERROR`;
export const GET_PARENT_PROJECTS_EMPTY = `GET_PARENT_PROJECTS_EMPTY`;

// UPDATE PARENT PROJECT
export const UPDATE_PARENT_PROJECT = `UPDATE_PARENT_PROJECT`;
export const UPDATE_PARENT_PROJECT_SUCCESS = `UPDATE_PARENT_PROJECT_SUCCESS`;
export const UPDATE_PARENT_PROJECT_ERROR = `UPDATE_PARENT_PROJECT_ERROR`;
export const UPDATE_PARENT_PROJECT_EMPTY = `UPDATE_PARENT_PROJECT_EMPTY`;

/* ----------------------------------- CONFIG STORE MANAGEMENT ----------------------------------- */
// CREATE BENEFIT GROUP
export const CREATE_BENEFIT_GROUP = `CREATE_BENEFIT_GROUP`;
export const CREATE_BENEFIT_GROUP_SUCCESS = `CREATE_BENEFIT_GROUP_SUCCESS`;
export const CREATE_BENEFIT_GROUP_ERROR = `CREATE_BENEFIT_GROUP_ERROR`;
export const CREATE_BENEFIT_GROUP_EMPTY = `CREATE_BENEFIT_GROUP_EMPTY`;

// UPDATE BENEFIT GROUP
export const UPDATE_BENEFIT_GROUP = `UPDATE_BENEFIT_GROUP`;
export const UPDATE_BENEFIT_GROUP_SUCCESS = `UPDATE_BENEFIT_GROUP_SUCCESS`;
export const UPDATE_BENEFIT_GROUP_ERROR = `UPDATE_BENEFIT_GROUP_ERROR`;
export const UPDATE_BENEFIT_GROUP_EMPTY = `UPDATE_BENEFIT_GROUP_EMPTY`;

// LIST BENEFIT GROUPS LIST
export const GET_BENEFIT_GROUPS_LIST = `GET_BENEFIT_GROUPS_LIST`;
export const GET_BENEFIT_GROUPS_LIST_SUCCESS = `GET_BENEFIT_GROUPS_LIST_SUCCESS`;
export const GET_BENEFIT_GROUPS_LIST_ERROR = `GET_BENEFIT_GROUPS_LIST_ERROR`;
export const GET_BENEFIT_GROUPS_LIST_EMPTY = `GET_BENEFIT_GROUPS_LIST_EMPTY`;

// CREATE BENEFIT GROUP ITEM
export const CREATE_BENEFIT_GROUP_ITEM = `CREATE_BENEFIT_GROUP_ITEM`;
export const CREATE_BENEFIT_GROUP_ITEM_SUCCESS = `CREATE_BENEFIT_GROUP_ITEM_SUCCESS`;
export const CREATE_BENEFIT_GROUP_ITEM_ERROR = `CREATE_BENEFIT_GROUP_ITEM_ERROR`;
export const CREATE_BENEFIT_GROUP_ITEM_EMPTY = `CREATE_BENEFIT_GROUP_ITEM_EMPTY`;

// LIST BENEFIT GROUP ITEMs
export const GET_BENEFIT_GROUP_ITEMS = `GET_BENEFIT_GROUP_ITEMS`;
export const GET_BENEFIT_GROUP_ITEMS_SUCCESS = `GET_BENEFIT_GROUP_ITEMS_SUCCESS`;
export const GET_BENEFIT_GROUP_ITEMS_ERROR = `GET_BENEFIT_GROUP_ITEMS_ERROR`;
export const GET_BENEFIT_GROUP_ITEMS_EMPTY = `GET_BENEFIT_GROUP_ITEMS_EMPTY`;

// UPDATE BENEFIT GROUP ITEMS
export const UPDATE_BENEFIT_GROUP_ITEM = `UPDATE_BENEFIT_GROUP_ITEM`;
export const UPDATE_BENEFIT_GROUP_ITEM_SUCCESS = `UPDATE_BENEFIT_GROUP_ITEM_SUCCESS`;
export const UPDATE_BENEFIT_GROUP_ITEM_ERROR = `UPDATE_BENEFIT_GROUP_ITEM_ERROR`;
export const UPDATE_BENEFIT_GROUP_ITEM_EMPTY = `UPDATE_BENEFIT_GROUP_ITEM_EMPTY`;

// UPDATE BENEFIT GROUP ITM
export const UPDATE_BENEFIT_GROUP_ITM = `UPDATE_BENEFIT_GROUP_ITM`;
export const UPDATE_BENEFIT_GROUP_ITM_SUCCESS = `UPDATE_BENEFIT_GROUP_ITM_SUCCESS`;
export const UPDATE_BENEFIT_GROUP_ITM_ERROR = `UPDATE_BENEFIT_GROUP_ITM_ERROR`;
export const UPDATE_BENEFIT_GROUP_ITM_EMPTY = `UPDATE_BENEFIT_GROUP_ITM_EMPTY`;

// CREATE BENEFIT GROUP CATEGORY
export const CREATE_BENEFIT_GROUP_CATEGORY = `CREATE_BENEFIT_GROUP_CATEGORY`;
export const CREATE_BENEFIT_GROUP_CATEGORY_SUCCESS = `CREATE_BENEFIT_GROUP_CATEGORY_SUCCESS`;
export const CREATE_BENEFIT_GROUP_CATEGORY_ERROR = `CREATE_BENEFIT_GROUP_CATEGORY_ERROR`;
export const CREATE_BENEFIT_GROUP_CATEGORY_EMPTY = `CREATE_BENEFIT_GROUP_CATEGORY_EMPTY`;

// GET BENEFIT GROUP CATEGORIES
export const GET_BENEFIT_GROUP_CATEORIES = `GET_BENEFIT_GROUP_CATEORIES`;
export const GET_BENEFIT_GROUP_CATEORIES_SUCCESS = `GET_BENEFIT_GROUP_CATEORIES_SUCCESS`;
export const GET_BENEFIT_GROUP_CATEORIES_ERROR = `GET_BENEFIT_GROUP_CATEORIES_ERROR`;
export const GET_BENEFIT_GROUP_CATEORIES_EMPTY = `GET_BENEFIT_GROUP_CATEORIES_EMPTY`;

// GET SERVICE IN BENEFIT ITEM
export const GET_SERVICES_IN_BENEFIT_ITEM = `GET_SERVICES_IN_BENEFIT_ITEM`;
export const GET_SERVICES_IN_BENEFIT_ITEM_SUCCESS = `GET_SERVICES_IN_BENEFIT_ITEM_SUCCESS`;
export const GET_SERVICES_IN_BENEFIT_ITEM_ERROR = `GET_SERVICES_IN_BENEFIT_ITEM_ERROR`;
export const GET_SERVICES_IN_BENEFIT_ITEM_EMPTY = `GET_SERVICES_IN_BENEFIT_ITEM_EMPTY`;

// GET BENEFIT GROUP ITEM
export const GET_BENEFIT_ITEMS_LIST = `GET_BENEFIT_ITEMS_LIST`;
export const GET_BENEFIT_ITEMS_LIST_SUCCESS = `GET_BENEFIT_ITEMS_LIST_SUCCESS`;
export const GET_BENEFIT_ITEMS_LIST_ERROR = `GET_BENEFIT_ITEMS_LIST_ERROR`;
export const GET_BENEFIT_ITEMS_LIST_EMPTY = `GET_BENEFIT_ITEMS_LIST_EMPTY`;

// UPDATE BENEFIT ITEM
export const UPDATE_BENEFIT_ITEMS = `UPDATE_BENEFIT_ITEMS`;
export const UPDATE_BENEFIT_ITEMS_SUCCESS = `UPDATE_BENEFIT_ITEMS_SUCCESS`;
export const UPDATE_BENEFIT_ITEMS_ERROR = `UPDATE_BENEFIT_ITEMS_ERROR`;
export const UPDATE_BENEFIT_ITEMS_EMPTY = `UPDATE_BENEFIT_ITEMS_EMPTY`;

/* ----------------------------------- CUSTOMER DATA MANAGEMENT ----------------------------------- */
// CUSTOMER UPLOADS INFO
export const CUSTOMER_UPLOADS_INFO = `CUSTOMER_UPLOADS_INFO`;
export const CUSTOMER_UPLOADS_INFO_SUCCESS = `CUSTOMER_UPLOADS_INFO_SUCCESS`;
export const CUSTOMER_UPLOADS_INFO_ERROR = `CUSTOMER_UPLOADS_INFO_ERROR`;
export const CUSTOMER_UPLOADS_INFO_EMPTY = `CUSTOMER_UPLOADS_INFO_EMPTY`;

// CLIENTS CUSTOMER
export const CLIENTS_CUSTOMER = `CLIENTS_CUSTOMER`;
export const CLIENTS_CUSTOMER_SUCCESS = `CLIENTS_CUSTOMER_SUCCESS`;
export const CLIENTS_CUSTOMER_ERROR = `CLIENTS_CUSTOMER_ERROR`;
export const CLIENTS_CUSTOMER_EMPTY = `CLIENTS_CUSTOMER_EMPTY`;

// UPDATE CLIENTS CUSTOMER
export const UPDATE_CLIENTS_CUSTOMER = `UPDATE_CLIENTS_CUSTOMER`;
export const UPDATE_CLIENTS_CUSTOMER_SUCCESS = `UPDATE_CLIENTS_CUSTOMER_SUCCESS`;
export const UPDATE_CLIENTS_CUSTOMER_ERROR = `UPDATE_CLIENTS_CUSTOMER_ERROR`;
export const UPDATE_CLIENTS_CUSTOMER_EMPTY = `UPDATE_CLIENTS_CUSTOMER_EMPTY`;

/* ----------------------------------- SURGE SPHERE ----------------------------------- */
/* ----------------- SURGE SPHERE - CAMPAIGN ----------------- */
// CREATE
export const CREATE_CAMPAIGN = `CREATE_CAMPAIGN`;
export const CREATE_CAMPAIGN_SUCCESS = `CREATE_CAMPAIGN_SUCCESS`;
export const CREATE_CAMPAIGN_ERROR = `CREATE_CAMPAIGN_ERROR`;
export const CREATE_CAMPAIGN_EMPTY = `CREATE_CAMPAIGN_EMPTY`;

// LIST
export const GET_CAMPAIGNS = `GET_CAMPAIGNS`;
export const GET_CAMPAIGNS_SUCCESS = `GET_CAMPAIGNS_SUCCESS`;
export const GET_CAMPAIGNS_ERROR = `GET_CAMPAIGNS_ERROR`;
export const GET_CAMPAIGNS_EMPTY = `GET_CAMPAIGNS_EMPTY`;

// UPDATE
export const UPDATE_CAMPAIGN = `UPDATE_CAMPAIGN`;
export const UPDATE_CAMPAIGN_SUCCESS = `UPDATE_CAMPAIGN_SUCCESS`;
export const UPDATE_CAMPAIGN_ERROR = `UPDATE_CAMPAIGN_ERROR`;
export const UPDATE_CAMPAIGN_EMPTY = `UPDATE_CAMPAIGN_EMPTY`;

/* ----------------- SURGE SPHERE - RULES ----------------- */
// CREATE
export const CREATE_RULE = `CREATE_RULE`;
export const CREATE_RULE_SUCCESS = `CREATE_RULE_SUCCESS`;
export const CREATE_RULE_ERROR = `CREATE_RULE_ERROR`;
export const CREATE_RULE_EMPTY = `CREATE_RULE_EMPTY`;

// LIST
export const GET_RULES = `GET_RULES`;
export const GET_RULES_SUCCESS = `GET_RULES_SUCCESS`;
export const GET_RULES_ERROR = `GET_RULES_ERROR`;
export const GET_RULES_EMPTY = `GET_RULES_EMPTY`;

// UPDATE
export const UPDATE_RULE = `UPDATE_RULE`;
export const UPDATE_RULE_SUCCESS = `UPDATE_RULE_SUCCESS`;
export const UPDATE_RULE_ERROR = `UPDATE_RULE_ERROR`;
export const UPDATE_RULE_EMPTY = `UPDATE_RULE_EMPTY`;

// DELETE
export const DELETE_RULE = `DELETE_RULE`;
export const DELETE_RULE_SUCCESS = `DELETE_RULE_SUCCESS`;
export const DELETE_RULE_ERROR = `DELETE_RULE_ERROR`;
export const DELETE_RULE_EMPTY = `DELETE_RULE_EMPTY`;

/* ----------------- SURGE SPHERE - EVENT ----------------- */
// CREATE
export const CREATE_SURGE_EVENT = `CREATE_SURGE_EVENT`;
export const CREATE_SURGE_EVENT_SUCCESS = `CREATE_SURGE_EVENT_SUCCESS`;
export const CREATE_SURGE_EVENT_ERROR = `CREATE_SURGE_EVENT_ERROR`;
export const CREATE_SURGE_EVENT_EMPTY = `CREATE_SURGE_EVENT_EMPTY`;

// LIST
export const GET_SURGE_EVENTS = `GET_SURGE_EVENTS`;
export const GET_SURGE_EVENTS_SUCCESS = `GET_SURGE_EVENTS_SUCCESS`;
export const GET_SURGE_EVENTS_ERROR = `GET_SURGE_EVENTS_ERROR`;
export const GET_SURGE_EVENTS_EMPTY = `GET_SURGE_EVENTS_EMPTY`;

// UPDATE
export const UPDATE_SURGE_EVENT = `UPDATE_SURGE_EVENT`;
export const UPDATE_SURGE_EVENT_SUCCESS = `UPDATE_SURGE_EVENT_SUCCESS`;
export const UPDATE_SURGE_EVENT_ERROR = `UPDATE_SURGE_EVENT_ERROR`;
export const UPDATE_SURGE_EVENT_EMPTY = `UPDATE_SURGE_EVENT_EMPTY`;

// DELETE
export const DELETE_SURGE_EVENT = `DELETE_SURGE_EVENT`;
export const DELETE_SURGE_EVENT_SUCCESS = `DELETE_SURGE_EVENT_SUCCESS`;
export const DELETE_SURGE_EVENT_ERROR = `DELETE_SURGE_EVENT_ERROR`;
export const DELETE_SURGE_EVENT_EMPTY = `DELETE_SURGE_EVENT_EMPTY`;

/* ----------------- SURGE SPHERE - ATTRIBUTE ----------------- */
// CREATE
export const CREATE_ATTRIBUTE = `CREATE_ATTRIBUTE`;
export const CREATE_ATTRIBUTE_SUCCESS = `CREATE_ATTRIBUTE_SUCCESS`;
export const CREATE_ATTRIBUTE_ERROR = `CREATE_ATTRIBUTE_ERROR`;
export const CREATE_ATTRIBUTE_EMPTY = `CREATE_ATTRIBUTE_EMPTY`;

// LIST
export const GET_ATTRIBUTES = `GET_ATTRIBUTES`;
export const GET_ATTRIBUTES_SUCCESS = `GET_ATTRIBUTES_SUCCESS`;
export const GET_ATTRIBUTES_ERROR = `GET_ATTRIBUTES_ERROR`;
export const GET_ATTRIBUTES_EMPTY = `GET_ATTRIBUTES_EMPTY`;

// UPDATE
export const UPDATE_ATTRIBUTE = `UPDATE_ATTRIBUTE`;
export const UPDATE_ATTRIBUTE_SUCCESS = `UPDATE_ATTRIBUTE_SUCCESS`;
export const UPDATE_ATTRIBUTE_ERROR = `UPDATE_ATTRIBUTE_ERROR`;
export const UPDATE_ATTRIBUTE_EMPTY = `UPDATE_ATTRIBUTE_EMPTY`;

// DELETE
export const DELETE_ATTRIBUTE = `DELETE_ATTRIBUTE`;
export const DELETE_ATTRIBUTE_SUCCESS = `DELETE_ATTRIBUTE_SUCCESS`;
export const DELETE_ATTRIBUTE_ERROR = `DELETE_ATTRIBUTE_ERROR`;
export const DELETE_ATTRIBUTE_EMPTY = `DELETE_ATTRIBUTE_EMPTY`;

/* ----------------- SURGE SPHERE - WALLET ----------------- */
// LIST
export const GET_WALLETS = `GET_WALLETS`;
export const GET_WALLETS_SUCCESS = `GET_WALLETS_SUCCESS`;
export const GET_WALLETS_ERROR = `GET_WALLETS_ERROR`;
export const GET_WALLETS_EMPTY = `GET_WALLETS_EMPTY`;

/* ----------------------------------- DATA POOL ----------------------------------- */
// LIST
export const GET_FILES_SUMMARY = `GET_FILES_SUMMARY`;
export const GET_FILES_SUMMARY_SUCCESS = `GET_FILES_SUMMARY_SUCCESS`;
export const GET_FILES_SUMMARY_ERROR = `GET_FILES_SUMMARY_ERROR`;
export const GET_FILES_SUMMARY_EMPTY = `GET_FILES_SUMMARY_EMPTY`;

// LIST
export const GET_FILE_SUMMARY = `GET_FILE_SUMMARY`;
export const GET_FILE_SUMMARY_SUCCESS = `GET_FILE_SUMMARY_SUCCESS`;
export const GET_FILE_SUMMARY_ERROR = `GET_FILE_SUMMARY_ERROR`;
export const GET_FILE_SUMMARY_EMPTY = `GET_FILE_SUMMARY_EMPTY`;

// POST
export const UPLOAD_FILE = `UPLOAD_FILE`;
export const UPLOAD_FILE_SUCCESS = `UPLOAD_FILE_SUCCESS`;
export const UPLOAD_FILE_ERROR = `UPLOAD_FILE_ERROR`;
export const UPLOAD_FILE_EMPTY = `UPLOAD_FILE_EMPTY`;

/* ----------------------------------- DATA INTEGRATION ----------------------------------- */
// GET
export const GET_INTEGRATION_DATA = `GET_INTEGRATION_DATA`;
export const GET_INTEGRATION_DATA_SUCCESS = `GET_INTEGRATION_DATA_SUCCESS`;
export const GET_INTEGRATION_DATA_ERROR = `GET_INTEGRATION_DATA_ERROR`;
export const GET_INTEGRATION_DATA_EMPTY = `GET_INTEGRATION_DATA_EMPTY`;

// CREATE
export const CREATE_INTEGRATION_DATA = `CREATE_INTEGRATION_DATA`;
export const CREATE_INTEGRATION_DATA_SUCCESS = `CREATE_INTEGRATION_DATA_SUCCESS`;
export const CREATE_INTEGRATION_DATA_ERROR = `CREATE_INTEGRATION_DATA_ERROR`;
export const CREATE_INTEGRATION_DATA_EMPTY = `CREATE_INTEGRATION_DATA_EMPTY`;

// UPDATE
export const UPDATE_INTEGRATION_DATA = `UPDATE_INTEGRATION_DATA`;
export const UPDATE_INTEGRATION_DATA_SUCCESS = `UPDATE_INTEGRATION_DATA_SUCCESS`;
export const UPDATE_INTEGRATION_DATA_ERROR = `UPDATE_INTEGRATION_DATA_ERROR`;
export const UPDATE_INTEGRATION_DATA_EMPTY = `UPDATE_INTEGRATION_DATA_EMPTY`;

/* ----------------- COMMUNICATION  ----------------- */
// CREATE TEMPLATE
export const CREATE_TEMPLATE = `CREATE_TEMPLATE`;
export const CREATE_TEMPLATE_SUCCESS = `CREATE_TEMPLATE_SUCCESS`;
export const CREATE_TEMPLATE_ERROR = `CREATE_TEMPLATE_ERROR`;
export const CREATE_TEMPLATE_EMPTY = `CREATE_TEMPLATE_EMPTY`;

// UPDATE TEMPLATE
export const UPDATE_TEMPLATE = `UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_SUCCESS = `UPDATE_TEMPLATE_SUCCESS`;
export const UPDATE_TEMPLATE_ERROR = `UPDATE_TEMPLATE_ERROR`;
export const UPDATE_TEMPLATE_EMPTY = `UPDATE_TEMPLATE_EMPTY`;

// GET TEMPLATE LIST
export const GET_TEMPLATE_LIST = `GET_TEMPLATE_LIST`;
export const GET_TEMPLATE_LIST_SUCCESS = `GET_TEMPLATE_LIST_SUCCESS`;
export const GET_TEMPLATE_LIST_ERROR = `GET_TEMPLATE_LIST_ERROR`;
export const GET_TEMPLATE_LIST_EMPTY = `GET_TEMPLATE_LIST_EMPTY`;

// GET TEMPLATE Details
export const GET_TEMPLATE_DETAILS = `GET_TEMPLATE_DETAILS`;
export const GET_TEMPLATE_DETAILS_SUCCESS = `GET_TEMPLATE_DETAILS_SUCCESS`;
export const GET_TEMPLATE_DETAILS_ERROR = `GET_TEMPLATE_DETAILS_ERROR`;
export const GET_TEMPLATE_DETAILS_EMPTY = `GET_TEMPLATE_DETAILS_EMPTY`;

// CREATE COMS CONFIG
export const CREATE_COMS_CONFIG = `CREATE_COMS_CONFIG`;
export const CREATE_COMS_CONFIG_SUCCESS = `CREATE_COMS_CONFIG_SUCCESS`;
export const CREATE_COMS_CONFIG_ERROR = `CREATE_COMS_CONFIG_ERROR`;
export const CREATE_COMS_CONFIG_EMPTY = `CREATE_COMS_CONFIG_EMPTY`;

// UPDATE COMS CONFIG
export const UPDATE_COMS_CONFIG = `UPDATE_COMS_CONFIG`;
export const UPDATE_COMS_CONFIG_SUCCESS = `UPDATE_COMS_CONFIG_SUCCESS`;
export const UPDATE_COMS_CONFIG_ERROR = `UPDATE_COMS_CONFIG_ERROR`;
export const UPDATE_COMS_CONFIG_EMPTY = `UPDATE_COMS_CONFIG_EMPTY`;

// GET COMS CONFIG LIIST
export const GET_COMS_CONFIG_LIST = `GET_COMS_CONFIG_LIST`;
export const GET_COMS_CONFIG_LIST_SUCCESS = `GET_COMS_CONFIG_LIST_SUCCESS`;
export const GET_COMS_CONFIG_LIST_ERROR = `GET_COMS_CONFIG_LIST_ERROR`;
export const GET_COMS_CONFIG_LIST_EMPTY = `GET_COMS_CONFIG_LIST_EMPTY`;

// GET EVENT LIST
export const GET_COMS_EVENT_LIST = `GET_COMS_EVENT_LIST`;
export const GET_COMS_EVENT_LIST_SUCCESS = `GET_COMS_EVENT_LIST_SUCCESS`;
export const GET_COMS_EVENT_LIST_ERROR = `GET_COMS_EVENT_LIST_ERROR`;
export const GET_COMS_EVENT_LIST_EMPTY = `GET_COMS_EVENT_LIST_EMPTY`;

// GET COMS VENDOR LIST
export const GET_COMS_VENDOR_LIST = `GET_COMS_VENDOR_LIST`;
export const GET_COMS_VENDOR_LIST_SUCCESS = `GET_COMS_VENDOR_LIST_SUCCESS`;
export const GET_COMS_VENDOR_LIST_ERROR = `GET_COMS_VENDOR_LIST_ERROR`;
export const GET_COMS_VENDOR_LIST_EMPTY = `GET_COMS_VENDOR_LIST_EMPTY`;

//  SEND COMMUNICATION TESTING
export const SEND_COMMUNICATION = `SEND_COMMUNICATION`;
export const SEND_COMMUNICATION_SUCCESS = `SEND_COMMUNICATION_SUCCESS`;
export const SEND_COMMUNICATION_ERROR = `SEND_COMMUNICATION_ERROR`;
export const SEND_COMMUNICATION_EMPTY = `SEND_COMMUNICATION_EMPTY`;

// DASHBOARD BOOKING SUMMARY
export const GET_BOOKING_SUMMARY = `GET_BOOKING_SUMMARY`;
export const GET_BOOKING_SUMMARY_SUCCESS = `GET_BOOKING_SUMMARY_SUCCESS`;
export const GET_BOOKING_SUMMARY_ERROR = `GET_BOOKING_SUMMARY_ERROR`;
export const GET_BOOKING_SUMMARY_EMPTY = `GET_BOOKING_SUMMARY_EMPTY`;

// CREATE COMS CONFIG Project
export const CREATE_COMS_CHANNEL_CONFIG_PROJECT = `CREATE_COMS_CHANNEL_CONFIG_PROJECT`;
export const CREATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS = `CREATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS`;
export const CREATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR = `CREATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR`;
export const CREATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY = `CREATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY`;

// CREATE COMS CONFIG Project
export const GET_COMS_CHANNEL_CONFIG_PROJECT = `GET_COMS_CHANNEL_CONFIG_PROJECT`;
export const GET_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS = `GET_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS`;
export const GET_COMS_CHANNEL_CONFIG_PROJECT_ERROR = `GET_COMS_CHANNEL_CONFIG_PROJECT_ERROR`;
export const GET_COMS_CHANNEL_CONFIG_PROJECT_EMPTY = `GET_COMS_CHANNEL_CONFIG_PROJECT_EMPTY`;

// CREATE COMS_CHANNEL CONFIG Project
export const UPDATE_COMS_CHANNEL_CONFIG_PROJECT = `UPDATE_COMS_CHANNEL_CONFIG_PROJECT`;
export const UPDATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS = `UPDATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS`;
export const UPDATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR = `UPDATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR`;
export const UPDATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY = `UPDATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY`;

// GET VARIABLES
export const GET_VARIABLES = `GET_VARIABLES`;
export const GET_VARIABLES_SUCCESS = `GET_VARIABLES_SUCCESS`;
export const GET_VARIABLES_ERROR = `GET_VARIABLES_ERROR`;
export const GET_VARIABLES_EMPTY = `GET_VARIABLES_EMPTY`;

// GET COMS BENEFIT
export const GET_COMS_BENEFIT = `GET_COMS_BENEFIT`;
export const GET_COMS_BENEFIT_SUCCESS = `GET_COMS_BENEFIT_SUCCESS`;
export const GET_COMS_BENEFIT_ERROR = `GET_COMS_BENEFIT_ERROR`;
export const GET_COMS_BENEFIT_EMPTY = `GET_COMS_BENEFIT_EMPTY`;

/* ----------------------------------- THRIWE PRIME BOOKING ----------------------------------- */
// GET LIST
export const GET_THRIWE_PRIME_BOOKING_LIST = `GET_THRIWE_PRIME_BOOKING_LIST`;
export const GET_THRIWE_PRIME_BOOKING_SUCCESS = `GET_THRIWE_PRIME_BOOKING_SUCCESS`;
export const GET_THRIWE_PRIME_BOOKING_ERROR = `GET_THRIWE_PRIME_BOOKING_ERROR`;
export const GET_THRIWE_PRIME_BOOKING_EMPTY = `GET_THRIWE_PRIME_BOOKING_EMPTY`;

/* ----------------------------------- REPORTS MANAGEMENT ----------------------------------- */
// GET COMMS REPORT
export const GET_COMMS_REPORT_LIST = `GET_COMMS_REPORT_LIST`;
export const GET_COMMS_REPORT_SUCCESS = `GET_COMMS_REPORT_SUCCESS`;
export const GET_COMMS_REPORT_ERROR = `GET_COMMS_REPORT_ERROR`;
export const GET_COMMS_REPORT_EMPTY = `GET_COMMS_REPORT_EMPTY`;

/* ----------------------------------- VENDOR PANEL MANAGEMENT ----------------------------------- */
// GET BENEFIT DOMAINS
export const GET_BENEFIT_DOMAINS = `GET_BENEFIT_DOMAINS`;
export const GET_BENEFIT_DOMAINS_SUCCESS = `GET_BENEFIT_DOMAINS_SUCCESS`;
export const GET_BENEFIT_DOMAINS_ERROR = `GET_BENEFIT_DOMAINS_ERROR`;
export const GET_BENEFIT_DOMAINS_EMPTY = `GET_BENEFIT_DOMAINS_EMPTY`;

// GET VENDORS LIST
export const GET_VENDORS = `GET_VENDORS`;
export const GET_VENDORS_SUCCESS = `GET_VENDORS_SUCCESS`;
export const GET_VENDORS_ERROR = `GET_VENDORS_ERROR`;
export const GET_VENDORS_EMPTY = `GET_VENDORS_EMPTY`;

// CREATE VENDOR
export const CREATE_VENDOR = `CREATE_VENDOR`;
export const CREATE_VENDOR_SUCCESS = `CREATE_VENDOR_SUCCESS`;
export const CREATE_VENDOR_ERROR = `CREATE_VENDOR_ERROR`;
export const CREATE_VENDOR_EMPTY = `CREATE_VENDOR_EMPTY`;

// CREATE VENDOR AGENT
export const CREATE_VENDOR_AGENT = `CREATE_VENDOR_AGENT`;
export const CREATE_VENDOR_AGENT_SUCCESS = `CREATE_VENDOR_AGENT_SUCCESS`;
export const CREATE_VENDOR_AGENT_ERROR = `CREATE_VENDOR_AGENT_ERROR`;
export const CREATE_VENDOR_AGENT_EMPTY = `CREATE_VENDOR_AGENT_EMPTY`;

// UPDATE MEMBERSHIP
export const UPDATE_MEMBERSHIP = `UPDATE_MEMBERSHIP`;
export const UPDATE_MEMBERSHIP_SUCCESS = `UPDATE_MEMBERSHIP_SUCCESS`;
export const UPDATE_MEMBERSHIP_ERROR = `UPDATE_MEMBERSHIP_ERROR`;
export const UPDATE_MEMBERSHIP_EMPTY = `UPDATE_BENEFIT_GROUP_ITM_EMPTY`;

/* ----------------------------------- CLIENT PANEL MANAGEMENT ----------------------------------- */
// CLIENT LOGIN
export const CLIENT_LOGIN = `CLIENT_LOGIN`;
export const CLIENT_LOGIN_SUCCESS = `CLIENT_LOGIN_SUCCESS`;
export const CLIENT_LOGIN_ERROR = `CLIENT_LOGIN_ERROR`;
export const CLIENT_LOGIN_EMPTY = `CLIENT_LOGIN_EMPTY`;

// VALIDATE TOKEN
export const VALIDATE_TOKEN = `VALIDATE_TOKEN`;
export const VALIDATE_TOKEN_SUCCESS = `VALIDATE_TOKEN_SUCCESS`;
export const VALIDATE_TOKEN_ERROR = `VALIDATE_TOKEN_ERROR`;
export const VALIDATE_TOKEN_EMPTY = `VALIDATE_TOKEN_EMPTY`;

// GET MEMBERSHIPS
export const GET_MEMBERSHIPS_DATA = `GET_MEMBERSHIPS_DATA`;
export const GET_MEMBERSHIPS_DATA_SUCCESS = `GET_MEMBERSHIPS_DATA_SUCCESS`;
export const GET_MEMBERSHIPS_DATA_ERROR = `GET_MEMBERSHIPS_DATA_ERROR`;
export const GET_MEMBERSHIPS_DATA_EMPTY = `GET_MEMBERSHIPS_DATA_EMPTY`;

// CLIENT FORGOT PASSWORD
export const CLIENT_FORGOT_PASSWORD = `CLIENT_FORGOT_PASSWORD`;
export const CLIENT_FORGOT_PASSWORD_SUCCESS = `CLIENT_FORGOT_PASSWORD_SUCCESS`;
export const CLIENT_FORGOT_PASSWORD_ERROR = `CLIENT_FORGOT_PASSWORD_ERROR`;
export const CLIENT_FORGOT_PASSWORD_EMPTY = `CLIENT_FORGOT_PASSWORD_EMPTY`;

// CLIENT RESET PASSWORD
export const CLIENT_RESET_PASSWORD = `CLIENT_RESET_PASSWORD`;
export const CLIENT_RESET_PASSWORD_SUCCESS = `CLIENT_RESET_PASSWORD_SUCCESS`;
export const CLIENT_RESET_PASSWORD_ERROR = `CLIENT_RESET_PASSWORD_ERROR`;
export const CLIENT_RESET_PASSWORD_EMPTY = `CLIENT_RESET_PASSWORD_EMPTY`;

// CREATE BOOKING
export const CREATE_BOOKING = `CREATE_BOOKING`;
export const CREATE_BOOKING_SUCCESS = `CREATE_BOOKING_SUCCESS`;
export const CREATE_BOOKING_ERROR = `CREATE_BOOKING_ERROR`;
export const CREATE_BOOKING_EMPTY = `CREATE_BOOKING_EMPTY`;

// UPDATE PROFILE
// GET MEMEBERSHIP ADMIN
export const GET_ADMIN_MEMBERSHIP = `GET_ADMIN_MEMBERSHIP`;
export const GET_ADMIN_MEMBERSHIP_SUCCESS = `GET_ADMIN_MEMBERSHIP_SUCCESS`;
export const GET_ADMIN_MEMBERSHIP_ERROR = `GET_ADMIN_MEMBERSHIP_ERROR`;
export const GET_ADMIN_MEMBERSHIP_EMPTY = `GET_ADMIN_MEMBERSHIP_EMPTY`;

// GET BENEFIT CATEGORY
export const GET_BENEFIT_CATEGORY = `GET_BENEFIT_CATEGORY`;
export const GET_BENEFIT_CATEGORY_SUCCESS = `GET_BENEFIT_CATEGORY_SUCCESS`;
export const GET_BENEFIT_CATEGORY_ERROR = `GET_BENEFIT_CATEGORY_ERROR`;
export const GET_BENEFIT_CATEGORY_EMPTY = `GET_BENEFIT_CATEGORY_EMPTY`;

// GET USER CARD
export const GET_USER_CARD_DATA = `GET_USER_CARD_DATA`;
export const GET_USER_CARD_DATA_SUCCESS = `GET_USER_CARD_DATA_SUCCESS`;
export const GET_USER_CARD_DATA_ERROR = `GET_USER_CARD_DATA_ERROR`;
export const GET_USER_CARD_DATA_EMPTY = `GET_USER_CARD_DATA_EMPTY`;

// GET MEMBERSHIP QUOTA
export const GET_MEMBERSHIP_QUOTA = `GET_MEMBERSHIP_QUOTA`;
export const GET_MEMBERSHIP_QUOTA_SUCCESS = `GET_MEMBERSHIP_QUOTA_SUCCESS`;
export const GET_MEMBERSHIP_QUOTA_ERROR = `GET_MEMBERSHIP_QUOTA_ERROR`;
export const GET_MEMBERSHIP_QUOTA_EMPTY = `GET_MEMBERSHIP_QUOTA_EMPTY`;

// CREATE MANUAL BOOKING
export const CREATE_MANUAL_BOOKING = `CREATE_MANUAL_BOOKING`;
export const CREATE_MANUAL_BOOKING_SUCCESS = `CREATE_MANUAL_BOOKING_SUCCESS`;
export const CREATE_MANUAL_BOOKING_ERROR = `CREATE_MANUAL_BOOKING_ERROR`;
export const CREATE_MANUAL_BOOKING_EMPTY = `CREATE_MANUAL_BOOKING_EMPTY`;

// GET BENEFIT ITEM
export const GET_ADMIN_BENEFIT_ITEM = `GET_ADMIN_BENEFIT_ITEM`;
export const GET_ADMIN_BENEFIT_ITEM_SUCCESS = `GET_ADMIN_BENEFIT_ITEM_SUCCESS`;
export const GET_ADMIN_BENEFIT_ITEM_ERROR = `GET_ADMIN_BENEFIT_ITEM_ERROR`;
export const GET_ADMIN_BENEFIT_ITEM_EMPTY = `GET_ADMIN_BENEFIT_ITEM_EMPTY`;

//  UPLOAD FILE
export const CREATE_UPLOAD_TICKET = `CREATE_UPLOAD_TICKET`;
export const CREATE_UPLOAD_TICKET_SUCCESS = `CREATE_UPLOAD_TICKET_SUCCESS`;
export const CREATE_UPLOAD_TICKET_ERROR = `CREATE_UPLOAD_TICKET_ERROR`;
export const CREATE_UPLOAD_TICKET_EMPTY = `CREATE_UPLOAD_TICKET_EMPTY`;

// GET BENEFIT ITEM
export const UPDATE_PROFILE = `UPDATE_PROFILE`;
export const UPDATE_PROFILE_SUCCESS = `UPDATE_PROFILE_SUCCESS`;
export const UPDATE_PROFILE_ERROR = `UPDATE_PROFILE_ERROR`;
export const UPDATE_PROFILE_EMPTY = `UPDATE_PROFILE_EMPTY`;

// GET BENEFIT ITEMS
export const GET_BENEFIT_ITEMS = `GET_BENEFIT_ITEMS`;
export const GET_BENEFIT_ITEMS_SUCCESS = `GET_BENEFIT_ITEMS_SUCCESS`;
export const GET_BENEFIT_ITEMS_ERROR = `GET_BENEFIT_ITEMS_ERROR`;
export const GET_BENEFIT_ITEMS_EMPTY = `GET_BENEFIT_ITEMS_EMPTY`;

// GET BINS LIST
export const GET_BINS_LIST = `GET_BINS_LIST`;
export const GET_BINS_LIST_SUCCESS = `GET_BINS_LIST_SUCCESS`;
export const GET_BINS_LIST_ERROR = `GET_BINS_LIST_ERROR`;
export const GET_BINS_LIST_EMPTY = `GET_BINS_LIST_EMPTY`;

// CREATE CLIENT USER
export const CREATE_CLIENT_USER = `CREATE_CLIENT_USER`;
export const CREATE_CLIENT_USER_SUCCESS = `CREATE_CLIENT_USER_SUCCESS`;
export const CREATE_CLIENT_USER_ERROR = `CREATE_CLIENT_USER_ERROR`;
export const CREATE_CLIENT_USER_EMPTY = `CREATE_CLIENT_USER_EMPTY`;

// CLIENT HISTORY
export const GET_CLIENT_HISTORY_LIST = `GET_CLIENT_HISTORY_LIST`;
export const GET_CLIENT_HISTORY_SUCCESS = `GET_CLIENT_HISTORY_SUCCESS`;
export const GET_CLIENT_HISTORY_ERROR = `GET_CLIENT_HISTORY_ERROR`;
export const GET_CLIENT_HISTORY_EMPTY = `GET_CLIENT_HISTORY_EMPTY`;

// GET CLIENT BENEFIT CONFIG
export const GET_CLIENT_BENEFIT_CONFIG = `GET_CLIENT_BENEFIT_CONFIG`;
export const GET_CLIENT_BENEFIT_CONFIG_SUCCESS = `GET_CLIENT_BENEFIT_CONFIG_SUCCESS`;
export const GET_CLIENT_BENEFIT_CONFIG_ERROR = `GET_CLIENT_BENEFIT_CONFIG_ERROR`;
export const GET_CLIENT_BENEFIT_CONFIG_EMPTY = `GET_CLIENT_BENEFIT_CONFIG_EMPTY`;

// GET CLIENT AIRPORT
export const GET_CLIENT_AIRPORT = `GET_CLIENT_AIRPORT`;
export const GET_CLIENT_AIRPORT_SUCCESS = `GET_CLIENT_AIRPORT_SUCCESS`;
export const GET_CLIENT_AIRPORT_ERROR = `GET_CLIENT_AIRPORT_ERROR`;
export const GET_CLIENT_AIRPORT_EMPTY = `GET_CLIENT_AIRPORT_EMPTY`;

// SEND CLIENT DOWNLOAD DUMP
export const SEND_CLIENT_DOWNLOAD_DUMP = `SEND_CLIENT_DOWNLOAD_DUMP`;
export const SEND_CLIENT_DOWNLOAD_DUMP_SUCCESS = `SEND_CLIENT_DOWNLOAD_DUMP_SUCCESS`;
export const SEND_CLIENT_DOWNLOAD_DUMP_ERROR = `SEND_CLIENT_DOWNLOAD_DUMP_ERROR`;
export const SEND_CLIENT_DOWNLOAD_DUMP_EMPTY = `SEND_CLIENT_DOWNLOAD_DUMP_EMPTY`;

// CLIENT DOWNLOADS LIST
export const CLIENT_DOWNLOADS_LIST = `CLIENT_DOWNLOADS_LIST`;
export const CLIENT_DOWNLOADS_LIST_SUCCESS = `CLIENT_DOWNLOADS_LIST_SUCCESS`;
export const CLIENT_DOWNLOADS_LIST_ERROR = `CLIENT_DOWNLOADS_LIST_ERROR`;
export const CLIENT_DOWNLOADS_LIST_EMPTY = `CLIENT_DOWNLOADS_LIST_EMPTY`;

// SEND MAIL
export const SEND_EMAIL = `SEND_EMAIL`;
export const SEND_EMAIL_SUCCESS = `SEND_EMAIL_SUCCESS`;
export const SEND_EMAIL_ERROR = `SEND_EMAIL_ERROR`;
export const SEND_EMAIL_EMPTY = `SEND_EMAIL_EMPTY`;

// GET CLIENT SUBREGION
export const GET_CLIENT_REGION = `GET_CLIENT_REGION`;
export const GET_CLIENT_REGION_SUCCESS = `GET_CLIENT_REGION_SUCCESS`;
export const GET_CLIENT_REGION_ERROR = `GET_CLIENT_REGION_ERROR`;
export const GET_CLIENT_REGION_EMPTY = `GET_CLIENT_REGION_EMPTY`;

// GET CLIENT CUSTOMER
export const GET_CLIENT_CUSTOMER = `GET_CLIENT_CUSTOMER`;
export const GET_CLIENT_CUSTOMER_SUCCESS = `GET_CLIENT_CUSTOMER_SUCCESS`;
export const GET_CLIENT_CUSTOMER_ERROR = `GET_CLIENT_CUSTOMER_ERROR`;
export const GET_CLIENT_CUSTOMER_EMPTY = `GET_CLIENT_CUSTOMER_EMPTY`;

// GET VENDOR DOWNLOAD BOOKINGS
export const GET_VENDOR_DOWNLOAD_BOOKINGS = `GET_VENDOR_DOWNLOAD_BOOKINGS`;
export const GET_VENDOR_DOWNLOAD_BOOKINGS_SUCCESS = `GET_VENDOR_DOWNLOAD_BOOKINGS_SUCCESS`;
export const GET_VENDOR_DOWNLOAD_BOOKINGS_ERROR = `GET_VENDOR_DOWNLOAD_BOOKINGS_ERROR`;
export const GET_VENDOR_DOWNLOAD_BOOKINGS_EMPTY = `GET_VENDOR_DOWNLOAD_BOOKINGS_EMPTY`;

// GET VENDOR DOWNLOADS INFO
export const GET_VENDOR_DOWNLOADS_INFO = `GET_VENDOR_DOWNLOADS_INFO`;
export const GET_VENDOR_DOWNLOADS_INFO_SUCCESS = `GET_VENDOR_DOWNLOADS_INFO_SUCCESS`;
export const GET_VENDOR_DOWNLOADS_INFO_ERROR = `GET_VENDOR_DOWNLOADS_INFO_ERROR`;
export const GET_VENDOR_DOWNLOADS_INFO_EMPTY = `GET_VENDOR_DOWNLOADS_INFO_EMPTY`;

/* ----------------------------------- VENDOR ----------------------------------- */
// VENDOR LOGIN
export const VENDOR_LOGIN = `VENDOR_LOGIN`;
export const VENDOR_LOGIN_SUCCESS = `VENDOR_LOGIN_SUCCESS`;
export const VENDOR_LOGIN_ERROR = `VENDOR_LOGIN_ERROR`;
export const VENDOR_LOGIN_EMPTY = `VENDOR_LOGIN_EMPTY`;

// VENDOR VALIDATE OTP
export const VENDOR_OTP_VALIDATE = `VENDOR_OTP_VALIDATE`;
export const VENDOR_OTP_VALIDATE_SUCCESS = `VENDOR_OTP_VALIDATE_SUCCESS`;
export const VENDOR_OTP_VALIDATE_ERROR = `VENDOR_OTP_VALIDATE_ERROR`;
export const VENDOR_OTP_VALIDATE_EMPTY = `VENDOR_OTP_VALIDATE_EMPTY`;

// VENDOR RESEND OTP
export const VENDOR_RESEND_OTP = `VENDOR_RESEND_OTP`;
export const VENDOR_RESEND_OTP_SUCCESS = `VENDOR_RESEND_OTP_SUCCESS`;
export const VENDOR_RESEND_OTP_ERROR = `VENDOR_RESEND_OTP_ERROR`;
export const VENDOR_RESEND_OTP_EMPTY = `VENDOR_RESEND_OTP_EMPTY`;

// VENDORS DASHBOARD DATA
export const VENDOR_DASHBOARD_DATA = `VENDOR_DASHBOARD_DATA`;
export const VENDOR_DASHBOARD_DATA_SUCCESS = `VENDOR_DASHBOARD_DATA_SUCCESS`;
export const VENDOR_DASHBOARD_DATA_ERROR = `VENDOR_DASHBOARD_DATA_ERROR`;
export const VENDOR_DASHBOARD_DATA_EMPTY = `VENDOR_DASHBOARD_DATA_EMPTY`;

// VENDORS BOOKINGS DATA
export const VENDOR_BOOKINGS_DATA = `VENDOR_BOOKINGS_DATA`;
export const VENDOR_BOOKINGS_DATA_SUCCESS = `VENDOR_BOOKINGS_DATA_SUCCESS`;
export const VENDOR_BOOKINGS_DATA_ERROR = `VENDOR_BOOKINGS_DATA_ERROR`;
export const VENDOR_BOOKINGS_DATA_EMPTY = `VENDOR_BOOKINGS_DATA_EMPTY`;

// VENDOR UPDATE BOOKING
export const VENDOR_UPDATE_BOOKING = `VENDOR_UPDATE_BOOKING`;
export const VENDOR_UPDATE_BOOKING_SUCCESS = `VENDOR_UPDATE_BOOKING_SUCCESS`;
export const VENDOR_UPDATE_BOOKING_ERROR = `VENDOR_UPDATE_BOOKING_ERROR`;
export const VENDOR_UPDATE_BOOKING_EMPTY = `VENDOR_UPDATE_BOOKING_EMPTY`;

// VENDORS LIST
export const VENDORS_LIST = `VENDORS_LIST`;
export const VENDORS_LIST_SUCCESS = `VENDORS_LIST_SUCCESS`;
export const VENDORS_LIST_ERROR = `VENDORS_LIST_ERROR`;
export const VENDORS_LIST_EMPTY = `VENDORS_LIST_EMPTY`;

// VENDORS GENERATE TOKEN
export const VENDOR_GENERATE_QR = `VENDOR_GENERATE_QR`;
export const VENDOR_GENERATE_QR_SUCCESS = `VENDOR_GENERATE_QR_SUCCESS`;
export const VENDOR_GENERATE_QR_ERROR = `VENDOR_GENERATE_QR_ERROR`;
export const VENDOR_GENERATE_QR_EMPTY = `VENDOR_GENERATE_QR_EMPTY`;

// VENDOR DOWNLOAD DUMP
export const VENDOR_DOWNLOAD_DUMP = `VENDOR_DOWNLOAD_DUMP`;
export const VENDOR_DOWNLOAD_DUMP_SUCCESS = `VENDOR_DOWNLOAD_DUMP_SUCCESS`;
export const VENDOR_DOWNLOAD_DUMP_ERROR = `VENDOR_DOWNLOAD_DUMP_ERROR`;
export const VENDOR_DOWNLOAD_DUMP_EMPTY = `VENDOR_DOWNLOAD_DUMP_EMPTY`;

// VENDOR DOWNLOAD LIST
export const VENDOR_DOWNLOAD_LIST = `VENDOR_DOWNLOAD_LIST`;
export const VENDOR_DOWNLOAD_LIST_SUCCESS = `VENDOR_DOWNLOAD_LIST_SUCCESS`;
export const VENDOR_DOWNLOAD_LIST_ERROR = `VENDOR_DOWNLOAD_LIST_ERROR`;
export const VENDOR_DOWNLOAD_LIST_EMPTY = `VENDOR_DOWNLOAD_LIST_EMPTY`;

/* ----------------------------------- GM REPORT ----------------------------------- */
// FILE TYPES LIST
export const GET_FILE_TYPES = `GET_FILE_TYPES`;
export const GET_FILE_TYPES_SUCCESS = `GET_FILE_TYPES_SUCCESS`;
export const GET_FILE_TYPES_ERROR = `GET_FILE_TYPES_ERROR`;
export const GET_FILE_TYPES_EMPTY = `GET_FILE_TYPES_EMPTY`;

// FILE TYPES CONFIG LIST
export const GET_FILE_TYPES_CONFIG = `GET_FILE_TYPES_CONFIG`;
export const GET_FILE_TYPES_CONFIG_SUCCESS = `GET_FILE_TYPES_CONFIG_SUCCESS`;
export const GET_FILE_TYPES_CONFIG_ERROR = `GET_FILE_TYPES_CONFIG_ERROR`;
export const GET_FILE_TYPES_CONFIG_EMPTY = `GET_FILE_TYPES_CONFIG_EMPTY`;

// DOWNLOAD GM RECORD LIST IN EXCEL
export const DOWNLOAD_CONFIG_EXCEL = `DOWNLOAD_CONFIG_EXCEL`;
export const DOWNLOAD_CONFIG_EXCEL_SUCCESS = `DOWNLOAD_CONFIG_EXCEL_SUCCESS`;
export const DOWNLOAD_CONFIG_EXCEL_ERROR = `DOWNLOAD_CONFIG_EXCEL_ERROR`;
export const DOWNLOAD_CONFIG_EXCEL_EMPTY = `DOWNLOAD_CONFIG_EXCEL_EMPTY`;

// UPLOAD CONFIG EXCEL
export const UPLOAD_CONFIG_EXCEL = `UPLOAD_CONFIG_EXCEL`;
export const UPLOAD_CONFIG_EXCEL_SUCCESS = `UPLOAD_CONFIG_EXCEL_SUCCESS`;
export const UPLOAD_CONFIG_EXCEL_ERROR = `UPLOAD_CONFIG_EXCEL_ERROR`;
export const UPLOAD_CONFIG_EXCEL_EMPTY = `UPLOAD_CONFIG_EXCEL_EMPTY`;

// GET RECORDED DATA
export const GET_RECORD_DATA = `GET_RECORD_DATA`;
export const GET_RECORD_DATA_SUCCESS = `GET_RECORD_DATA_SUCCESS`;
export const GET_RECORD_DATA_ERROR = `GET_RECORD_DATA_ERROR`;
export const GET_RECORD_DATA_EMPTY = `GET_RECORD_DATA_EMPTY`;

// UPDATE RECORDED DATA
export const UPDATE_RECORD_DATA = `UPDATE_RECORD_DATA`;
export const UPDATE_RECORD_DATA_SUCCESS = `UPDATE_RECORD_DATA_SUCCESS`;
export const UPDATE_RECORD_DATA_ERROR = `UPDATE_RECORD_DATA_ERROR`;
export const UPDATE_RECORD_DATA_EMPTY = `UPDATE_RECORD_DATA_EMPTY`;

// GET TRACKER DATA
export const GET_TRACKER_DATA = `GET_TRACKER_DATA`;
export const GET_TRACKER_DATA_SUCCESS = `GET_TRACKER_DATA_SUCCESS`;
export const GET_TRACKER_DATA_ERROR = `GET_TRACKER_DATA_ERROR`;
export const GET_TRACKER_DATA_EMPTY = `GET_TRACKER_DATA_EMPTY`;

// PUBLISH DATA
export const PUBLISH_RECORD_DATA = `PUBLISH_RECORD_DATA`;
export const PUBLISH_RECORD_DATA_SUCCESS = `PUBLISH_RECORD_DATA_SUCCESS`;
export const PUBLISH_RECORD_DATA_ERROR = `PUBLISH_RECORD_DATA_ERROR`;
export const PUBLISH_RECORD_DATA_EMPTY = `PUBLISH_RECORD_DATA_EMPTY`;

// APPROVE REJECT
export const APPROVE_REJECT_RECORDS = `APPROVE_REJECT_RECORDS`;
export const APPROVE_REJECT_RECORDS_SUCCESS = `APPROVE_REJECT_RECORDS_SUCCESS`;
export const APPROVE_REJECT_RECORDS_ERROR = `APPROVE_REJECT_RECORDS_ERROR`;
export const APPROVE_REJECT_RECORDS_EMPTY = `APPROVE_REJECT_RECORDS_EMPTY`;

// SEND APPROVAL MAIL
export const SEND_APPROVAL_MAIL = `SEND_APPROVAL_MAIL`;
export const SEND_APPROVAL_MAIL_SUCCESS = `SEND_APPROVAL_MAIL_SUCCESS`;
export const SEND_APPROVAL_MAIL_ERROR = `SEND_APPROVAL_MAIL_ERROR`;
export const SEND_APPROVAL_MAIL_EMPTY = `SEND_APPROVAL_MAIL_EMPTY`;

// PUBLISH DATA WITH AUTH
export const PUBLISH_DATA_WITH_AUTH = `PUBLISH_DATA_WITH_AUTH`;
export const PUBLISH_DATA_WITH_AUTH_SUCCESS = `PUBLISH_DATA_WITH_AUTH_SUCCESS`;
export const PUBLISH_DATA_WITH_AUTH_ERROR = `PUBLISH_DATA_WITH_AUTH_ERROR`;
export const PUBLISH_DATA_WITH_AUTH_EMPTY = `PUBLISH_DATA_WITH_AUTH_EMPTY`;

// DELETE TRACKER RECORD
export const DELETE_TRACKER_RECORD = `DELETE_TRACKER_RECORD`;
export const DELETE_TRACKER_RECORD_SUCCESS = `DELETE_TRACKER_RECORD_SUCCESS`;
export const DELETE_TRACKER_RECORD_ERROR = `DELETE_TRACKER_RECORD_ERROR`;
export const DELETE_TRACKER_RECORD_EMPTY = `DELETE_TRACKER_RECORD_EMPTY`;

// GET CLIENT PRICE
export const GET_CLIENT_PRICE = `GET_CLIENT_PRICE`;
export const GET_CLIENT_PRICE_SUCCESS = `GET_CLIENT_PRICE_SUCCESS`;
export const GET_CLIENT_PRICE_ERROR = `GET_CLIENT_PRICE_ERROR`;
export const GET_CLIENT_PRICE_EMPTY = `GET_CLIENT_PRICE_EMPTY`;

// GET AMENITIES DETAILS
export const GET_AMENITIES_DETAILS = `GET_AMENITIES_DETAILS`;
export const GET_AMENITIES_DETAILS_SUCCESS = `GET_AMENITIES_DETAILS_SUCCESS`;
export const GET_AMENITIES_DETAILS_ERROR = `GET_AMENITIES_DETAILS_ERROR`;
export const GET_AMENITIES_DETAILS_EMPTY = `GET_AMENITIES_DETAILS_EMPTY`;

// GET PRICE DETAILS
export const GET_PRICE_DETAILS = `GET_PRICE_DETAILS`;
export const GET_PRICE_DETAILS_SUCCESS = `GET_PRICE_DETAILS_SUCCESS`;
export const GET_PRICE_DETAILS_ERROR = `GET_PRICE_DETAILS_ERROR`;
export const GET_PRICE_DETAILS_EMPTY = `GET_PRICE_DETAILS_EMPTY`;

// PRICE PREVIEW
export const PRICE_PREVIEW = `PRICE_PREVIEW`;
export const PRICE_PREVIEW_SUCCESS = `PRICE_PREVIEW_SUCCESS`;
export const PRICE_PREVIEW_ERROR = `PRICE_PREVIEW_ERROR`;
export const PRICE_PREVIEW_EMPTY = `PRICE_PREVIEW_EMPTY`;

// CLIENT PRICE DETAILS
export const CLIENT_PRICE_DETAILS = `CLIENT_PRICE_DETAILS`;
export const CLIENT_PRICE_DETAILS_SUCCESS = `CLIENT_PRICE_DETAILS_SUCCESS`;
export const CLIENT_PRICE_DETAILS_ERROR = `CLIENT_PRICE_DETAILS_ERROR`;
export const CLIENT_PRICE_DETAILS_EMPTY = `CLIENT_PRICE_DETAILS_EMPTY`;

// UPLOAD ATTACHMENT
export const UPLOAD_ATTACHMENT = `UPLOAD_ATTACHMENT`;
export const UPLOAD_ATTACHMENT_SUCCESS = `UPLOAD_ATTACHMENT_SUCCESS`;
export const UPLOAD_ATTACHMENT_ERROR = `UPLOAD_ATTACHMENT_ERROR`;
export const UPLOAD_ATTACHMENT_EMPTY = `UPLOAD_ATTACHMENT_EMPTY`;

// DELETE ATTACHMENT
export const DELETE_ATTACHMENT = `DELETE_ATTACHMENT`;
export const DELETE_ATTACHMENT_SUCCESS = `DELETE_ATTACHMENT_SUCCESS`;
export const DELETE_ATTACHMENT_ERROR = `DELETE_ATTACHMENT_ERROR`;
export const DELETE_ATTACHMENT_EMPTY = `DELETE_ATTACHMENT_EMPTY`;

// GET USER PROFILE
export const GET_USER_PROFILE = `GET_USER_PROFILE`;
export const GET_USER_PROFILE_SUCCESS = `GET_USER_PROFILE_SUCCESS`;
export const GET_USER_PROFILE_ERROR = `GET_USER_PROFILE_ERROR`;
export const GET_USER_PROFILE_EMPTY = `GET_USER_PROFILE_EMPTY`;

// GET PROJECT UTILITIES
export const GET_PROJECT_UTILITY = `GET_PROJECT_UTILITY`;
export const GET_PROJECT_UTILITY_SUCCESS = `GET_PROJECT_UTILITY_SUCCESS`;
export const GET_PROJECT_UTILITY_ERROR = `GET_PROJECT_UTILITY_ERROR`;
export const GET_PROJECT_UTILITY_EMPTY = `GET_PROJECT_UTILITY_EMPTY`;

// GET RECON DETAILS
export const GET_RECON_DETAILS = `GET_RECON_DETAILS`;
export const GET_RECON_DETAILS_SUCCESS = `GET_RECON_DETAILS_SUCCESS`;
export const GET_RECON_DETAILS_ERROR = `GET_RECON_DETAILS_ERROR`;
export const GET_RECON_DETAILS_EMPTY = `GET_RECON_DETAILS_EMPTY`;

// GET RECON PREVIEW
export const GET_RECON_PREVIEW = `GET_RECON_PREVIEW`;
export const GET_RECON_PREVIEW_SUCCESS = `GET_RECON_PREVIEW_SUCCESS`;
export const GET_RECON_PREVIEW_ERROR = `GET_RECON_PREVIEW_ERROR`;
export const GET_RECON_PREVIEW_EMPTY = `GET_RECON_PREVIEW_EMPTY`;

// GET GM REPORT
export const GET_GM_REPORT = `GET_GM_REPORT`;
export const GET_GM_REPORT_SUCCESS = `GET_GM_REPORT_SUCCESS`;
export const GET_GM_REPORT_ERROR = `GET_GM_REPORT_ERROR`;
export const GET_GM_REPORT_EMPTY = `GET_GM_REPORT_EMPTY`;

/* ----------------------------------- ADIB - CLIENT ----------------------------------- */
// GET BUSINESS
export const GET_BUSINESS = `GET_BUSINESS`;
export const GET_BUSINESS_SUCCESS = `GET_BUSINESS_SUCCESS`;
export const GET_BUSINESS_ERROR = `GET_BUSINESS_ERROR`;
export const GET_BUSINESS_EMPTY = `GET_BUSINESS_EMPTY`;

// UPDATE BUSINESS
export const UPDATE_BUSINESS = `UPDATE_BUSINESS`;
export const UPDATE_BUSINESS_SUCCESS = `UPDATE_BUSINESS_SUCCESS`;
export const UPDATE_BUSINESS_ERROR = `UPDATE_BUSINESS_ERROR`;
export const UPDATE_BUSINESS_EMPTY = `UPDATE_BUSINESS_EMPTY`;

// GET QUESTION ANSWER
export const GET_QUES_ANS = `GET_QUES_ANS`;
export const GET_QUES_ANS_SUCCESS = `GET_QUES_ANS_SUCCESS`;
export const GET_QUES_ANS_ERROR = `GET_QUES_ANS_ERROR`;
export const GET_QUES_ANS_EMPTY = `GET_QUES_ANS_EMPTY`;

// CREATE QUESTION
export const ASK_QUESTION = `ASK_QUESTION`;
export const ASK_QUESTION_SUCCESS = `ASK_QUESTION_SUCCESS`;
export const ASK_QUESTION_ERROR = `ASK_QUESTION_ERROR`;
export const ASK_QUESTION_EMPTY = `ASK_QUESTION_EMPTY`;

// GET POST
export const GET_POST = `GET_POST`;
export const GET_POST_SUCCESS = `GET_POST_SUCCESS`;
export const GET_POST_ERROR = `GET_POST_ERROR`;
export const GET_POST_EMPTY = `GET_POST_EMPTY`;

// UPDATE POST
export const UPDATE_POST = `UPDATE_POST`;
export const UPDATE_POST_SUCCESS = `UPDATE_POST_SUCCESS`;
export const UPDATE_POST_ERROR = `UPDATE_POST_ERROR`;
export const UPDATE_POST_EMPTY = `UPDATE_POST_EMPTY`;

// GET OFFER
export const GET_OFFER = `GET_OFFER`;
export const GET_OFFER_SUCCESS = `GET_OFFER_SUCCESS`;
export const GET_OFFER_ERROR = `GET_OFFER_ERROR`;
export const GET_OFFER_EMPTY = `GET_OFFER_EMPTY`;

// UPDATE OFFER
export const UPDATE_OFFER = `UPDATE_OFFER`;
export const UPDATE_OFFER_SUCCESS = `UPDATE_OFFER_SUCCESS`;
export const UPDATE_OFFER_ERROR = `UPDATE_OFFER_ERROR`;
export const UPDATE_OFFER_EMPTY = `UPDATE_OFFER_EMPTY`;

// GET ANNOUCEMENTS
export const GET_ANNOUNCEMENTS = `GET_ANNOUNCEMENTS`;
export const GET_ANNOUNCEMENTS_SUCCESS = `GET_ANNOUNCEMENTS_SUCCESS`;
export const GET_ANNOUNCEMENTS_ERROR = `GET_ANNOUNCEMENTS_ERROR`;
export const GET_ANNOUNCEMENTS_EMPTY = `GET_ANNOUNCEMENTS_EMPTY`;

// POST ANNOUCEMENTS
export const POST_ANNOUNCEMENTS = `POST_ANNOUNCEMENTS`;
export const POST_ANNOUNCEMENTS_SUCCESS = `POST_ANNOUNCEMENTS_SUCCESS`;
export const POST_ANNOUNCEMENTS_ERROR = `POST_ANNOUNCEMENTS_ERROR`;
export const POST_ANNOUNCEMENTS_EMPTY = `POST_ANNOUNCEMENTS_EMPTY`;

// GET POST TYPES
export const GET_POST_TYPES = `GET_POST_TYPES`;
export const GET_POST_TYPES_SUCCESS = `GET_POST_TYPES_SUCCESS`;
export const GET_POST_TYPES_ERROR = `GET_POST_TYPES_ERROR`;
export const GET_POST_TYPES_EMPTY = `GET_POST_TYPES_EMPTY`;

// POST IMAGE
export const POST_IMAGE = `POST_IMAGE`;
export const POST_IMAGE_SUCCESS = `POST_IMAGE_SUCCESS`;
export const POST_IMAGE_ERROR = `POST_IMAGE_ERROR`;
export const POST_IMAGE_EMPTY = `POST_IMAGE_EMPTY`;

// ADIB SURGE REPORT
export const ADIB_SURGE_REPORT = `ADIB_SURGE_REPORT`;
export const ADIB_SURGE_REPORT_SUCCESS = `ADIB_SURGE_REPORT_SUCCESS`;
export const ADIB_SURGE_REPORT_ERROR = `ADIB_SURGE_REPORT_ERROR`;
export const ADIB_SURGE_REPORT_EMPTY = `ADIB_SURGE_REPORT_EMPTY`;

// ADIB MONTHLY SUMMARY
export const GET_ADIB_MONTHLY_SUMMARY = `GET_ADIB_MONTHLY_SUMMARY`;
export const GET_ADIB_MONTHLY_SUMMARY_SUCCESS = `GET_ADIB_MONTHLY_SUMMARY_SUCCESS`;
export const GET_ADIB_MONTHLY_SUMMARY_ERROR = `GET_ADIB_MONTHLY_SUMMARY_ERROR`;
export const GET_ADIB_MONTHLY_SUMMARY_EMPTY = `GET_ADIB_MONTHLY_SUMMARY_EMPTY`;

// ADIB PARTNERS MONTHLY SUMMARY
export const GET_ADIB_PARTNERS_MONTHLY_SUMMARY = `GET_ADIB_PARTNERS_MONTHLY_SUMMARY`;
export const GET_ADIB_PARTNERS_MONTHLY_SUMMARY_SUCCESS = `GET_ADIB_PARTNERS_MONTHLY_SUMMARY_SUCCESS`;
export const GET_ADIB_PARTNERS_MONTHLY_SUMMARY_ERROR = `GET_ADIB_PARTNERS_MONTHLY_SUMMARY_ERROR`;
export const GET_ADIB_PARTNERS_MONTHLY_SUMMARY_EMPTY = `GET_ADIB_PARTNERS_MONTHLY_SUMMARY_EMPTY`;

// GET POST SETTING
export const GET_POST_SETTING = `GET_POST_SETTING`;
export const GET_POST_SETTING_SUCCESS = `GET_POST_SETTING_SUCCESS`;
export const GET_POST_SETTING_ERROR = `GET_POST_SETTING_ERROR`;
export const GET_POST_SETTING_EMPTY = `GET_POST_SETTING_EMPTY`;

// UPDATE POST SETTING
export const UPDATE_POST_SETTING = `UPDATE_POST_SETTING`;
export const UPDATE_POST_SETTING_SUCCESS = `UPDATE_POST_SETTING_SUCCESS`;
export const UPDATE_POST_SETTING_ERROR = `UPDATE_POST_SETTING_ERROR`;
export const UPDATE_POST_SETTING_EMPTY = `UPDATE_POST_SETTING_EMPTY`;

// UPDATE POST SETTING
export const UPDATE_USER_SETTING = `UPDATE_USER_SETTING`;
export const UPDATE_USER_SETTING_SUCCESS = `UPDATE_USER_SETTING_SUCCESS`;
export const UPDATE_USER_SETTING_ERROR = `UPDATE_USER_SETTING_ERROR`;
export const UPDATE_USER_SETTING_EMPTY = `UPDATE_USER_SETTING_EMPTY`;

/* ----------------------------------- BOOKING MANAGEMENT V2 ----------------------------------- */
// GET BOOKING LIST
export const GET_BOOKING_LIST_V2 = `GET_BOOKING_LIST_V2`;
export const GET_BOOKING_LIST_V2_SUCCESS = `GET_BOOKING_LIST_V2_SUCCESS`;
export const GET_BOOKING_LIST_V2_ERROR = `GET_BOOKING_LIST_V2_ERROR`;
export const GET_BOOKING_LIST_V2_EMPTY = `GET_BOOKING_LIST_V2_EMPTY`;

// CONFIRM BOOKING
export const CONFIRM_BOOKING_V2 = `CONFIRM_BOOKING_V2`;
export const CONFIRM_BOOKING_V2_SUCCESS = `CONFIRM_BOOKING_V2_SUCCESS`;
export const CONFIRM_BOOKING_V2_ERROR = `CONFIRM_BOOKING_V2_ERROR`;
export const CONFIRM_BOOKING_V2_EMPTY = `CONFIRM_BOOKING_V2_EMPTY`;

// CANCEL BOOKING
export const CANCEL_BOOKING_V2 = `CANCEL_BOOKING_V2`;
export const CANCEL_BOOKING_V2_SUCCESS = `CANCEL_BOOKING_V2_SUCCESS`;
export const CANCEL_BOOKING_V2_ERROR = `CANCEL_BOOKING_V2_ERROR`;
export const CANCEL_BOOKING_V2_EMPTY = `CANCEL_BOOKING_V2_EMPTY`;

// PROCCESS BOOKING LIST
export const PROCCESS_BOOKING_V2 = `PROCCESS_BOOKING_V2`;
export const PROCCESS_BOOKING_V2_SUCCESS = `PROCCESS_BOOKING_V2_SUCCESS`;
export const PROCCESS_BOOKING_V2_ERROR = `PROCCESS_BOOKING_V2_ERROR`;
export const PROCCESS_BOOKING_V2_EMPTY = `PROCCESS_BOOKING_V2_EMPTY`;

// CANCEL BOOKING GOLF
export const CANCEL_BOOKING_GOLF = `CANCEL_BOOKING_GOLF`;
export const CANCEL_BOOKING_GOLF_SUCCESS = `CANCEL_BOOKING_GOLF_SUCCESS`;
export const CANCEL_BOOKING_GOLF_ERROR = `CANCEL_BOOKING_GOLF_ERROR`;
export const CANCEL_BOOKING_GOLF_EMPTY = `CANCEL_BOOKING_GOLF_EMPTY`;

// CONFIRM BOOKING GOLF
export const CONFIRM_BOOKING_GOLF = `CONFIRM_BOOKING_GOLF`;
export const CONFIRM_BOOKING_GOLF_SUCCESS = `CONFIRM_BOOKING_GOLF_SUCCESS`;
export const CONFIRM_BOOKING_GOLF_ERROR = `CONFIRM_BOOKING_GOLF_ERROR`;
export const CONFIRM_BOOKING_GOLF_EMPTY = `CONFIRM_BOOKING_GOLF_EMPTY`;

// COMPLATE BOOKING LIST
export const COMPLETE_BOOKING_V2 = `COMPLETE_BOOKING_V2`;
export const COMPLETE_BOOKING_V2_SUCCESS = `COMPLETE_BOOKING_V2_SUCCESS`;
export const COMPLETE_BOOKING_V2_ERROR = `COMPLETE_BOOKING_V2_ERROR`;
export const COMPLETE_BOOKING_V2_EMPTY = `COMPLETE_BOOKING_V2_EMPTY`;

// DOWNLOAD ADIB BOOKINGS
export const DOWNLOAD_ADIB_BOOKING = `DOWNLOAD_ADIB_BOOKING`;
export const DOWNLOAD_ADIB_BOOKING_SUCCESS = `DOWNLOAD_ADIB_BOOKING_SUCCESS`;
export const DOWNLOAD_ADIB_BOOKING_ERROR = `DOWNLOAD_ADIB_BOOKING_ERROR`;
export const DOWNLOAD_ADIB_BOOKING_EMPTY = `DOWNLOAD_ADIB_BOOKING_EMPTY`;

// SEND CLIENT DOWNLOAD DUMP
export const GET_CLIENT_DOWNLOAD_DUMP_ADIB = `GET_CLIENT_DOWNLOAD_DUMP_ADIB`;
export const GET_CLIENT_DOWNLOAD_DUMP_ADIB_SUCCESS = `GET_CLIENT_DOWNLOAD_DUMP_ADIB_SUCCESS`;
export const GET_CLIENT_DOWNLOAD_DUMP_ADIB_ERROR = `GET_CLIENT_DOWNLOAD_DUMP_ADIB_ERROR`;
export const GET_CLIENT_DOWNLOAD_DUMP_ADIB_EMPTY = `GET_CLIENT_DOWNLOAD_DUMP_ADIB_EMPTY`;

/* ----------------------------------- ADMIN PANEL MANAGEMENT ----------------------------------- */
// ADMIN LOGIN
export const ADMIN_LOGIN = `ADMIN_LOGIN`;
export const ADMIN_LOGIN_SUCCESS = `ADMIN_LOGIN_SUCCESS`;
export const ADMIN_LOGIN_ERROR = `ADMIN_LOGIN_ERROR`;
export const ADMIN_LOGIN_EMPTY = `ADMIN_LOGIN_EMPTY`;

/* ----------------------------------- REVIEW ----------------------------------- */
export const GET_FEEDBACK = `GET_FEEDBACK`;
export const GET_FEEDBACK_SUCCESS = `GET_FEEDBACK_SUCCESS`;
export const GET_FEEDBACK_ERROR = `GET_FEEDBACK_ERROR`;
export const GET_FEEDBACK_EMPTY = `GET_FEEDBACK_EMPTY`;
// PERMISSION CHECKER
export const GET_PERMISSION_CHECKER = `GET_PERMISSION_CHECKER`;
export const GET_PERMISSION_CHECKER_SUCCESS = `GET_PERMISSION_CHECKER_SUCCESS`;
export const GET_PERMISSION_CHECKER_ERROR = `GET_PERMISSION_CHECKER_ERROR`;
export const GET_PERMISSION_CHECKER_EMPTY = `GET_PERMISSION_CHECKER_EMPTY`;

// UPDATE PERMISSION CHECKER
export const UDPATE_PERMISSION_CHECKER = `UDPATE_PERMISSION_CHECKER`;
export const UDPATE_PERMISSION_CHECKER_SUCCESS = `UDPATE_PERMISSION_CHECKER_SUCCESS`;
export const UDPATE_PERMISSION_CHECKER_ERROR = `UDPATE_PERMISSION_CHECKER_ERROR`;
export const UDPATE_PERMISSION_CHECKER_EMPTY = `UDPATE_PERMISSION_CHECKER_EMPTY`;

import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label, CardFooter, Button, CardHeader } from 'reactstrap';
import { Constant } from '../../../../../../../Helpers/constant';
import DataTable from 'react-data-table-component';

const BookingDetails = ({ details }) => {
  const [bookingDetails, setBookingDetails] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  let lastUpdatedBy = `NA`;
  let confirmedBy = `NA`;
  let processedBy = `NA`;
  let cancelledBy = `NA`;
  let cancelledReason = `NA`;

  let confirmedAt = `NA`;
  let processedAt = `NA`;
  let cancelledAt = `NA`;
  let latestBookingStatus = details?.bookingStatusCode;

  details?.bookingModifyPersons?.forEach(element => {
    if (element?.bookingStatus === latestBookingStatus) {
      lastUpdatedBy = element?.name;
    }
    if (
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE ||
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_USER ||
      element?.bookingStatus === Constant.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE
    ) {
      cancelledBy = element?.name;
      cancelledReason = element?.remarks;
      cancelledAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
      confirmedBy = element?.name;
      confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.PROCESSED) {
      processedBy = element?.name;
      processedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
    }
  });

  const inputTypeOptions = [
    { value: 1, label: 'Text' },
    { value: 2, label: 'Select' },
    { value: 3, label: 'Date' },
    { value: 4, label: 'Time' },
    { value: 5, label: 'Address' },
    { value: 6, label: 'Number' },
  ];

  useEffect(() => {
    setBookingDetails(details);
    if (details?.delayed?.DynamicFields?.length > 0) {
      const fetchCoumns = [
        {
          name: 'S.No',
          width: '150px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Header Name',
          maxWidth: '400px',
          wrap: true,
          selector: row => row?.headerName,
        },
        {
          name: 'Value',
          maxWidth: '500px',
          wrap: true,
          selector: row => row?.value,
        },
      ];
      setColumns(fetchCoumns);
      setData(
        details?.delayed?.DynamicFields?.map((dynamicData, index) => {
          return {
            serialNumber: index + 1,
            headerName: dynamicData?.headerName || 'NA',
            value: dynamicData?.value || 'NA',
          };
        }),
      );
    } else {
      setColumns([]);
      setData([]);
    }
  }, [details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      {/* <button onClick={() => console.log({ details })}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.booking_id || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">
                      {details?.user?.firstName || ''} {details?.user?.lastName || ''}{' '}
                      {!details?.user?.firstName && !details?.user?.lastName ? 'NA' : ''}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">User Contact Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile No.'}</Label>
                    <Label className="text-muted">{details?.user?.phoneNumber || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BookingDetails;

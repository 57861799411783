import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { DOWNLOAD_ADIB_BOOKING, DOWNLOAD_ADIB_BOOKING_SUCCESS, DOWNLOAD_ADIB_BOOKING_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* downloadBookingsDumpAdib({ payload }) {
  console.log('payload?.headers?.codepayload?.headers?.code', payload?.headers?.code);
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/adib/admin/download`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...payload?.headers,
      },
      params: {
        ...payload?.urlParam,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: DOWNLOAD_ADIB_BOOKING_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({
        type: DOWNLOAD_ADIB_BOOKING_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_ADIB_BOOKING_ERROR, payload: error });
  }
}

export function* watchDownloadBookingsDumpAdib() {
  yield takeEvery(DOWNLOAD_ADIB_BOOKING, downloadBookingsDumpAdib);
}

function* downloadBookingsDumpAdibSaga() {
  yield all([fork(watchDownloadBookingsDumpAdib)]);
}

export default downloadBookingsDumpAdibSaga;

import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { uaeProjectsBaseURL } from '../../../Containers/Config/index.js';
import { ADMIN_LOGIN, ADMIN_LOGIN_SUCCESS, ADMIN_LOGIN_ERROR } from '../actionType.js';
import apiCall from '../apiCall';
import cogoToast from 'cogo-toast';

function* adminLogin({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/v2/login`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      // cogoToast.success(response?.data?.message);
      yield put({
        type: ADMIN_LOGIN_SUCCESS,
        payload: response,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: ADMIN_LOGIN_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: ADMIN_LOGIN_ERROR, payload: error });
  }
}

export function* watchadminLogin() {
  yield takeEvery(ADMIN_LOGIN, adminLogin);
}

function* adminLoginSaga() {
  yield all([fork(watchadminLogin)]);
}

export default adminLoginSaga;

import React, { useEffect, useState } from 'react';

import { Card, CardBody, Col, Container, Row, Input, Label, Button } from 'reactstrap';
import ParticlesAuth from './../../../pages/AuthenticationInner/ParticlesAuth';
import jwt_decode from 'jwt-decode';

//redux
import { useSelector, useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';
import { isValidEmail } from '../../Helpers/Helpers';
import { Error } from '../../Common';

import { withRouter, Link, useHistory } from 'react-router-dom';

import ThriweWhite from './../../../assets/custom/thriwe-white-logo.png';

import { ADMIN_LOGIN, LOGIN } from '../../../store/application/actionType';
import { GoogleLogin } from '@react-oauth/google';
import FullLoader from '../../Common/Loader/CompleteLoader';
import { Constant } from '../../Helpers/constant';
import MicrosoftLogin from 'react-microsoft-login';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  /* ------------------------ LOCAL STATES ---------------------------- */

  const initialState = {
    email: '',
    password: '',
  };
  const [state, setState] = useState(initialState);

  const { user } = useSelector(state => ({
    user: state.Account.user,
  }));

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  /* -------------------- Custom Code -------------------- */
  const { adminLogin, adminLoginLoading, userLoginError, userLoginErrorMsg } = useSelector(state => ({
    adminLogin: state.AdminLogin.data,
    adminLoginLoading: state.AdminLogin.loading,
    userLoginError: state.AdminLogin.error,
    userLoginErrorMsg: state.AdminLogin.message,
  }));

  /* -------------------- Custom Code -------------------- */
  const { userLogin, userLoginLoading } = useSelector(state => ({
    userLogin: state.UserLogin.data,
    userLoginLoading: state.UserLogin.loading,
  }));

  console.log('userLoginLoading', userLoginLoading, adminLoginLoading);

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.email?.trim() !== `` && data?.password?.trim() !== `` && isValidEmail(data?.email?.trim())) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: ADMIN_LOGIN,
        payload: {
          data: {
            ...state,
          },
        },
      });
    }
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        break;

      case 'password':
        updatedState = {
          ...state,
          password: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  useEffect(() => {
    if (adminLoginLoading || userLoginLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [adminLoginLoading, userLoginLoading]);

  useEffect(() => {
    if (![null, undefined, -1].includes(window.localStorage.getItem('authUser'))) {
      let redirectionRoute = `/`;
      const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
      if (userType === Constant?.userTypes?.ADMIN_USER) {
        redirectionRoute = `/dashboard`;
      } else if (userType === Constant?.userTypes?.CLIENT) {
        redirectionRoute = `/client/dashboard`;
      } else if (userType === Constant?.userTypes?.VENDOR) {
        redirectionRoute = `/vendor/dashboard`;
      } else {
        redirectionRoute = `/dashboard`;
      }
      history.push(`${redirectionRoute}`);
    }
  }, []);

  useEffect(() => {
    if (adminLogin !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.ADMIN_USER ?? -1));

      localStorage.setItem(
        'authUser',
        JSON.stringify({
          status: 'success',
          token: adminLogin?.headers?.authorization,
          data: {
            userId: adminLogin?.data?.objectId,
            name: adminLogin?.data?.name,
          },
          permissions: jwt_decode(adminLogin?.headers?.authorization)?.permissions ?? [],
        }),
      );

      history.push(`/dashboard`);
    }
  }, [adminLogin]);

  useEffect(() => {
    if (userLogin !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.ADMIN_USER ?? -1));

      localStorage.setItem(
        'authUser',
        JSON.stringify({
          status: 'success',
          token: userLogin?.headers?.authorization,
          data: {
            userId: userLogin?.data?.objectId,
            name: userLogin?.data?.name,
          },
          permissions: jwt_decode(userLogin?.headers?.authorization)?.permissions ?? [],
        }),
      );

      history.push(`/dashboard`);
    }
  }, [userLogin]);

  const microsoftLogin = data => {
    if (!data?.account?.username?.split('@')?.includes('thriwe.com')) {
      cogoToast.error(`Login allowed for Thriwers only.`);
      return false;
    } else {
      const payload = {
        email: `${data?.account?.username}`?.toLocaleLowerCase(),
        name: `${data?.account?.name}`,
        clientId: 'qwerty',
        // loginToken: data,
        loginToken: {
          clientId: `${data?.account?.tenantId}`,
          client_id: `${data?.account?.tenantId}`,
          credential: `${data?.accessToken}`,
          select_by: 'btn',
        },
      };

      dispatch({
        type: LOGIN,
        payload: {
          data: payload,
        },
      });
    }

    // window.localStorage.setItem('profilePic', tempGoogleResponse?.picture);
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        {loading && <FullLoader />}
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              {/* <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue to Thriwe.</p>
                    </div>

                    <Row className="">
                      <div className="d-flex justify-content-center">
                        <div>
                          <GoogleLogin
                            onSuccess={credentialsResponse => {
                              googleLoginResSuccessHandler(credentialsResponse);
                            }}
                            onError={() => {
                              cogoToast.error('Login failed!');
                            }}
                          />
                        </div>
                      </div>
                    </Row>

                    <br />

                    <Row className="">
                      <div className="d-flex justify-content-center">
                        <MicrosoftLogin
                          clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
                          authCallback={(err, data) => {
                            if (err) {
                              cogoToast.error('Login failed!');
                            } else {
                              microsoftLogin(data);
                            }
                          }}
                        />
                        <div></div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    <div className="text-center mt-2">
                      <h1 className="text-primary">{t('Login')}</h1>
                    </div>
                    <div className="p-2 mt-4 mb-3">
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          {t('Email ID')}
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          placeholder={t(`Enter Email ID`)}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.email}
                        />
                        {isValidationShow && state?.email?.trim() === `` && <Error text={`${t("Email can't be empty")}`} />}
                        {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email?.trim()) && (
                          <Error text={t(`Please enter valid email`)} />
                        )}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          {t('Password')}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="password"
                            name="password"
                            type={isShowPassword ? 'text' : 'password'}
                            placeholder={t(`Enter Password`)}
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.password}
                          />
                          {isValidationShow && state?.password?.trim() === `` && <Error text="Password can't be empty" />}
                          {isValidationShow && state?.password?.trim() !== `` && <Error text="Please enter correct password" />}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => {
                              setIsShowPassword(!isShowPassword);
                            }}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="form-check">
                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                        <Label className="form-check-label" htmlFor="auth-remember-check">
                          {t('Remember me')}
                        </Label>
                        {/* <div className="float-end">
                          <Link to="/client/forgot-password" className="text-muted">
                            {t(`Forgot password?`)}
                          </Link>
                        </div> */}
                      </div>

                      <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit" onClick={submitHandler}>
                          {t('Login')}
                        </Button>
                      </div>
                      <div className="mt-4" style={{ fontSize: '16px', fontWeight: '600', textAlign: 'center' }}>
                        OR
                      </div>
                      <div className="d-flex justify-content-center mt-4">
                        <MicrosoftLogin
                          clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
                          authCallback={(err, data) => {
                            if (err) {
                              cogoToast.error('Login failed!');
                            } else {
                              microsoftLogin(data);
                            }
                          }}
                        />
                        <div></div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
